import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchFilters } from 'src/app/modules/search-intel/models/search-intel.model';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { Themes } from 'src/app/shared/models/skins.model';

@Component({
  selector: 'app-intel-search-image-popup',
  templateUrl: './intel-search-image-popup.component.html',
  styleUrls: ['./intel-search-image-popup.component.scss'],
})
export class IntelSearchImagePopupComponent implements OnInit {
  importFilters: SearchFilters;
  onSave: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private appConfigService: AppConfigService,
    public dialogRef: MatDialogRef<IntelSearchImagePopupComponent>
  ) {}
  theme: Themes;
  ngOnInit() {
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  onSubmit() {
    this.onSave.emit(this.importFilters);
    this.dialogRef.close();
  }
}
