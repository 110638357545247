import { Component, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { BaseComponent } from 'src/app/base/base.component';
import { QueryService } from 'src/app/services/query/query.service';
import { TargetService } from 'src/app/services/target/target.service';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBar,
} from '@angular/material/snack-bar';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import {
  DashboardService,
  DashboardView,
} from 'src/app/services/dashboard/dashboard.service';
import { Router } from '@angular/router';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';

@Component({
  selector: 'app-quick-query',
  templateUrl: './quick-query.component.html',
  styleUrls: ['./quick-query.component.scss'],
})
export class QuickQueryComponent extends BaseComponent {
  number = new FormControl();
  defaultSnackbarHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  defaultSnackbarVerticalPosition: MatSnackBarVerticalPosition = 'top';
  showFlag: boolean = false;
  isMobileResolution: boolean;
  errorMsg: string;

  constructor(
    public snackBar: MatSnackBar,
    private queryService: QueryService,
    private targetService: TargetService,
    private translationService: TranslationService,
    private applicationStateService: ApplicationStateService,
    private dashboardService: DashboardService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private userBillingService: UserBillingService
  ) {
    super();
    this.isMobileResolution =
      this.applicationStateService.getIsMobileResolution();
  }

  keyDownFunction(event, number) {
    this.hideFlagIfNotValid();
    if (event.keyCode === 13 && number) {
      if (this.isValidImsi(this.number.value)) {
        return;
      }
      this.isValidPhone(number);
    }
  }

  clickEvent() {
    if (this.number.value) {
      if (this.isValidImsi(this.number.value)) {
        return;
      }
      this.isValidPhone(this.number.value);
    }
  }

  isValidImsi(number) {
    const queries = [];
    if (this.queryService.isValidImsi(number)) {
      queries.push({ imsi: number });
      this.submitQuickQuery(queries);
      this.number.setValue('');
      return true;
    }
  }

  isValidPhone(number: string) {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();

    try {
      const queries = [];
      const validPhone = this.targetService.getValidPhone(
        phoneNumberUtil,
        number
      );

      if (validPhone) {
        this.number.setValue(null);
        this.showFlag = false;
        this.cdRef.markForCheck();

        queries.push({ telno: validPhone });
        this.submitQuickQuery(queries);
      } else {
        this.showMessage(
          this.translationService.translate('Enter a valid number')
        );
      }
    } catch (e) {
      this.showMessage(
        this.translationService.translate('Enter a valid number')
      );
    }
  }

  submitQuickQuery(queries) {
    if (
      this.userBillingService.userHasEnoughCredits([
        BillingActions.QUERY_LOCATION,
      ])
    ) {
      const quickQuerySubscription = this.queryService
        .quickQuery(queries)
        .subscribe({
          next: () => {
            this.showMessage(
              this.translationService.translate('Query created successfully!')
            );
            this.router.navigate(['discovery']);
            this.dashboardService.componentsView.next(DashboardView.LOG);
            this.dashboardService.showLogTab.next(true);
            quickQuerySubscription.unsubscribe();
          },
          error: (err) => {
            this.queryService.handleQueryError(
              err,
              'Query has not been created'
            );
            quickQuerySubscription.unsubscribe();
          },
        });
    }
  }

  removeValue() {
    this.number.setValue('');
  }

  // TODO - Extends from Base
  showMessage(
    msg: string,
    okText = 'OK',
    preferredDuration?: number,
    preferredHorizontalPosition = this.defaultSnackbarHorizontalPosition,
    preferredVerticalPosition = this.defaultSnackbarVerticalPosition
  ) {
    this.snackBar.open(msg, okText, {
      duration: preferredDuration || 3000,
      horizontalPosition: preferredHorizontalPosition,
      verticalPosition: preferredVerticalPosition,
      panelClass: ['custom-snackbar'],
    });
  }

  private hideFlagIfNotValid() {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    try {
      const number = phoneNumberUtil.parse(this.number.value);
      this.showFlag = !!number.getCountryCode();
    } catch (error) {
      this.showFlag = false;
    }
  }
}
