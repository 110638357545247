import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AppConfigService } from '@app/config';
import { BaseComponent } from 'src/app/base/base.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Query } from 'src/app/shared/models/query-item.model';

@Component({
  selector: 'app-bottom-sheet-mobile',
  templateUrl: './bottom-sheet-mobile.component.html',
  styleUrls: ['./bottom-sheet-mobile.component.scss'],
})
export class BottomSheetMobileComponent
  extends BaseComponent
  implements OnInit
{
  skin;
  showQaugeOnMobile: Boolean;
  hasConcurrentLimits: boolean = false;
  constructor(
    private bottomSheetRef: MatBottomSheetRef<DashboardComponent>,
    private applicationStateService: ApplicationStateService,
    private dashboardService: DashboardService,
    private appConfigService: AppConfigService
  ) {
    super();
    this.skin = this.applicationStateService.getSkin();
  }

  ngOnInit() {
    this.hasConcurrentLimits = this.appConfigService.getConfigVariable(
      'hasConcurrentLimits'
    );
    const dashboardSubscription =
      this.dashboardService.showQaugeOnMobile.subscribe((flag) => {
        this.showQaugeOnMobile = flag;
      });

    this.subscriptions.push(dashboardSubscription);
  }

  closeBottomSheet(event) {
    event.preventDefault();
    this.bottomSheetRef.dismiss();
    this.dashboardService.showQaugeOnMobile.next(true);
  }

  onSelectedQuery(query: Query) {
    this.bottomSheetRef.dismiss();
  }
}
