import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
} from '@busacca/ng-pick-datetime';
import { BaseComponent } from 'src/app/base/base.component';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { QueryService } from 'src/app/services/query/query.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Moment } from 'moment';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
  fullPickerInput: 'DD.MM.YYYY HH:mm',
};

@Component({
  selector: 'app-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }],
})
export class DatetimepickerComponent extends BaseComponent implements OnInit {
  @Output() dateRange = new EventEmitter<Date[]>();

  isProfilerView = false;
  isAdminView = false;
  @Input() placeholderText: string;
  frequency: number;

  private _dateTimeRange?: Date[];

  constructor(
    private queryService: QueryService,
    private translationService: TranslationService,
    private applicationStateService: ApplicationStateService,
    dateTimeAdapter: DateTimeAdapter<any>,
    public snackBar: MatSnackBar,
    private router: Router,
    private appConfigService: AppConfigService
  ) {
    super();
    dateTimeAdapter.setLocale(this.appConfigService.getLanguage());
    this.isAdminView = this.router.url.includes('admin');
  }

  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Output() dates = new EventEmitter<any>();
  @Input()
  public set dateTimeRange(dateTimeRange: Date[]) {
    this._dateTimeRange = dateTimeRange;
    if (this._dateTimeRange?.length === 0) {
      this.queryService.dateRange.next([]);
    }
  }

  public get dateTimeRange() {
    return this._dateTimeRange;
  }

  ngOnInit() {
    this.isProfilerView = this.applicationStateService.isProfilerTarget();

    if (this.appConfigService.getConfigVariable('forceLowFrequencySchedules')) {
      this.subscriptions.push(
        this.queryService.frequency.subscribe((frequency: number) => {
          this.frequency = frequency;
        })
      );
    }
  }

  getRange(date: [Moment, Moment]) {
    // tenant ledger
    if (this.isAdminView && !this.handleRangeRestriction(date, 3)) {
      return;
    }
    const datesChecked: boolean = this.datesChecker(date);
    if (datesChecked) {
      const dateTimeRange = [date[0].toDate(), date[1].toDate()];
      if (this.isProfilerView) {
        this.dateRange.emit(dateTimeRange);
        return;
      }
      if (
        this.appConfigService.getConfigVariable('forceLowFrequencySchedules') &&
        this.frequency
      ) {
        this.validateDateRange(dateTimeRange);
      }
      this.queryService.dateRange.next(dateTimeRange);
    }
  }

  datesChecker(date: [Moment, Moment]): boolean {
    if (date[0] && date[1]) {
      return true;
    } else {
      this.showMessage(
        this.translationService.translate(
          'Please fill in both fields for the date'
        )
      );
    }
    return false;
  }

  private handleRangeRestriction(
    dates: [Moment, Moment],
    maxDaysRange: number
  ): boolean {
    const difference =
      new Date(dates[1].toString()).getTime() -
      new Date(dates[0].toString()).getTime();
    if (difference < 1000 * 3600 * 24 * maxDaysRange) {
      this.dateRange.emit([dates[0].toDate(), dates[1].toDate()]);
      return true;
    }
    this.showMessage(
      this.translationService.translate('Please select a 3 day range maximum.')
    );
  }

  private validateDateRange(dateTimeRange: Date[]) {
    const limit = this.appConfigService.getConfigVariable('themeQueriesLimit');
    const from = dateTimeRange[0];
    const to = dateTimeRange[1];
    const diff = to.getTime() - from.getTime();
    const hours = diff / (1000 * 3600);
    if (hours / (this.frequency / 60) > limit) {
      this.showMessage(
        `${this.translationService.translate(
          'Maximum amount of robot queries is'
        )} ${limit}.
        ${this.translationService.translate(
          'Please choose another daterange.'
        )}`
      );
    }
  }
}
