import { Component } from '@angular/core';
import { ActiveToast } from 'ngx-toastr';
import { BaseComponent } from 'src/app/shared/classes/base.component';

@Component({
  selector: 'app-recommendation-import-toastr',
  templateUrl: './recommendation-import-toastr.component.html',
  styleUrls: ['./recommendation-import-toastr.component.scss'],
})
export class RecommendationImportToastrComponent extends BaseComponent {
  constructor() {
    super();
  }

  public toast: ActiveToast<RecommendationImportToastrComponent> | undefined;
  public data: { telno: string } | undefined;
  public isImported: boolean = false;
}
