import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type Confirmation = 'cancel' | 'proceed';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements AfterViewInit {
  title: string = 'Confirmation';
  description: string = 'Are you sure want to do this?';
  okText: string = 'Yes';
  cancelText: string = 'No';
  enableCancelBtn: boolean = false;
  dialogWidth: string = '360px';
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title?: string;
      description?: string;
      okText?: string;
      cancelText?: string;
      enableCancelBtn?: boolean;
      cancelBtnColor?: string;
      proceedBtnColor?: string;
      dialogWidth?: string;
    }
  ) {
    if (data) {
      this.setOptions();
    }
  }

  ngAfterViewInit(): void {
    this.dialogRef.updateSize(this.dialogWidth);
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  proceed() {
    this.dialogRef.close('proceed');
  }

  private setOptions() {
    if (this.data.title) {
      this.title = this.data.title;
    }

    if (this.data.description) {
      this.description = this.data.description;
    }
    if (this.data.okText) {
      this.okText = this.data.okText;
    }
    if (this.data.cancelText) {
      this.cancelText = this.data.cancelText;
    }
    this.enableCancelBtn = !!this.data.enableCancelBtn;
    this.dialogWidth = this.data.dialogWidth || this.dialogWidth;
  }
}
