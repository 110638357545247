import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfigService } from '@app/config';
import { Observable, Subscription, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { BillingService } from 'src/app/services/billing/billing.service';
import { CaseService } from 'src/app/services/case/case.service';
import { RedirectSnackBarService } from 'src/app/services/snack-bar.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Action } from 'src/app/shared/classes/action.class';
import {
  BillingActionType,
  BillingActions,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';
import { Case } from 'src/app/shared/models/case.model';
import { Themes } from 'src/app/shared/models/skins.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ActionService } from 'src/app/shared/services/action.service';
import { AnalysisActionsListModel } from '../../models/analysis-actions.model';

@Component({
  selector: 'app-add-to-case',
  templateUrl: './add-to-case.component.html',
  styleUrls: ['./add-to-case.component.scss'],
})
export class AddToCaseComponent extends BaseComponent implements OnInit {
  @ViewChild('caseInput') caseInput: ElementRef<HTMLInputElement>;
  addToCaseForm: FormGroup;
  casesList$: Observable<any>;
  allCases: Case[];
  selectedCaseItem: Case;
  selectedCases: Case[] = [];
  visible = true;
  selectable = true;
  removable = true;
  caseCreditsChargesEnabled: boolean;
  creditsForExpired: number;
  billingPlan: BillingPlan<BillingActions, BillingActionType>;
  isUnlimitedTheme: boolean = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  caseCreditsMessage: string;
  expireCaseDays: number;

  constructor(
    private caseService: CaseService,
    @Inject(MAT_DIALOG_DATA)
    public targets: any,
    private localStorageService: LocalStorageService,
    private translationService: TranslationService,
    public dialogRef: MatDialogRef<AddToCaseComponent>,
    private actionService: ActionService,
    private appConfigService: AppConfigService,
    private billingService: BillingService,
    private redirectSnackBarService: RedirectSnackBarService
  ) {
    super();
  }

  ngOnInit(): void {
    this.caseCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForCase'
    );
    this.isUnlimitedTheme =
      this.appConfigService.getConfigVariable('theme') === Themes.UNLIMITED;
    this.expireCaseDays =
      this.appConfigService.getConfigVariable('expireCaseDays');
    this.caseCreditsMessage = this.translationService.interpolate(
      'Management for a new case is free of charge for #{days} days',
      { days: this.expireCaseDays.toString() }
    );
    this.initForm();
    this.billingPlan = this.billingService.getBillingPlan().getValue();

    this.casesList$ = this.addToCaseForm.controls['caseName'].valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      map((value) => {
        return typeof value === 'string' ? value : value?.caseName;
      }),
      switchMap((caseItem: string | null) => {
        if (!caseItem) {
          this.selectedCaseItem = null;
        }
        return caseItem ? this._filter(caseItem) : of([]);
      })
    );
  }

  initForm() {
    this.addToCaseForm = new FormGroup({
      caseName: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/),
      ]),
    });
  }

  /**
   * @param  {string} value
   * @returns Case
   */
  private _filter(value: string): Observable<Case[]> {
    const filterValue = value.toLowerCase();
    return this.getCases(filterValue);
  }

  private getCases(caseName: string): Observable<Case[]> {
    return this.caseService
      .getPaginatedCases({ limit: 10, page: 1, filterArg: caseName })
      .pipe(
        map((data) => {
          this.allCases = data.result;
          return data.result;
        })
      );
  }

  caseSelected(e: MatAutocompleteSelectedEvent): void {
    this.selectedCaseItem = <Case>e.option.value;
    const caseItem: Case = this.selectedCaseItem;
    this.selectedCases.push(caseItem);
    this.allCases = this.allCases.filter((caseObj) => caseObj !== caseItem);
    this.caseInput.nativeElement.value = '';

    this.checkRenewalCreditsCount();
  }

  caseRemoved(caseItem: Case): void {
    const index = this.selectedCases.indexOf(caseItem);
    if (index >= 0) {
      this.selectedCases.splice(index, 1);
      this.allCases.unshift(caseItem);
    }
    this.checkRenewalCreditsCount();
  }

  displayFn(caseItem: Case): string {
    return caseItem && caseItem.caseName ? caseItem.caseName : '';
  }

  addToCase(): void {
    if (!this.selectedCaseItem) {
      return;
    }

    const targetIds = this.targets.map((i) => i.cells.id.content);
    const existingTargetIds = this.selectedCaseItem.assignedTargets.map(
      (i) => i.id
    );
    const assignedTargets = [...new Set(targetIds.concat(existingTargetIds))];
    if (this.caseCreditsChargesEnabled && this.selectedCaseItem.expired) {
      this.renewCase(this.selectedCaseItem.id).subscribe((isRenewed) => {
        if (!isRenewed) {
          return;
        }
        this.updateCaseDetails(this.selectedCaseItem, assignedTargets);
      });
    } else {
      this.updateCaseDetails(this.selectedCaseItem, assignedTargets);
    }
  }

  newCase(): void {
    const targetIds = this.targets.map((i) => i.cells.id.content);
    const caseName = this.addToCaseForm.value.caseName;
    if (caseName && targetIds.length) {
      this.createCase(caseName, targetIds);
    }
  }

  createCase(caseName: string, assignedTargets: TargetItem[]): void {
    const obj: Case = {
      caseName: caseName,
      caseColor: '#005CFF',
      caseDescription: '',
      assignedTargets: assignedTargets,
      assignedUsers: [this.localStorageService.getCurrentUser().identity],
    };

    this.caseService.createCase(obj).subscribe(
      (result) => {
        if (result) {
          this.showMessage(
            this.translationService.translate('Case created successfully!')
          );
          this.dialogRef.close();
          this.actionService.publishAction(
            new Action({
              key: AnalysisActionsListModel.REFRESH_DATA,
              data: null,
            })
          );
        }
      },
      (error: any) => {
        const errorMessage = error?.messages;

        if (
          this.redirectSnackBarService.shouldShowRedirectSnackBar(errorMessage)
        ) {
          this.redirectSnackBarService.showRedirectSnackBar(errorMessage);
        } else {
          this.showMessage(
            this.translationService.translate(
              error.messages ? error.messages : 'Case has not been created'
            )
          );
        }
      }
    );
  }

  updateCaseDetails(caseItem: Case, assignedTargets: TargetItem[]): void {
    const obj: Case = {
      ...caseItem,
      assignedTargets: assignedTargets,
      assignedUsers: [this.localStorageService.getCurrentUser().identity],
    };

    this.caseService.addTargetsToCase(obj).subscribe(
      (result) => {
        if (result) {
          this.showMessage(
            this.translationService.translate('Case updated successfully!')
          );
          this.dialogRef.close();
          this.actionService.publishAction(
            new Action({
              key: AnalysisActionsListModel.REFRESH_DATA,
              data: null,
            })
          );
        }
      },
      (error: any) => {
        this.showMessage(
          this.translationService.translate(
            error.messages ? error.messages : 'Case has not been edited'
          )
        );
      }
    );
  }

  checkRenewalCreditsCount(): void {
    const expiredTargetsCount = this.selectedCases.filter(
      (i) => i.expired
    ).length;
    this.creditsForExpired =
      expiredTargetsCount *
      this.billingPlan[BillingActions.CASE_MANAGEMENT].cost;
  }

  onKeyDown(event) {
    if (this.selectedCases.length === 1) {
      event.preventDefault();
    }
  }

  renewCase(caseId: string): Observable<boolean> {
    return new Observable<boolean>((observable) => {
      const subscription: Subscription = this.caseService
        .renewCase(caseId)
        .subscribe(
          () => {
            this.showMessage(
              this.translationService.translate('Case renewed successfully!')
            );
            this.selectedCaseItem.expired = false;
            observable.next(true);
          },
          (error) => {
            this.showMessage(
              this.translationService.translate(
                error.messages ? error.messages : 'Case has not been renewed'
              )
            );
            observable.next(false);
          },
          () => {
            observable.complete();
          }
        );
      this.subscriptions.push(subscription);
    });
  }
}
