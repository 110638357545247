import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Event,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import {
  DrawerComponent,
  DrawerService,
  DrawerState,
  FontAwesomeFamily,
  LayoutService,
} from '@fe-platform/shared-ui/intellectus';
import { Angulartics2Matomo } from 'angulartics2';
import { FeatureNotificationResponse } from 'datalayer/models/background-jobs/feature-notification';
import { OsintSearchNotificationResponse } from 'datalayer/models/background-jobs/osint-notifications';
import { PurchaseNotificationType } from 'datalayer/models/background-jobs/purchase-notifications';
import { RefreshLocalCacheService } from 'datalayer/services/base/refresh-data.service';
import { ActiveToast } from 'ngx-toastr';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  skip,
  skipWhile,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { JobStatus } from 'src/app/modules/data-layer/models/background-jobs/background-job-status';
import { JobType } from 'src/app/modules/data-layer/models/background-jobs/background-job-type';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { TitleService } from 'src/app/services/title.service';
import { MessageSubject } from 'src/app/services/websocket/message-subject.model';
import { Message } from 'src/app/services/websocket/websocket.class';
import { OsintGroupNotificationsService } from 'src/app/shared/components/osint-group-notifications/osint-group-notifications.service';
import { Skins, Themes } from 'src/app/shared/models/skins.model';
import { customIconList } from 'src/app/shared/util/custom-icons';
import { environment } from 'src/environments/environment';
import { ProxyWsService } from './modules/ad-ids/shared/proxy-ws.service';
import { PurchaseNotificationsService } from './modules/call-logs/components/cl-main/components/cl-intel-suggestions/purchase-notification/purchase-notification.service';
import { SomedusRequestObj } from './modules/profiler/shared/models/profiler-dashboard-sections.model';
import { IntelResultsNotificationsService } from './modules/search-intel/components/intel-results-notification/intel-results-notification.service';
import { CssOverwritesService } from './services/application/css-overwrites.service';
import { AuthState } from './services/authentication/auth-state.enum';
import { AuthService } from './services/authentication/auth.service';
import { FeaturesService } from './services/features/features.service';
import { IntelSearchTrackerService } from './services/intel-search-tracker/intel-search-tracker.service';
import { IntelSearchMapByID } from './services/intel-search-tracker/type/intel-search-by-id';
import { NearbyLocationsStore } from './services/nearby-locations.store';
import {
  QuestionnaireService,
  QuestionnaireState,
} from './services/questionnaire/questionnaire.service';
import { TranslationService } from './services/translation/translation.service';
import { DataLayerWsManagerService } from './services/websocket/data-layer-ws-manager.service';
import { ServerPyWsService } from './services/websocket/server-py-websocket.service';
import { Animations } from './shared/animations/animations';
import { FeatureGroupNotificationsService } from './shared/components/feature-group-notifications/feature-group-notifications.service';
import { IntelSearchManagerComponent } from './shared/components/intel-search-manager/intel-search-manager.component';
import { InvestigationFeedbackPopupComponent } from './shared/components/investigation-feedback-popup/investigation-feedback-popup.component';
import { ApplicationMainPageUrls } from './shared/models/application-main-page-urls.enum';
import { NearbyLocationsBodyMsg } from './shared/models/nearby-locations.model';
import { transformSnakeToCamel } from './shared/util/helper';
import { RedirectionRoutes } from 'src/app/services/authentication/types';
import { StoredModalDataService } from 'src/app/modules/search-intel/services/stored-modal-data.service';
import { OsintService } from 'datalayer/services/osint/osint.service';
import { BackgroundJob } from 'datalayer/models/background-jobs/background-job.model';
import { head } from 'lodash-es';
import { AppHighlighterService } from 'src/app/services/app-highlighter/app-highlighter.service';

const themesToImage: { [key in Themes]: string } = {
  [Themes.GEO]: 'intellectus.png',
  [Themes.GEOLOC]: 'geoloc_logo_64.png',
  [Themes.WHITE]: 'white-logo.png',
  [Themes.FRESHVALE]: 'freshvale-favicon-logo.png',
  [Themes.UNLIMITED]: '',
  [Themes.GENERSHIELD]: 'genershield.png',
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [Animations.slideInAnimation, Animations.fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends BaseComponent implements OnInit {
  title = 'geo2-fe';
  showNavbar: boolean = false;
  isSmallDeviceResolution = false;
  isMobileResolution = false;
  isGeolocTheme: Boolean = false;
  isWhiteTheme: Boolean = false;
  basicImageSrc = './assets/static/images/';
  theme: Themes;
  @ViewChild('targetHeight', { static: true }) targetElement: any;
  headerTop: number = 0;
  displayInFullWidth = false;
  topVal = false;
  flavor: string;
  removeOverflowStyle = false;

  enableQuestionnaires = this.appConfigService.getConfigVariable(
    'enableQuestionnaires'
  );
  @ViewChild('questionnaireContainer', { read: ViewContainerRef })
  questionnaireContainer;
  questionnaireRef: ComponentRef<InvestigationFeedbackPopupComponent>;
  public isInvestigationPage = false;

  public isMobile: boolean;

  public readonly title$: Observable<string> = this.titleService.title;

  private intelSearchContainer: ActiveToast<DrawerComponent> | undefined;

  private enabledMobileScreens: boolean = false;

  public highlightClass$: Observable<string> =
    this.appHighlighterService.state$.pipe(
      tap((): void => this.cdRef.markForCheck())
    );

  private searchContainerCreationLocked: boolean = false;

  constructor(
    private authService: AuthService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    private appConfigService: AppConfigService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private refreshLocalCacheService: RefreshLocalCacheService,
    private osintGroupNotificationsService: OsintGroupNotificationsService,
    private featureGroupNotificationsService: FeatureGroupNotificationsService,
    private angulartics2: Angulartics2Matomo,
    private titleService: TitleService,
    private nearbyLocationsStore: NearbyLocationsStore,
    private dataLayerWsManager: DataLayerWsManagerService,
    private featuresService: FeaturesService,
    public purchaseNotificationsService: PurchaseNotificationsService,
    private serverPyWsService: ServerPyWsService,
    private translationService: TranslationService,
    private questionnaireService: QuestionnaireService,
    private resolver: ComponentFactoryResolver,
    private intelResultsNotificationsService: IntelResultsNotificationsService,
    private proxyWsService: ProxyWsService,
    public cssOverwritesService: CssOverwritesService,
    private readonly drawerService: DrawerService,
    private readonly intelSearchTrackerService: IntelSearchTrackerService,
    private readonly storedModalDataService: StoredModalDataService,
    private readonly osintService: OsintService,
    private readonly layoutService: LayoutService,
    private readonly appHighlighterService: AppHighlighterService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    super();
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.flavor = this.appConfigService.getConfigVariable('flavor');
    const isUAEnabled = this.appConfigService.getConfigVariable('enabledUA');

    // For Custom Icons
    customIconList.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon.iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.iconUrl)
      );
    });
    if (isUAEnabled) {
      this.angulartics2.startTracking();
    }
    this.titleService.startListening();
  }

  setupRouteRelatedUIFlags(url: string) {
    const outterRoutes: string[] = ['/login', '/terms-of-use'];
    const routes: string[] = [
      '/discovery',
      '/dashboard-new',
      '/adint',
      '/targets/cases-link-analysis',
    ];
    const modalUrlSegment: string = '(modal:';
    this.displayInFullWidth =
      routes.includes(url) ||
      outterRoutes.includes(url) ||
      routes.some((searchUrl: string): boolean =>
        url.includes(`${searchUrl}${modalUrlSegment}`)
      );
    if (this.displayInFullWidth) {
      this.cssOverwritesService.changeMainContainerWidth('100%');
    } else {
      this.cssOverwritesService.resetMainContainerWidth();
    }
    this.topVal = outterRoutes.includes(url);
    this.removeOverflowStyle =
      url.includes(`/${ApplicationMainPageUrls.WEBINT}/results`) ||
      url.includes('/overview') ||
      url.includes('/feed') ||
      url.includes('/case-call-log-analysis');
    this.showNavbar = !outterRoutes.includes(url);
    this.isInvestigationPage = this.applicationStateService.isPage(
      ApplicationMainPageUrls.INVESTIGATION
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.layoutService.isMobile$.subscribe((isMobile: boolean): void => {
        this.isMobile = isMobile;
      })
    );

    const routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe({
        next: (event: Event) =>
          this.setupRouteRelatedUIFlags((event as NavigationEnd).url),
      });

    if (this.theme === 'GEOLOC') {
      this.isGeolocTheme = true;
    }

    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }

    const authSubscription = this.authService.authState$
      .pipe(
        tap((state) => {
          if (state !== AuthState.authenticated) {
            return;
          }

          this.listenPurchaseClNotifications();
          this.dataLayerWsManager.connect();
          this.listenOsintNotification();
          this.createPendingSomedusNotifications();
          this.listenSearchResultsNotifications();
          this.createIntelSearchSubscriptions();
          this.fetchOsintBackgroundJobs();
        })
      )
      .subscribe((state) => {
        this.showNavbar = state === AuthState.authenticated;
      });

    const configSubscription = this.appConfigService.authenticatedConfigLoaded$
      .pipe(
        skipWhile((v) => !v),
        take(1)
      )
      .subscribe((config) => {
        this.theme = this.appConfigService.getConfigVariable('theme');
        this.setFavicon();
        this.enabledMobileScreens = this.appConfigService.getConfigVariable(
          'enabledMobileScreens'
        );
        this.isMobileResolution = !this.enabledMobileScreens
          ? this.applicationStateService.getIsMobileResolution()
          : false;
      });

    this.subscriptions.push(
      this.questionnaireService.showTypeOfInvestigation
        .pipe(
          distinctUntilChanged((prev, curr) => {
            return (
              prev.shouldShow === curr.shouldShow && prev.state === curr.state
            );
          })
        )
        .subscribe((state: QuestionnaireState) => {
          if (state && state.state && state.shouldShow) {
            this.questionnaireContainer.clear();
            const factory: ComponentFactory<InvestigationFeedbackPopupComponent> =
              this.resolver.resolveComponentFactory(
                InvestigationFeedbackPopupComponent
              );

            this.questionnaireRef =
              this.questionnaireContainer.createComponent(factory);
          } else {
            if (this.questionnaireRef) {
              this.questionnaireRef.destroy();
            }
          }
        })
    );

    this.subscriptions.push(
      authSubscription,
      routerSubscription,
      configSubscription
    );
    this.isSmallDeviceResolution =
      this.applicationStateService.getIsSmallDeviceResolution();
  }

  public setFavicon() {
    let favicon = document.querySelector('link[rel="shortcut icon"]');
    if (!favicon) {
      favicon = document.createElement('link');
      favicon.setAttribute('rel', 'shortcut icon');
      const head = document.querySelector('head');

      if (head) {
        head.appendChild(favicon);
      }
    }
    favicon.setAttribute('type', 'image/png');
    if (this.flavor === Skins.CVTP) {
      favicon.setAttribute('href', `${this.basicImageSrc}trg-logo.png`);
    } else if (environment.flavor === Skins.GEO4) {
      const image = themesToImage[this.theme];
      favicon.setAttribute('href', `${this.basicImageSrc}${image}`);
    }
  }

  public onSearchIconClickHandler(): void {
    if (!!this.intelSearchContainer) {
      this.intelSearchContainer.toastRef.componentInstance.toggleState();
    }
  }

  getHeadHeight(heightVal: number) {
    this.headerTop = heightVal;
  }

  private createPendingSomedusNotifications(): void {
    this.subscriptions.push(
      this.featuresService
        .getUploadingSomedusFeatures()
        .subscribe((result: SomedusRequestObj[]) => {
          const notifications: FeatureNotificationResponse[] = [];
          if (result?.length) {
            result.forEach((somedesRequest) => {
              notifications.push({
                target_id: somedesRequest.targetId,
                platform: somedesRequest.platform,
                status: somedesRequest.status,
                request_id: somedesRequest.requestId,
              });
            });
          }
          notifications.forEach((notification) =>
            this.featureGroupNotificationsService.processNotification(
              notification
            )
          );
        })
    );
  }

  private listenOsintNotification() {
    this.serverPyWsService.getMessage().subscribe((msg: Message) => {
      switch (msg.subject) {
        case MessageSubject.NearbyLocations:
          const response: NearbyLocationsBodyMsg = msg.body;
          this.nearbyLocationsStore.saveNearbyLocations({
            queryId: response.geo_query_id,
            queryDate: response.query_date,
            profiles: transformSnakeToCamel(response.profiles),
            queryArgTelno: response.telno,
          });
          break;
        case MessageSubject.TrilaterationNearbyLocations:
          this.nearbyLocationsStore.setTrilaterationNearbyPeopleResponse(
            msg.body as NearbyLocationsBodyMsg
          );
          break;
        case MessageSubject.OSINTSearch:
          if (
            msg.body?.osint_type === JobType.DEEP_OSINT &&
            msg.body.status === JobStatus.DONE
          ) {
            // We need this timeout to make sure the Data Platform processed all of the new data before we request to
            // view them in the FE
            setTimeout(() => {
              this.osintGroupNotificationsService.processNotification(
                msg.body as OsintSearchNotificationResponse
              );
              this.refreshLocalCacheService.refreshSource(msg);
            }, 1000 * 60 * 3); // 3 min
          } else {
            this.osintGroupNotificationsService.processNotification(
              msg.body as OsintSearchNotificationResponse
            );
            this.refreshLocalCacheService.refreshSource(msg);
          }
          break;

        case MessageSubject.SomedusQueryRequest:
        case MessageSubject.SomedusQueryResponse:
          this.featureGroupNotificationsService.processNotification(
            msg.body as FeatureNotificationResponse
          );
          break;
        default:
          break;
      }
    });
  }

  listenPurchaseClNotifications() {
    this.proxyWsService.getMessage().subscribe((msg) => {
      switch (msg?.body?.notificationType) {
        case PurchaseNotificationType.PurchaseCompleted:
          this.purchaseNotificationsService.processNotification(msg.body);
          break;
        case PurchaseNotificationType.PurchaseFailed:
          this.showMessage(
            this.translationService.translate('Purchase call logs failed!')
          );
          break;
        default:
          break;
      }
    });
  }

  listenSearchResultsNotifications() {
    this.proxyWsService.getMessage().subscribe((msg: Message) => {
      if (msg?.body?.subject === MessageSubject.searchingTrgDataLake) {
        this.intelResultsNotificationsService.processNotification(msg);
      }
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }

  private createIntelSearchSubscriptions(): void {
    delete this.intelSearchContainer;
    const notifier$: Observable<AuthState> = this.authService.authState$.pipe(
      filter((s) => s !== AuthState.authenticated)
    );
    this.intelSearchTrackerService
      .initialRunningSearchesLoad()
      .pipe(takeUntil(notifier$))
      .subscribe((state: IntelSearchMapByID): void => {
        if (state?.size > 0) {
          this.showDrawer(DrawerState.MINIMIZED);
        }
      });
    this.intelSearchTrackerService.minimized
      .pipe(takeUntil(notifier$))
      .subscribe((): void => {
        this.showDrawer(
          this.isMobile ? DrawerState.MINIMIZED : DrawerState.DEFAULT
        );
      });
    this.intelSearchTrackerService
      .getSearchCount()
      .pipe(takeUntil(notifier$))
      .subscribe((size: number): void => {
        if (size === 0 && this.intelSearchContainer) {
          this.intelSearchContainer.toastRef.componentInstance.close();
        }
      });
    this.intelSearchTrackerService.searchCompleted
      .pipe(takeUntil(notifier$))
      .subscribe((_: string): void => {
        if (!this.isMobile) {
          this.showDrawer();
        }
      });
    this.intelSearchTrackerService.drawerMinimized
      .pipe(takeUntil(notifier$))
      .subscribe((): void => {
        if (
          this.intelSearchContainer &&
          this.intelSearchContainer.toastRef.componentInstance.state ===
            DrawerState.DEFAULT
        ) {
          this.intelSearchContainer.toastRef.componentInstance.toggleState();
        }
      });
    this.router.events
      .pipe(
        skip(1),
        takeUntil(notifier$),
        filter(
          (event) =>
            event instanceof NavigationStart &&
            this.intelSearchContainer?.toastRef.componentInstance.state ===
              DrawerState.DEFAULT
        ),
        tap((): void => {
          this.showDrawer(DrawerState.MINIMIZED);
        })
      )
      .subscribe();

    this.listenClickOnViewSearchResults().subscribe();
  }

  private showDrawer(state = DrawerState.DEFAULT): void {
    if (!this.intelSearchContainer && !this.searchContainerCreationLocked) {
      this.searchContainerCreationLocked = true;
      setTimeout((): void => {
        this.intelSearchContainer = this.drawerService.show(
          IntelSearchManagerComponent,
          {
            title: this.getTranslatedDrawerTitle(),
            width: '350px',
            onClose: (): void => {
              delete this.intelSearchContainer;
            },
            onExpand: (): void => {
              const url: string | undefined =
                this.storedModalDataService.getUrl();
              if (url) {
                this.router.navigate([{ outlets: { modal: url } }]).then();
                return;
              }

              this.router
                .navigate([
                  { outlets: { modal: [RedirectionRoutes.WEBINT, 'history'] } },
                ])
                .then();
            },
            onStateToggled: (state: DrawerState): void => {
              if (state === DrawerState.MINIMIZED) {
                this.intelSearchTrackerService.transformAllSearchesToHidden();
              }
            },
            badge: this.intelSearchTrackerService.getSearchCount(true),
            headerIcon: {
              name: 'global-search',
              family: FontAwesomeFamily.KIT,
            },
            expandIcon: {
              name: 'up-right-and-down-left-from-center',
              family: FontAwesomeFamily.SOLID,
            },
            showLoadingOnMinimized: true,
            state,
          }
        );
        this.cdRef.markForCheck();

        this.searchContainerCreationLocked = false;
      }, 200);
    } else {
      setTimeout(
        (): void => {
          this.intelSearchContainer.toastRef.componentInstance.state = state;
        },
        !!this.intelSearchContainer ? 0 : 200
      );
    }

    if (state === DrawerState.MINIMIZED) {
      this.intelSearchTrackerService.transformAllSearchesToHidden();
    }
  }

  private listenClickOnViewSearchResults(): Observable<string> {
    return this.intelSearchTrackerService.viewResultsClicked.pipe(
      tap((id: string): void => {
        if (this.router.url.includes('webint/results')) {
          this.intelSearchTrackerService.removeSearch(id);
          return;
        }
        this.storedModalDataService.clearAll();
        this.intelSearchTrackerService.toggleSearchInViewState(id);
        this.router
          .navigate([
            { outlets: { modal: [RedirectionRoutes.WEBINT, 'results', id] } },
          ])
          .then();
      })
    );
  }

  private getTranslatedDrawerTitle(): Observable<string> {
    return this.translationService.languageChange.pipe(
      map(() => this.translationService.translate('Profile Search'))
    );
  }

  private fetchOsintBackgroundJobs(): void {
    this.osintService
      .fetchOsintPendingJobs(this.authService.user.current.identity)
      .subscribe((backgroundJobs: BackgroundJob[]) => {
        if (backgroundJobs && backgroundJobs.length) {
          backgroundJobs.forEach((backgroundJob: BackgroundJob) => {
            const now: Date = new Date();

            // filter out old stucked jobs
            if (
              new Date(backgroundJob.created_at).getTime() >
              now.getTime() - 3600 * 24 * 1000
            ) {
              const msg: OsintSearchNotificationResponse = {
                platform: head(backgroundJob.query_args).platform,
                status: backgroundJob.status,
                target_id: backgroundJob.target_id,
                osint_type: backgroundJob.job_type,
              };
              this.osintGroupNotificationsService.processNotification(msg);
            }
          });
        }
      });
  }
}
