import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { BaseService } from 'src/app/services/base.service';
import { TranslationService } from './../../../services/translation/translation.service';
import { WebintDisabledModalComponent } from './webint-disabled-modal.component';

@Injectable({
  providedIn: 'root',
})
export class WebintDisabledService extends BaseService {
  constructor(
    protected router: Router,
    private dialog: MatDialog,
    private appConfigService: AppConfigService,
    protected snackBar: MatSnackBar,
    private translationService: TranslationService
  ) {
    super(router, snackBar);
  }

  openWebintDisabledModal(): void {
    let webintDisabledModal = this.dialog.open(WebintDisabledModalComponent, {
      autoFocus: true,
    });

    webintDisabledModal.afterClosed().subscribe((modalResult: boolean) => {
      if (modalResult) {
        this.showMessage(
          `${this.translationService.translate(
            'Thank you for your message. Your customer success representative will contact you shortly'
          )}`
        );
      }
    });
  }

  handleWebintAvailability(): boolean {
    if (this.webintActionsDisabled) {
      this.openWebintDisabledModal();
      return false;
    }

    return true;
  }

  get webintActionsDisabled(): boolean {
    return this.appConfigService.getConfigVariable('disableWebintActions');
  }
}
