import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { QueryService } from 'src/app/services/query/query.service';
import { BaseComponent } from 'src/app/base/base.component';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { LocationQueryService } from 'src/app/services/query/location-query.service';
import { Circle } from 'src/app/modules/mapV2/models/map.models';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { InstantMessagesStore } from 'src/app/services/instant-messages.store';
import { tap } from 'rxjs/operators';
import { Angulartics2 } from 'angulartics2';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';

@Component({
  selector: 'app-queries',
  templateUrl: './queries.component.html',
  styleUrls: ['./queries.component.scss'],
})
export class QueriesComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  enableAoIQuery: boolean;
  @Output() selectedCountry = new EventEmitter<string>();
  @Output() showSearchTool = new EventEmitter<boolean>();
  @Output() showDrawingTools = new EventEmitter<boolean>();
  @Output() clearQueryMap = new EventEmitter<boolean>();
  @Output() drawAreaOfInterestFromComponent = new EventEmitter<Circle>();
  @Input() areaOfInterestDrawn: Circle;
  @Output() emittedLocationQuery = new EventEmitter<any>();
  @Output() clearDateTimeRange = new EventEmitter();
  areaOfInterest: Circle;
  simpleQueryFlag: boolean = false;

  constructor(
    private queryService: QueryService,
    private translationService: TranslationService,
    private dashboardService: DashboardService,
    protected locationQueryService: LocationQueryService,
    private appConfigService: AppConfigService,
    private angulartics2: Angulartics2,
    private instantMessagesStore: InstantMessagesStore,
    private userBillingService: UserBillingService
  ) {
    super();
  }

  ngOnInit() {
    this.enableAoIQuery =
      this.appConfigService.getConfigVariable('enableAoIQuery');
  }

  ngOnChanges(changes: SimpleChanges) {
    this.areaOfInterest = changes.areaOfInterestDrawn.currentValue;
  }

  getValidState(flag: boolean) {
    this.simpleQueryFlag = flag;
  }

  submitQuery() {
    if (
      !this.userBillingService.userHasEnoughCredits([
        BillingActions.QUERY_LOCATION,
      ])
    ) {
      return;
    }

    if (this.queryService.isAdvancedQuery.value) {
      this.queryService.enableAdvancedQuery();
    } else {
      this.angulartics2.eventTrack.next({
        action: matomoActions.submitAdvancedQuery,
        properties: {
          category: matomoCategories.gioAdvancedQuery,
        },
      });
      try {
        const simpleQuerySubscription = this.queryService
          .createSimpleQuery()
          .pipe(
            tap((queries) => {
              queries.result.forEach((q) => {
                this.instantMessagesStore.fetchAllImPlatforms(
                  q.query_args.telno,
                  q.id,
                  true
                );
              });

              this.queryService.numbersToBeQueried.next([]);
            })
          )
          .subscribe(
            () => {
              this.showMessage(
                this.translationService.translate('Query created successfully!')
              );
              this.changeView('logView');
            },
            () => {
              this.showMessage(
                this.translationService.translate('Query has not been created')
              );
            }
          );
        this.subscriptions.push(simpleQuerySubscription);
      } catch (e) {
        console.error(e);
      }
    }
  }

  changeView(view) {
    this.dashboardService.componentsView.next(view);
  }

  resetFields() {
    this.clearDateTimeRange.emit();
    this.queryService.clearFrequency.next(true);
    this.queryService.clearQueryInput.next(true);
    this.queryService.clearAdvancedInputs.next(true);
    this.simpleQueryFlag = false;
  }

  mapDrawTools(index) {
    this.clearQueryMap.emit(true);
    this.locationQueryService.locationQueryData.next([]);
    if (index === 1) {
      this.showSearchTool.emit(true);
      this.showDrawingTools.emit(true);
    } else {
      this.showSearchTool.emit(false);
      this.showDrawingTools.emit(false);
    }
  }

  onSelectedCountry(country: string) {
    this.selectedCountry.emit(country);
  }

  toggleSearchTool(flag: boolean) {
    this.showSearchTool.emit(flag);
  }

  toggleDrawingTools(flag: boolean) {
    this.showDrawingTools.emit(flag);
  }

  onClearQueryMap(flag: boolean) {
    this.clearQueryMap.emit(flag);
  }

  onDrawnAreaOfInterestFromComponent(circle: Circle) {
    this.drawAreaOfInterestFromComponent.emit(circle);
  }

  onEmittedLocationQuery(locationQuery: any) {
    this.emittedLocationQuery.emit(locationQuery);
  }
}
