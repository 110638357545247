import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  Breadcrumb,
  FontAwesomeFamily,
  ModalComponent,
  ModalConfig,
} from '@fe-platform/shared-ui/intellectus';
import { ModalDataService } from 'src/app/modules/search-intel/services/modal-data.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { buildBreadcrumbs } from 'src/app/modules/search-intel/helper/build-breadcrumbs';
import { ApplicationMainPageUrls } from '@shared/models/application-main-page-urls.enum';
import { IntelSearchTrackerService } from 'src/app/services/intel-search-tracker/intel-search-tracker.service';
import { StoredModalDataService } from 'src/app/modules/search-intel/services/stored-modal-data.service';

@UntilDestroy()
@Component({
  selector: 'trg-ui-search-results-modal',
  templateUrl: './search-results-modal.component.html',
  styleUrls: ['./search-results-modal.component.scss'],
})
export class SearchResultsModalComponent implements AfterViewInit {
  @ViewChild('modalComponent')
  public readonly modalComponent: ModalComponent;

  public config: ModalConfig;

  private searchID?: string;

  private minimized: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly modalDataService: ModalDataService,
    private readonly intelSearchTrackerService: IntelSearchTrackerService,
    private readonly storedModalDataService: StoredModalDataService
  ) {
    this.updateConfig({
      icon: {
        family: FontAwesomeFamily.KIT,
        name: 'global-search',
      },
      actions: [
        {
          icon: {
            name: 'arrow-up-right-from-square',
            family: FontAwesomeFamily.SOLID,
          },
          action: (): void => {
            const link: string =
              this.config.breadcrumbs[this.config.breadcrumbs.length - 1].link;
            this.router
              .navigate([link])
              .then((): void => this.modalComponent.close());
          },
        },
        {
          icon: {
            name: 'down-left-and-up-right-to-center',
            family: FontAwesomeFamily.SOLID,
          },
          action: (): void => {
            this.intelSearchTrackerService.toggleSearchInViewState(
              this.searchID
            );
            delete this.searchID;
            this.storeModalState();
            this.modalComponent.close();
          },
        },
      ],
      breadcrumbs: [],
    });

    this.createSubscriptions();
  }

  public ngAfterViewInit(): void {
    const scrollTop: number = this.storedModalDataService.getScrollTop();

    if (scrollTop) {
      this.modalComponent.scrollableContent.nativeElement.scrollTo(
        0,
        scrollTop
      );
    }
  }

  public onBreadcrumbClick(index: number): void {
    const breadcrumb: Breadcrumb = this.config.breadcrumbs[index];
    this.router
      .navigate([{ outlets: { modal: breadcrumb.link } }])
      .then((): void => this.storedModalDataService.clearAll());
    this.updateConfig({
      breadcrumbs: this.config.breadcrumbs.splice(index, 1),
    });
  }

  public modalClose(event: boolean): void {
    if (!event) {
      return;
    }
    if (this.searchID) {
      this.intelSearchTrackerService.removeSearch(this.searchID);
    }

    this.router.navigate([{ outlets: { modal: null } }]).then();

    if (!this.minimized) {
      this.storedModalDataService.clearAll();
    }
  }

  private updateConfig(partialConfig: Partial<ModalConfig>): void {
    this.config = {
      ...this.config,
      ...partialConfig,
    };
  }

  private createSubscriptions(): void {
    this.modalDataService.snapshot
      .pipe(untilDestroyed(this))
      .subscribe((snapshot: ActivatedRouteSnapshot): void => {
        this.extractSearchIdFromSnapshot(snapshot);
        const breadcrumbs: Breadcrumb[] = buildBreadcrumbs(
          snapshot,
          ApplicationMainPageUrls.WEBINT,
          this.config.breadcrumbs
        );
        if (breadcrumbs?.length) {
          this.updateConfig({
            breadcrumbs: breadcrumbs,
          });
        }
      });

    this.modalDataService.outsideNavigation
      .pipe(untilDestroyed(this))
      .subscribe((): void => this.modalComponent.close());

    this.modalDataService.locate
      .pipe(untilDestroyed(this))
      .subscribe((): void => {
        this.intelSearchTrackerService.toggleSearchInViewState(this.searchID);
        delete this.searchID;
        this.storeModalState();
      });
  }

  private extractSearchIdFromSnapshot(snapshot: ActivatedRouteSnapshot): void {
    if (snapshot.params.hasOwnProperty('id')) {
      this.searchID = snapshot.params.id;
    }
  }

  public storeModalState(): void {
    this.minimized = true;
    const lastUrl: string = this.config.breadcrumbs.pop().link;
    this.storedModalDataService.storeUrl(lastUrl);
    this.storedModalDataService.setScrollTop(
      this.modalComponent.scrollableContent.nativeElement.scrollTop
    );
  }
}
