import { AbstractControl, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

export function isValidPhone(phone: string): boolean {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneNumberUtil.parse(phone);

    return phoneNumberUtil.isValidNumber(phoneNumber);
  } catch (e) {
    return false;
  }
}

// adapter for isValidPhone that returns a Validator Object
export function isValidPhoneFormValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let isValidNumber = isValidPhone(control.value);
    if (!isValidNumber && control.value) {
      return { invalidPhone: true };
    } else {
      return null;
    }
  };
}
