import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { getLangPaginatorIntl } from 'src/app/shared/util/paginator-translations';
import { TranslationService } from './services/translation/translation.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

const imports = [
  MatCardModule,
  MatExpansionModule,
  MatDividerModule,
  MatGridListModule,
  MatSidenavModule,
  MatToolbarModule,
  MatIconModule,
  MatListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonModule,
  MatChipsModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTableModule,
  MatSortModule,
  MatSlideToggleModule,
  MatRadioModule,
  MatMenuModule,
  MatBadgeModule,
  DragDropModule,
  MatBottomSheetModule,
  MatTabsModule,
  MatProgressBarModule,
  MatSliderModule,
  MatDialogModule,
  ClipboardModule,
  OverlayModule,
  PortalModule,
  MatButtonToggleModule,
  MatTooltipModule,
];

@NgModule({
  imports: [...imports],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: (translationService: TranslationService) =>
        getLangPaginatorIntl(translationService),
      deps: [TranslationService],
    },
  ],
  exports: [...imports],
})
export class MaterialModule {}
