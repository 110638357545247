import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IntelSearchArgTypes } from 'src/app/modules/search-intel/models/intel-search-arg-types.model';
import { SearchLabels } from 'src/app/modules/search-intel/models/search-intel.model';
import { BaseService } from 'src/app/services/base.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImageService extends BaseService {
  apiUrl: string = `${environment.fastAPIUri}/image`;

  constructor(
    private DomSanitizerService: DomSanitizer,
    private httpClient: HttpClient,
    protected snackBar: MatSnackBar,
    protected router: Router
  ) {
    super(router, snackBar);
  }

  public getPhotoUrl(photo: string, thumbnail?): string | SafeResourceUrl {
    if (!photo) {
      return;
    }

    const url = environment.fileManagerUri;

    // `${baseUrl}/files` is used for uploaded photos by user
    if (photo.includes(`/api/v1/files/`)) {
      return photo;
    }

    if (photo.includes(url)) {
      if (!photo.includes('?useThumbnail=yes') && thumbnail) {
        return `${photo}?useThumbnail=yes`;
      } else if (photo.includes('?useThumbnail=yes') && !thumbnail) {
        return photo.replace('?useThumbnail=yes', '');
      } else {
        return photo;
      }
    }

    if (photo.includes('base64')) {
      return thumbnail
        ? `${this.DomSanitizerService.bypassSecurityTrustResourceUrl(
            photo
          )}useThumbnail=yes`
        : this.DomSanitizerService.bypassSecurityTrustResourceUrl(photo);
    }

    if (photo.includes('/api/v1/file/')) {
      return thumbnail && !photo.includes('?useThumbnail=yes')
        ? `${photo}?useThumbnail=yes`
        : photo;
    }

    if (photo.includes('file')) {
      return thumbnail && !photo.includes('?useThumbnail=yes')
        ? `${url}${photo}?useThumbnail=yes`
        : `${url}${photo}`;
    }

    if (photo.includes('no_photo.svg') || photo.includes('assets/static')) {
      return photo;
    }

    return `${url}/file/${photo}`;
  }

  public getImageUrl(image: string | SafeResourceUrl, isBase64: boolean) {
    return this.httpClient
      .post<any>(`${this.apiUrl}/image-url`, { image, isBase64 })
      .pipe(
        map((response) => {
          return response?.result ? response.result : undefined;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  buildIntelSearchQueryArgs(
    data: {
      value: string;
      label: SearchLabels;
      photoUrl?: string | SafeResourceUrl;
      filename?: string | SafeResourceUrl;
    }[]
  ): { arg_type: string; arg_value: any }[] {
    const searchArgs: {
      arg_type: IntelSearchArgTypes;
      arg_value: any;
    }[] = [];

    data.forEach((field) => {
      if (field.label === SearchLabels.IMAGE) {
        searchArgs.push({
          arg_type: IntelSearchArgTypes.PHOTO,
          arg_value: {
            name: String(field.value).trim(),
            photoUrl: String(field.photoUrl).trim(),
            filename: field.filename || '',
          },
        });
      } else if (field.label === SearchLabels.PHONE) {
        searchArgs.push({
          arg_type: IntelSearchArgTypes.TELNO,
          arg_value: field.value,
        });
      }
    });
    return searchArgs;
  }

  public uploadImage(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<any>(`${this.url}/files`, formData).pipe(
      map((fileResult) => {
        return fileResult?.result;
      }),
      catchError((error) => this.handleError(error))
    );
  }
}
