import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class DialogCustomService {
  private dialogClosed$ = new Subject<boolean>();

  constructor(private router: Router) {}

  dialogClosed(): Observable<boolean> {
    return this.dialogClosed$.asObservable();
  }

  closeDialog(): void {
    this.dialogClosed$.next(true);
  }

  closeDialogAndNavigate(route: string, queryParams?: NavigationExtras): void {
    this.closeDialog();
    this.router.navigate([route], queryParams);
  }
}
