import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  TargetSummary,
  TargetSummaryInfo,
  TargetSummarySocialNetworkAssociates,
} from '@report-service-ts/data-models';
import { TargetSummarySectionIdentifier } from '@report-service-ts/data-models/dist/target-summary/enums/section-identifier.enum';
import { Profile } from 'datalayer/models/social/profile';
import { forkJoin, Observable, combineLatest, of } from 'rxjs';
import {
  take,
  tap,
  distinctUntilChanged,
  debounceTime,
  filter,
  map,
  timeout,
  catchError,
} from 'rxjs/operators';
import { ClExportBaseComponent } from 'src/app/modules/call-logs/components/cl-actions/cl-export-base/cl-export-base.component';
import { ReportGenerationService } from 'src/app/services/report-generation/report-generation.service';
import { ReportService } from 'src/app/services/report/report.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { UserService } from 'src/app/services/user/user.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { ReportAttributeDirective } from 'src/app/shared/directives/report-attribute.directive';
import { ReportSectionImage } from 'src/app/shared/models/report-section-image.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ClAssociate } from 'src/app/shared/modules/call-logs-shared/models/cl-associate';
import { MarkerCollection } from '../../modules/profiler-map/models/marker-collection.model';
import { TargetSummaryChildComponentService } from '../../services/target-summary-child-component.service';
import { TargetSummaryReportProgressBarElements } from '../../shared/enums/target-summary-report-progress-bar-elements.enum';
import { isEqual } from 'lodash';
import { TargetSummaryHelper } from './helpers/target-export-helper';
import { KlComponentsService } from 'src/app/modules/link-analysis-library/angular-link-analysis-library';
import { ProfilerService } from 'src/app/modules/profiler/services/profiler.service';
import { TargetTopRelatedPeopleService } from 'src/app/modules/profiler/services/target-top-related-people.service';

@Component({
  selector: 'app-target-export',
  templateUrl: './target-export.component.html',
  styleUrls: ['./target-export.component.scss'],
  providers: [KlComponentsService],
})
export class TargetExportComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChildren(ReportAttributeDirective) reportSections: QueryList<
    ReportAttributeDirective<TargetSummarySectionIdentifier>
  >;
  @ViewChild('textAnalysisContainer', { read: ViewContainerRef })
  textAnalysisContainer: ViewContainerRef;
  @ViewChild('activityPatternContainer', { read: ViewContainerRef })
  activityPatternContainer: ViewContainerRef;
  @ViewChild('linkAnalysisContainer', { read: ViewContainerRef })
  linkAnalysisContainer: ViewContainerRef;
  @ViewChild('locationsContainer', { read: ViewContainerRef })
  locationsContainer: ViewContainerRef;
  progressBarElements: {
    name: TargetSummaryReportProgressBarElements;
    value: boolean;
  }[] = Object.keys(TargetSummaryReportProgressBarElements).map((key) => ({
    name: TargetSummaryReportProgressBarElements[key],
    value: false,
  }));
  title: string;
  progressBarValue: number = 0;
  exportForm: FormGroup = null;
  filePreviewUrl: string;
  embedUrl: SafeResourceUrl;
  target: TargetItem;
  targetFamily: Profile[];
  createdBy: string;
  callTopAssociates: ClAssociate[] = [];
  locationData: MarkerCollection;
  targetSummarySectionIdentifier = TargetSummarySectionIdentifier;
  targetImageSections: ReportSectionImage<TargetSummarySectionIdentifier>[] =
    [];
  socialNetworkAssociates: TargetSummarySocialNetworkAssociates[] = [];
  targetSummary: TargetSummary;
  note: string;
  filteredValues: { [key: string]: boolean };
  customFormAvailableSections: TargetSummarySectionIdentifier[] = [];
  targetSummaryInfo: TargetSummaryInfo;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { targetId: string },
    public dialogRef: MatDialogRef<ClExportBaseComponent>,
    private formBuilder: FormBuilder,
    private reportGenerationService: ReportGenerationService,
    private domSanitizer: DomSanitizer,
    private reportService: ReportService,
    private userService: UserService,
    private translationService: TranslationService,
    private targetSummaryChildComponentService: TargetSummaryChildComponentService,
    private targetSummaryHelper: TargetSummaryHelper,
    private profilerService: ProfilerService,
    private targetTopRelatedPeopleService: TargetTopRelatedPeopleService
  ) {
    super();
    this.dialogRef.afterClosed().subscribe(() => {
      this.setLoading(false);
    });
  }

  ngOnInit(): void {
    this.setLoading(true);
    this.title = this.translationService.translate(
      'Gathering target summary report for'
    );
    this.generateReportForm();
    this.onNoteValueChanges();
  }

  ngAfterViewInit(): void {
    this.profilerService
      .getTargetData(this.data.targetId)
      .subscribe((response: TargetItem) => {
        if (!response) {
          this.dialogRef.close();
          this.showMessage(
            this.translationService.translate(
              'Could not gather data for this target'
            )
          );
          return;
        }
        this.target = response;
        this.generateReport();
      });
  }

  generateReport(): void {
    this.subscriptions.push(
      forkJoin({
        cloudWord: this.withTimeout(
          this.generateCloudWord(),
          TargetSummaryReportProgressBarElements.socialWorldCloud
        ),
        callTopAssociates: this.withTimeout(
          this.generateCallTopAssociates(),
          TargetSummaryReportProgressBarElements.callLogTopAssociates
        ),
        currentUser: this.withTimeout(this.userService.getCurrentUser()),
        target: this.withTimeout(
          this.generateCompleteTargetData(),
          TargetSummaryReportProgressBarElements.targetProfile
        ),
        activityPattern: this.withTimeout(
          this.generateActivityPattern(),
          TargetSummaryReportProgressBarElements.activityPattern
        ),
        locations: this.withTimeout(
          this.generateLocations(),
          TargetSummaryReportProgressBarElements.locations
        ),
        locationData: this.withTimeout(this.generateLocationData()),
        linkAnalysis: this.withTimeout(
          this.generateLinkAnalysis(),
          TargetSummaryReportProgressBarElements.socialNetworkGraph
        ),
        socialNetworkAssociates: this.withTimeout(
          this.generateSocialTopAssociates(),
          TargetSummaryReportProgressBarElements.socialNetworkTopAssociates
        ),
      }).subscribe(
        (data) => {
          this.createdBy = data.currentUser.username;
          if (data.target) {
            this.targetSummaryInfo = data.target;
          }
          if (data.cloudWord) {
            this.targetImageSections = [
              ...this.targetImageSections,
              ...data.cloudWord,
            ];
            this.availableFormSections(
              TargetSummarySectionIdentifier.socialWordCloud
            );
          }
          if (data.activityPattern) {
            this.targetImageSections = [
              ...this.targetImageSections,
              ...data.activityPattern,
            ];
            this.availableFormSections(
              TargetSummarySectionIdentifier.activityPatterns
            );
          }
          if (data.linkAnalysis) {
            this.targetImageSections = [
              ...this.targetImageSections,
              ...data.linkAnalysis,
            ];
            this.availableFormSections(
              TargetSummarySectionIdentifier.linkAnalysis
            );
          }
          if (data.socialNetworkAssociates) {
            this.socialNetworkAssociates = data.socialNetworkAssociates;
            this.availableFormSections(
              TargetSummarySectionIdentifier.socialNetworkTopAssociates
            );
          }
          if (data.locations) {
            this.targetImageSections = [
              ...this.targetImageSections,
              ...data.locations,
            ];
            this.availableFormSections(
              TargetSummarySectionIdentifier.locations
            );
          }
          if (data.locationData) {
            this.locationData = data.locationData;
          }
          if (data.callTopAssociates && data.callTopAssociates.length > 0) {
            this.callTopAssociates = data.callTopAssociates;
            this.availableFormSections(
              TargetSummarySectionIdentifier.callLogTopAssociates
            );
          }
          this.generateTargetSummaryModel();
          this.optionsFormSubscription();
          this.sectionsFormSubscription();
        },
        () => {
          this.dialogRef.close();
          this.showMessage(
            this.translationService.translate(
              'Could not gather data for this report'
            )
          );
        }
      )
    );
  }

  private withTimeout<T>(
    obs: Observable<T>,
    progressSection?: TargetSummaryReportProgressBarElements
  ): Observable<T> {
    return obs.pipe(
      timeout(120000),
      catchError((e) => {
        return of(undefined);
      }),
      take(1),
      tap(() => {
        if (progressSection) {
          this.bumpProgressBar(progressSection);
        }
      })
    );
  }

  generateCompleteTargetData(): Observable<TargetSummaryInfo> {
    return this.targetSummaryChildComponentService.generateCompleteTargetData(
      this.data.targetId
    );
  }

  generateSocialTopAssociates(): Observable<
    TargetSummarySocialNetworkAssociates[]
  > {
    return this.targetTopRelatedPeopleService.generateSocialTopAssociates(
      this.target
    );
  }

  generateLinkAnalysis(): Observable<
    ReportSectionImage<TargetSummarySectionIdentifier>[]
  > {
    return this.targetSummaryChildComponentService.generateLinkAnalysis(
      this.data.targetId,
      this.linkAnalysisContainer
    );
  }

  generateLocationData(): Observable<MarkerCollection> {
    return this.targetSummaryChildComponentService.generateLocationData();
  }

  generateLocations(): Observable<
    ReportSectionImage<TargetSummarySectionIdentifier>[]
  > {
    return this.targetSummaryChildComponentService.generateLocations(
      this.data.targetId,
      this.locationsContainer
    );
  }

  generateCallTopAssociates(): Observable<ClAssociate[]> {
    return this.targetSummaryChildComponentService.generateCallTopAssociates(
      this.target
    );
  }

  generateCloudWord(): Observable<
    ReportSectionImage<TargetSummarySectionIdentifier>[]
  > {
    return this.targetSummaryChildComponentService.generateCloudWordImage(
      this.data.targetId,
      this.textAnalysisContainer,
      this.reportSections
    );
  }

  generateActivityPattern(): Observable<
    ReportSectionImage<TargetSummarySectionIdentifier>[]
  > {
    return this.targetSummaryChildComponentService.generateTargetTotalActivityPattern(
      this.target,
      this.activityPatternContainer,
      this.reportSections
    );
  }

  generateTargetSummaryModel(filters?: { [key: string]: boolean }): void {
    const targetSummaryData: TargetSummary =
      this.targetSummaryHelper.generateTargetSummary(
        this.targetSummaryInfo,
        this.callTopAssociates,
        this.targetImageSections,
        this.locationData,
        this.socialNetworkAssociates,
        this.createdBy,
        this.note,
        filters
      );
    this.returnPreviewPdf(targetSummaryData);
  }

  returnPreviewPdf(target: TargetSummary): void {
    this.setLoading(true);
    this.reportGenerationService
      .generateTargetSummaryReport(target)
      .pipe(take(1))
      .subscribe((data) => {
        this.filePreviewUrl = window.URL.createObjectURL(data);
        this.embedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
          this.filePreviewUrl + '#toolbar=0&zoom=100'
        );
        this.setLoading(false);
      });
  }

  generateReportForm(): void {
    this.exportForm = this.formBuilder.group({
      options: ['1'],
      sections: this.formBuilder.group(this.returnSectionGroup()),
      note: new FormControl(
        '',
        Validators.compose([Validators.minLength(3), Validators.maxLength(255)])
      ),
    });
  }

  onNoteValueChanges(): void {
    combineLatest([
      this.exportForm.get('note').statusChanges,
      this.exportForm.get('note').valueChanges,
    ])
      .pipe(
        filter(
          ([noteFieldValidation, noteQuery]: [string, string]) =>
            noteFieldValidation === 'VALID'
        ),
        debounceTime(1000),
        distinctUntilChanged(isEqual),
        tap(
          ([noteValidation, noteQuery]: [string, string]) =>
            (this.note = noteQuery)
        )
      )
      .subscribe(() => {
        this.generateTargetSummaryModel(this.filteredValues);
      });
  }

  optionsFormSubscription(): void {
    const optionsFormSubscription = this.exportForm
      .get('options')
      .valueChanges.subscribe((value: string) => {
        if (value === '1') {
          this.exportForm.get('sections').disable({ emitEvent: false });
          this.exportForm.get('sections').reset();
          this.generateTargetSummaryModel();
        } else {
          this.customFormAvailableSections.forEach(
            (item: TargetSummarySectionIdentifier) => {
              if (item) {
                const section = Object.keys(
                  TargetSummarySectionIdentifier
                ).find((i) => TargetSummarySectionIdentifier[i] === item);
                this.exportForm.get('sections').get(section).enable();
              }
            }
          );
        }
      });
    this.subscriptions.push(optionsFormSubscription);
  }

  availableFormSections(section: TargetSummarySectionIdentifier): void {
    this.customFormAvailableSections.push(section);
  }

  sectionsFormSubscription() {
    const sectionsFormSubscription = this.exportForm
      .get('sections')
      .valueChanges.pipe(
        debounceTime(2500),
        distinctUntilChanged(),
        map((filteredValues) => {
          return {
            isFalse: Object.keys(filteredValues).every(
              (k) => !filteredValues[k]
            ),
            filteredValues,
          };
        })
      )
      .subscribe(
        (sectionsValue: {
          isFalse: Boolean;
          filteredValues: { [key: string]: boolean };
        }) => {
          if (!sectionsValue.isFalse) {
            this.filteredValues = sectionsValue.filteredValues;
            this.generateTargetSummaryModel(sectionsValue.filteredValues);
          }
        }
      );
    this.subscriptions.push(sectionsFormSubscription);
  }

  returnSectionGroup(): { [key: string]: FormControl } {
    const sections: { [key: string]: FormControl } = {};
    Object.keys(TargetSummarySectionIdentifier).forEach((el) => {
      sections[el] = this.formBuilder.control({ value: false, disabled: true });
    });
    return sections;
  }

  get sections(): FormGroup {
    return this.exportForm.get('sections') as FormGroup;
  }

  bumpProgressBar(section: TargetSummaryReportProgressBarElements): void {
    this.progressBarElements.find((el) => el.name === section).value = true;
    this.progressBarValue =
      this.progressBarValue +
      100 / Object.keys(this.progressBarElements).length;
  }

  onSubmit(): void {
    const title = this.target.alias ? this.target.alias : this.target.names[0];
    this.reportService.downloadPDF(
      title.split(' ').join('_') + '_summary',
      this.filePreviewUrl
    );
    this.dialogRef.close();
  }
}
