import { BillingLimitType, LimitType } from './billing-action.model';

export enum PoolFeatures {
  DEFAULT = 'DefaultPool',
  GEOLOCATION = 'Geolocation',
  WEBINT = 'WEBINT',
  CALL_LOG = 'Call Log',
  SOMEDUS = 'Somedus',
}

export enum CreditPoolTypes {
  GEOLOCATION = 'geolocation',
  WEBINT = 'webint',
  CALLLOG = 'callLog',
  SOMEDUS = 'somedus',
  TOTAL = 'total',
}

export type CreditPools = {
  [key in CreditPoolTypes]?: number;
};

export const PoolFeaturesLabelsMap: { [key: string]: PoolFeatures } = {
  geolocation: PoolFeatures.GEOLOCATION,
  webint: PoolFeatures.WEBINT,
  callLog: PoolFeatures.CALL_LOG,
  somedus: PoolFeatures.SOMEDUS,
};

export const ConcurrentPoolFeaturesLabelsMap: { [key: string]: string } = {
  geolocation: 'Discovery',
  webint: 'Profiling',
  somedus: 'Somedus',
};

export const ConcurrentPoolQuotaType: { [key: string]: string } = {
  geolocation: 'daily',
  webint: 'monthly',
  somedus: 'daily',
};

export const LimitTypeLabelsMap: { [key in BillingLimitType]: LimitType } = {
  [BillingLimitType.DAILY]: LimitType.DAILY,
  [BillingLimitType.MONTHLY]: LimitType.MONTHLY,
  [BillingLimitType.MAXIMUM]: LimitType.MAXIMUM,
  [BillingLimitType.FIXED]: LimitType.FIXED,
};

export interface AvailablePool {
  value: string;
  label: PoolFeatures;
  unassignCredits: number;
}
