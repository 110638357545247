<div class="loader-position" *ngIf="showLoader">
  <mat-spinner class="spinner-loader" color="accent"></mat-spinner>
</div>
<!-- Old Intel Search Loader for "Search by keyword" feature -->
<div class="loader-show" *ngIf="searchIntelLoading">
  <app-intel-loader></app-intel-loader>
</div>
<form
  class="example-form search-input-form"
  [class.shadow]="searchDrop && (!isMobile || searchText.value?.length)"
  [class.search-navbar]="searchType === 'OSINT'"
  [ngClass]="{ 'empty-state-form': !searchFields.length && !searchDrop }"
>
  <mat-form-field
    *ngIf="!importFilters?.searchByKeyword"
    class="example-chip-list intel-search"
    appearance="outline"
    #matFormField
  >
    <div
      class="empty-state-search-icon"
      *ngIf="
        !searchFields.length &&
        !searchDrop &&
        navBarIdentifier === availableNavBarIdentifier.MAINNAVBAR
      "
    >
      <mat-icon>search</mat-icon>
    </div>
    <div
      class="type-effect-wrapper"
      (click)="hideTypingEffect()"
      *ngIf="
        typingEffect && enableCovid19MX && searchType === IntelSearchType.OSINT
      "
    >
      <div class="typing-effect {{ appLanguage | async }}">
        {{ 'We now support search by CURP ID' | translate }}
      </div>
    </div>
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let field of searchFields; let i = index"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(i)"
      >
        <div class="search-item-chip">
          <div class="item-value row">
            <input
              *ngIf="
                field.label === searchLabels.PHONE ||
                field.label === searchLabels.POSSIBLE_TELNO
              "
              matInput
              type="text"
              ng2TelInput
              style="width: 30px"
              [ng2TelInputOptions]="{
                initialCountry: field.countryFlag || '',
                allowDropdown: true,
                autoPlaceholder: 'off'
              }"
              (countryChange)="onCountryChange($event)"
            />

            <ng-container *ngIf="field.label !== 'Image'">
              <span>{{ field.value }}</span>
            </ng-container>
            <ng-container *ngIf="field.label === 'Image'">
              <span *ngIf="field.displayName">
                {{ field.displayName ? field.displayName : field.value }}</span
              >
              <span *ngIf="!field.displayName">
                {{ field.filename ? field.filename : field.value }}</span
              >
            </ng-container>
          </div>
          <div class="item-desc assistant-font">
            <span>{{
              (field.label === searchLabels.POSSIBLE_TELNO
                ? searchLabels.PHONE
                : field.label
              ) | translate
            }}</span>
          </div>
        </div>
      </mat-chip>

      <input
        class="intel-navbar-search-text"
        [class.placeholder-color]="!typingEffect"
        matInput
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
        type="text"
        autocomplete="off"
        placeholder="{{
          searchFields?.length > 0 ? '' : (queryPlaceholder | translate) + '...'
        }}"
        (click)="onSearchDrop()"
        #searchFieldInput
        [formControl]="searchText"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      />
    </mat-chip-list>
    <div class="btn-wrp" *ngIf="searchType === 'OSINT' && !isMobile">
      <button
        mat-button
        class="image-btn"
        (click)="addImageDialog()"
        matTooltip="{{ 'Find profiles by face and name' | translate }}"
      >
        <img
          src="assets/static/images/search-intel/icons-24-search.svg"
          alt="credits"
        />
      </button>
    </div>
  </mat-form-field>

  <mat-form-field
    *ngIf="importFilters?.searchByKeyword"
    class="example-chip-list intel-search keyword-input"
    appearance="outline"
    #matFormField
  >
    <div
      class="empty-state-search-icon"
      *ngIf="
        !searchFields.length &&
        !searchDrop &&
        navBarIdentifier === availableNavBarIdentifier.MAINNAVBAR
      "
    >
      <mat-icon>search</mat-icon>
    </div>
    <div
      class="type-effect-wrapper"
      (click)="hideTypingEffect()"
      *ngIf="
        typingEffect && enableCovid19MX && searchType === IntelSearchType.OSINT
      "
    >
      <div *ngIf="!searchFields.length" class="typing-effect-by-keyword">
        {{ 'Start typing your keyword' | translate }}
      </div>
    </div>

    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let field of searchFields; let i = index"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(i)"
      >
        <div class="search-item-chip">
          <div class="item-value row">
            <ng-container *ngIf="field.label !== 'Image'">
              <span> {{ field.value }}</span>
            </ng-container>
          </div>
          <div class="item-desc assistant-font">
            <span>{{
              (field.label === searchLabels.POSSIBLE_TELNO
                ? searchLabels.PHONE
                : field.label
              ) | translate
            }}</span>
          </div>
        </div>
      </mat-chip>
      <input
        class="intel-navbar-search-text"
        matInput
        (keydown)="onKeywordsEntry($event)"
        type="text"
        autocomplete="off"
        placeholder="{{
          !searchFields.length ? ('Start typing your keyword' | translate) : ''
        }}"
        (click)="onSearchDrop()"
        #searchFieldInput
        [formControl]="searchText"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      />
    </mat-chip-list>
  </mat-form-field>
</form>

<span *ngIf="theme === 'UNLIMITED' && searchType === 'OSINT'" class="demo-sign"
  >DEMO</span
>
<!-- Search Drop Down | Suggestions-->
<div
  class="search-drop"
  [hidden]="
    !(searchDrop && searchType === 'OSINT') || (isMobile && !searchText.value)
  "
  (clickOutside)="clickedOutside()"
  [@openClose]="searchDrop ? 'open' : 'closed'"
>
  <div
    *ngIf="!importFilters?.searchByKeyword && searchText.value"
    class="labels-list example-details"
    intellectusIsMobile
  >
    <mat-selection-list
      #searchList
      (selectionChange)="onClickSearchItem(searchList.selectedOptions.selected)"
    >
      <mat-list-option *ngFor="let inputType of inputTypes" [value]="inputType">
        <app-input-type-content
          [inputType]="inputType"
        ></app-input-type-content>
      </mat-list-option>
    </mat-selection-list>
  </div>

  <!-- Search Filters -->
  <div class="intel-filters" *ngIf="showFilter">
    <app-intel-filters
      (emitFilters)="getIntelFilters($event)"
      [closeAllFilters]="searchDrop"
      (click)="$event.stopPropagation()"
    ></app-intel-filters>
    <div class="btn-wrp" *ngIf="billingPlan$ | async as billingPlan">
      <button
        mat-button
        class="cp-btn-primary cp-search-btn"
        [class.active]="btnActive && billingPlan?.intelSearch?.cost >= 0"
      >
        <span *ngIf="!importFilters?.searchByKeyword" (click)="search($event)">
          <span class="btn-txt">{{ 'Find profiles' | translate }}</span>
          <ng-container
            *ngIf="
              (searchText.value || searchFields.length) &&
              billingPlan?.intelSearch?.cost >= 0
            "
          >
            <span>|</span>
            <span class="credits">{{ billingPlan?.intelSearch?.cost }}</span>
          </ng-container>
        </span>
        <span
          (click)="searchByKeyword()"
          *ngIf="importFilters?.searchByKeyword"
        >
          <span class="btn-txt">{{ 'Search keyword' | translate }}</span>
          <ng-container *ngIf="searchText.value || searchFields.length">
          </ng-container>
        </span>
        <mat-icon
          [matMenuTriggerFor]="creditsMenu"
          #creditsMenuTrigger="matMenuTrigger"
          *ngIf="!isLocatingDisabled && inputIsTelno"
          >arrow_drop_down</mat-icon
        >
      </button>
      <mat-menu class="credits-menu" #creditsMenu="matMenu">
        <button
          mat-menu-item
          class="row align-items-center justify-content-between"
          (click)="searchAndLocate($event)"
        >
          {{ 'Find profiles & locate phone' | translate }}
          <span
            *ngIf="
              billingPlan?.intelSearch?.cost && billingPlan?.queryLocation?.cost
            "
            class="credits"
            >{{
              billingPlan?.intelSearch?.cost + billingPlan?.queryLocation?.cost
            }}</span
          >
        </button>
        <button
          mat-menu-item
          class="row align-items-center justify-content-between"
          (click)="locate()"
        >
          {{ 'Locate phone' | translate }}
          <span *ngIf="billingPlan?.queryLocation?.cost" class="credits">{{
            billingPlan?.queryLocation?.cost
          }}</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <ng-container
    *ngIf="!isMobile"
    [ngTemplateOutlet]="historyList"
    [ngTemplateOutletContext]="{ isMobile: false }"
  ></ng-container>
</div>

<ng-container
  *ngIf="isMobile"
  [ngTemplateOutlet]="historyList"
  [ngTemplateOutletContext]="{ isMobile: true }"
></ng-container>

<ng-template #historyList let-isMobile="isMobile">
  <ul
    class="history-list assistant-font"
    intellectusIsMobile
    [@.disabled]="isMobile || (searchDrop && animationLocked)"
  >
    <li>
      <div class="recent-search">{{ 'Recent searches' | translate }}</div>
      <ng-container *ngFor="let search of searchHistory; let i = index">
        <div
          intellectusIsMobile
          class="value-wrp flex-wrp clickable-content search-item"
          angulartics2On="click"
          [angularticsAction]="matomo.actions.recentSearchItem"
          [angularticsCategory]="matomo.categories.landingPage"
          *ngIf="i < 5"
          [@openClose]="searchDrop || isMobile ? 'open' : 'closed'"
        >
          <div
            class="d-flex justify-content-start align-items-center history-item-container"
          >
            <img
              (click)="makeFavorite(true, search.id)"
              *ngIf="!search.favorite"
              src="assets/static/images/search-intel/star-empty.svg"
              alt="favorites"
            />
            <img
              (click)="makeFavorite(false, search.id)"
              *ngIf="search.favorite"
              src="assets/static/images/search-intel/star-full.svg"
              alt="favorites"
            />
            <span class="history-item" (click)="showPreviousSearch(search)">
              <span [innerHTML]="search.queryArgsDisplay"></span>
            </span>
          </div>
          <span class="found" *ngIf="!isMobile"
            >{{
              search.profilesCount + search.articlesCount + search.hashtagCount
            }}
            {{ 'results' | translate }}</span
          >
        </div>
      </ng-container>
    </li>
    <li
      class="history-list clickable"
      angulartics2On="click"
      [angularticsAction]="matomo.actions.viewSearchHistory"
      [angularticsCategory]="matomo.categories.landingPage"
      [@openClose]="searchDrop || isMobile ? 'open' : 'closed'"
    >
      <a (click)="routerHelperService.routeToHistory()">{{
        'See search history' | translate
      }}</a>
    </li>
  </ul>
</ng-template>
