import { ActivatedRouteSnapshot } from '@angular/router';
import { Breadcrumb } from '@fe-platform/shared-ui/intellectus';

export function buildBreadcrumbs(
  snapshot: ActivatedRouteSnapshot,
  url: string = '',
  breadcrumbs: Breadcrumb[] = []
): Breadcrumb[] {
  let title: string =
    snapshot.routeConfig && snapshot.routeConfig.data
      ? snapshot.routeConfig.data.title
      : '';
  let path: string =
    snapshot.routeConfig && snapshot.routeConfig.data
      ? snapshot.routeConfig.path
      : '';

  const lastRoutePart = path.split('/').pop();
  const isDynamicRoute = lastRoutePart.startsWith(':');
  if (isDynamicRoute) {
    const paramName = lastRoutePart.split(':')[1];
    path = path.replace(lastRoutePart, snapshot.params[paramName]);
  }

  const nextUrl = path ? `${url}/${path}` : url;
  if (
    breadcrumbs.some(
      (breadcrumb: Breadcrumb): boolean => breadcrumb.link === nextUrl
    )
  ) {
    return;
  }

  const newBreadcrumb: Breadcrumb = {
    title,
    link: nextUrl,
  };

  const newBreadcrumbs = newBreadcrumb.title
    ? [...breadcrumbs, newBreadcrumb]
    : [...breadcrumbs];

  if (snapshot.firstChild) {
    return buildBreadcrumbs(snapshot.firstChild, nextUrl, newBreadcrumbs);
  }
  return newBreadcrumbs;
}
