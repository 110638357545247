import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
})
export class BaseModalComponent implements OnInit {
  @Output()
  public close = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<unknown>) {}

  ngOnInit(): void {
    this.dialogRef.addPanelClass('base-modal');
  }

  onClose() {
    if (this.close.observed) {
      this.close.emit();
    } else {
      this.dialogRef.close();
    }
  }
}
