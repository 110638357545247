import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { map, Observable, skipWhile, take } from 'rxjs';
import { SessionService } from './session.service';
import { RedirectionRoutes } from './types';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly sessionService: SessionService,
    private readonly appConfigService: AppConfigService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | boolean {
    if (this.sessionService.isValid()) {
      this.appConfigService.fetchAuthenticatedConfig();
      return this.appConfigService.authenticatedConfigLoaded$.pipe(
        skipWhile((v) => !v),
        take(1),
        map(() => true)
      );
    } else {
      this.router.navigate([RedirectionRoutes.LOGIN]);
      return false;
    }
  }
}
