import {
  BackendSession,
  Session,
} from '../../../../profiler/modules/instant-messaging-analytics/interfaces/session.interface';
import {
  BackendIpLocationInfo,
  IpLocationInfo,
} from '../../../../profiler/modules/instant-messaging-analytics/interfaces/ip-location-info.interface';

export interface Device {
  device?: string;
  os?: string;
  system_version?: string;
  session?: Session;
  ipLocationInfo?: IpLocationInfo;
}

export interface BackendDevice {
  device?: string;
  os?: string;
  system_version?: string;
  session?: BackendSession;
  ip_location_info?: BackendIpLocationInfo;
}
