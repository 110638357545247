import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalConfig } from '../../type/modal-config.type';
import {
  FontAwesomeFamily,
  FontAwesomeIcon,
} from '@fe-platform/shared-ui/intellectus';

@Component({
  selector: 'intellectus-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent {
  @Input()
  public config!: ModalConfig;

  @Input()
  public isMobile!: boolean;

  @Output()
  public readonly closeClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly breadcrumbClick: EventEmitter<number> = new EventEmitter<number>();

  public readonly closeIcon: FontAwesomeIcon = {
    family: FontAwesomeFamily.SOLID,
    name: 'xmark',
  };

  public readonly delimiterIcon: FontAwesomeIcon = {
    family: FontAwesomeFamily.SOLID,
    name: 'angle-right',
  };

  public readonly backCloseIcon: FontAwesomeIcon = {
    name: 'chevron-left',
    family: FontAwesomeFamily.REGULAR,
  };
}
