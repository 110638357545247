import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Subject, Subscription } from 'rxjs';
import { DependencyInjectionService } from 'src/app/services/dependency-injection.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit, OnDestroy {
  defaultSnackbarHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  defaultSnackbarVerticalPosition: MatSnackBarVerticalPosition = 'top';
  subscriptions: Subscription[] = [];
  intelSubscriptions: Subscription[] = [];
  public destroyed$ = new Subject();

  // Injected deps
  public snackBar: MatSnackBar | null = null;

  constructor() {
    // We now get the dependencies from our injector instead of passing them up from the childre
    // (that would not be a scaleable approach)
    const dependencyInjector = DependencyInjectionService.getInjector();
    if (null !== dependencyInjector) {
      this.snackBar = dependencyInjector.get(MatSnackBar);
    }
  }

  protected addIntelSubscriptions(subscription: Subscription) {
    this.intelSubscriptions.push(subscription);
  }

  protected resetIntelSubscriptions() {
    (this.intelSubscriptions || []).forEach((s) => s.unsubscribe());
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
    this.resetIntelSubscriptions();
    this.destroyed$.next(true);
  }

  protected showMessage(
    msg: string,
    okText = 'OK',
    preferredDuration?: number,
    preferredHorizontalPosition = this.defaultSnackbarHorizontalPosition,
    preferredVerticalPosition = this.defaultSnackbarVerticalPosition
  ) {
    if (!this.snackBar) {
      throw new Error('Snack Bar not yet initialised');
    }

    this.snackBar.open(msg, okText, {
      duration: preferredDuration || 3000,
      horizontalPosition: preferredHorizontalPosition,
      verticalPosition: preferredVerticalPosition,
      panelClass: ['custom-snackbar'],
    });
  }
}
