import { JwtHelperService } from '@auth0/angular-jwt';
import { differenceInMinutes } from 'date-fns';

export class Token extends String {
  private jwtHelperService: JwtHelperService = new JwtHelperService();

  constructor(token: string = '') {
    super(token);
  }

  public get expiresInMinutes() {
    if (this.valid) {
      const { exp } = this.decoded;
      return exp
        ? differenceInMinutes(parseInt(exp, 10) * 1000, new Date())
        : -1;
    }
    return -1;
  }

  public get valid() {
    return !this.jwtHelperService.isTokenExpired(this.toString());
  }

  public get decoded() {
    return this.jwtHelperService.decodeToken(this.toString());
  }
}
