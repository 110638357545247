import {
  Directive,
  Input,
  ViewContainerRef,
  TemplateRef,
  OnInit,
} from '@angular/core';
import { head } from 'lodash-es';
import { RoleManagementService } from 'src/app/services/roles/role-management.service';
import { UserRoles } from 'src/app/services/user/user.model';
@Directive({
  selector: '[accessControlRestricted]',
})
export class AccessControlRestrictedDirective implements OnInit {
  @Input() accessControlRestricted: UserRoles[] = [];
  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private roleManagementService: RoleManagementService
  ) {}
  ngOnInit(): void {
    const userRole: UserRoles = <UserRoles>(
      head(this.roleManagementService.currentUser.roles)
    );
    const isAllowed = this.accessControlRestricted.includes(userRole);
    if (isAllowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
