import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { TermsOfUseService } from 'src/app/services/authentication/terms-of-use.service';
import { QuestionnaireService } from 'src/app/services/questionnaire/questionnaire.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit {
  constructor(
    private authSessionService: AuthService,
    private cdRef: ChangeDetectorRef,
    private questionnaireService: QuestionnaireService,
    protected route: ActivatedRoute,
    protected router: Router,
    private appConfig: AppConfigService,
    private localStorageService: LocalStorageService,
    private termsOfUseService: TermsOfUseService
  ) {}
  ngOnInit(): void {
    if (
      !this.appConfig.getConfig()?.enableTermsOfUse ||
      !this.appConfig.getConfig()?.termsOfUseAccountId ||
      !this.appConfig.getConfig()?.termsOfUseClickWrapId ||
      !this.localStorageService.getCurrentUser()?.identity
    ) {
      this.logout(false);
      return;
    }

    this.validateTermsOfUse();
  }

  private validateTermsOfUse() {
    let docuSignClick = window['docuSignClick'];

    docuSignClick.Clickwrap.render(
      {
        environment: this.appConfig.getConfig()?.termsOfUseEnvironmentUrl,
        accountId: this.appConfig.getConfig()?.termsOfUseAccountId,
        clickwrapId: this.appConfig.getConfig()?.termsOfUseClickWrapId,
        clientUserId: `${this.appConfig.getConfig()?.tenantId}-${
          this.localStorageService.getCurrentUser()?.identity
        }`,
        // Called immediately upon user clicking ‘Agree’
        // Any calls to download will not work until onAgreed has been called
        onAgreeing: (agreementData) => {
          this.postLoginFunctionality(true);
        },
        // Called when the user has previously agreed OR has just successfully completed the agreement AND response has been successfully stored
        onAgreed: (agreementData) => {
          this.postLoginFunctionality(false);
        },
        // Called immediately upon user clicking ‘Decline’
        onDeclining: (agreementData) => {
          this.logout(true);
        },
        // Called when a user declines and a response has been successfully stored (if Record Decline Responses is enabled)
        onDeclined: (agreementData) => {
          this.logout(false);
        },
        // Called if any errors occur during agreement or configuration
        onError: (error) => {
          this.logout(false);
        },
      },
      '#trg-ds-clickwrap'
    );
  }

  private logout(reload: boolean): void {
    this.authSessionService.logout(reload);
    this.cdRef.markForCheck();
  }

  private postLoginFunctionality(reload: boolean): void {
    this.termsOfUseService.agreedToTermsOfUse = true;
    sessionStorage.setItem('agreedToTermsOfService', 'true');
    this.questionnaireService.displayQuestionnaireDialog();

    const navigateTo =
      history.state.data?.redirectUrl ||
      this.authSessionService.getDefaultRedirectRoute();

    this.router.navigate([navigateTo]).then(() => {
      if (reload) {
        window.location.reload();
      }
    });
  }
}
