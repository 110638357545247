import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ClusterIconStyle } from '@googlemaps/markerclustererplus';
import {
  Button,
  Circle,
  DrawMode,
  DrawModeOptions,
  Feature,
  MapOptions,
  MapTypeId,
  Marker,
  Point,
  Polygon,
  Polyline,
  Rectangle,
  StyledMapTypeOptions,
  TileSource,
} from 'src/app/modules/mapV2/models/map.models';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { CallLogFeatureData } from 'src/app/shared/models/call-log-feature-data.model';
import { MapType } from '../../models/map-type.enum';
import { HeatMap } from '../../models/map.models';

@Directive()
export abstract class BaseMapComponent extends BaseComponent {
  @Input() mapOptions: MapOptions = new MapOptions();

  @Input() loading: boolean = false;
  @Input() height: string;
  @Input() showExpandBtn: boolean = false;
  @Input() maximized: boolean = false;

  @Input() showLoaderOnInit: boolean = false;
  @Input() center: Point;
  @Input() markers: Marker[] = [];
  @Input() legendIcons: { [key: string]: string } = {};
  @Input() circles: Circle[] = [];
  @Input() polygons: Polygon[] = [];
  @Input() rectangles: Rectangle[] = [];
  @Input() polyLines: Polyline[] = [];
  @Input() points: Point[] = [];
  @Input() zoom: number;
  @Input() trafficEnabled: boolean;
  @Input() heatmapEnabled: boolean;
  @Input() nearbyLocationsEnabled: boolean;
  // TODO: remove this input and usee the polygoen one for timeline
  @Input() historyTimelineEnabled: boolean;
  @Input() enableClustering: boolean;
  @Input() enableDrawing: DrawMode | DrawModeOptions;
  @Input() enableSearching: boolean;
  @Input() mapTileSource: TileSource;
  @Input() buttons: Button[] = [];
  @Input() feature: Feature;
  @Input() freeze: boolean;
  @Input() heatMaps: HeatMap[] = [];
  @Input() clustererStyles: ClusterIconStyle[];

  @Input() callLogClusteringData: CallLogFeatureData;
  @Input() showMarkerInfoWindow: boolean;
  @Input() requestedMap: MapType;
  @Input() mapTypeId: MapTypeId;
  @Input() maxZoomClusterDistance: number;
  @Input() styledMapTypeCustomOptions: StyledMapTypeOptions;
  @Input() mapFitBounds: boolean;

  @Output() circleDrawn = new EventEmitter<Circle>();
  @Output() userLocation = new EventEmitter<Point>();
  @Output() stopDrawing = new EventEmitter<any>();
  @Output() rectangleDrawn = new EventEmitter<Rectangle>();
  @Output() renderingCompleted = new EventEmitter<void>();
  @Output() markerDrawn = new EventEmitter<Point>();
  @Output() markerClicked = new EventEmitter<string>();
  @Output() mapLoaded = new EventEmitter<boolean>();
  @Output() clusterClicked = new EventEmitter<any>();
  @Output() zoomChanged = new EventEmitter<number>();
  @Output() markersAdded = new EventEmitter<void>();
  @Output() streetViewMode = new EventEmitter<boolean>();
  @Output() tilesLoadedChanges = new EventEmitter<void>();
  @Output() visibleMarkers = new EventEmitter<Marker[]>();
}
