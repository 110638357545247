import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { Themes } from 'src/app/shared/models/skins.model';
import { BillingService } from 'src/app/services/billing/billing.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { Observable } from 'rxjs';
import {
  BillingActions,
  BillingActionType,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';

@Component({
  selector: 'app-msisdn-confirmation-popup',
  templateUrl: './msisdn-confirmation-popup.component.html',
  styleUrls: ['./msisdn-confirmation-popup.component.scss'],
})
export class MsisdnConfirmationPopupComponent
  extends BaseComponent
  implements OnInit
{
  onLocate: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string[],
    private appConfigService: AppConfigService,
    private billingService: BillingService
  ) {
    super();
  }
  theme: Themes;
  public billingPlan$: Observable<
    BillingPlan<BillingActions, BillingActionType>
  >;

  ngOnInit() {
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.billingPlan$ = this.billingService.getBillingPlan().asObservable();
  }

  onLocateTelnos() {
    this.onLocate.emit(this.data);
  }
}
