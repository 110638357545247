import { Injectable } from '@angular/core';
import { OTPChannels } from 'src/app/components/user-settings/user-settings.models';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { User, UserBalance, UserRoles } from 'src/app/services/user/user.model';
import {
  BillingLimitType,
  BillingPolicies,
  LimitType,
} from 'src/app/shared/models/billing-action.model';
import { CreditPools } from 'src/app/shared/models/credit-pools.model';
import { UserForm } from './user-dialog.model';

@Injectable()
export class UserDialogService {
  constructor(private userBillingService: UserBillingService) {
    this.userBillingService
      .isTenantExpired()
      .subscribe((isExpired) => (this.CREDITS_EXPIRED = isExpired));
  }
  public readonly UNASSIGN_FORM_POOL_PREFIX: string = 'unassign_';
  public CREDITS_EXPIRED: boolean = false;

  public getCreateUserRequestPayload(userForm): Partial<User> {
    let { email, otpPhone, feature, role, otpChannel, otpEnabled, username } =
      userForm;

    const newUser: Partial<User> = {
      username: username.replace(/\s/g, ''),
      email: !!email && email.length === 0 ? null : email,
      otpPhone,
      roles: [role],
      otpEnabled,
      otpChannel,
      features: [feature],
    };

    if (newUser.roles.includes(UserRoles.SUPPORT)) {
      newUser.email = null;
      newUser.otpEnabled = true;
      newUser.otpChannel = OTPChannels.Email;
      newUser.emailNotifications = true;
    }

    return newUser;
  }

  public getEditUserRequestPayload(
    incomingUserData: UserForm,
    user: User
  ): Partial<User> {
    return {
      ...this.getEditedUserFormFields(incomingUserData),
      ...this.handleOTPMethods(incomingUserData, user),
    };
  }

  public getUserBillingRequestPayload(incomingUserData: UserForm): UserBalance {
    let { limitType, defaultPool } = incomingUserData;
    let incomingBalance: CreditPools | number = null;
    const policies: BillingPolicies[] = [];

    if (this.userBillingService.isDistributedBalance()) {
      incomingBalance = {} as CreditPools;
      this.userBillingService
        .getAvailablePools()
        .map((pool) => pool.value)
        .forEach(
          (poolValue) =>
            (incomingBalance[poolValue] = incomingUserData[poolValue])
        );
    }

    if (LimitType.FIXED === limitType) {
      limitType = null;
    }

    if ([LimitType.DAILY, LimitType.MONTHLY].includes(limitType)) {
      policies.push(BillingPolicies.USAGE_LIMITS);
    }

    if (LimitType.MAXIMUM === limitType) {
      policies.push(BillingPolicies.MAX_USAGE);
      if (!this.userBillingService.isDistributedBalance()) {
        defaultPool = this.userBillingService.getTenantUnassignCredits();
      } else {
        this.userBillingService.getAvailablePools().forEach((pool) => {
          incomingBalance[pool.value] = pool.unassignCredits;
        });
      }
    }

    const requestPayload: UserBalance = {
      initialBalance:
        incomingBalance && Object.keys(incomingBalance as CreditPools).length
          ? incomingBalance
          : defaultPool,
      limitType: (!!limitType
        ? limitType.toLocaleUpperCase()
        : limitType) as BillingLimitType,
      balanceType: this.userBillingService.getTenantBalanceTypeValue(),
      policies: policies,
    };

    return requestPayload;
  }

  private getEditedUserFormFields(incomingUserData: UserForm): Partial<User> {
    const editedUser: Partial<User> = {};
    editedUser.roles = [incomingUserData.role];
    editedUser.features = [incomingUserData.feature];
    editedUser.otpPhone = incomingUserData.otpPhone;
    editedUser.email = incomingUserData.email;
    return editedUser;
  }

  private handleOTPMethods(
    incomingUserData: UserForm,
    user: User
  ): Partial<User> {
    const editedUser: Partial<User> = {};
    // disable sms notifications if the user removes the phone
    if (
      !incomingUserData?.otpPhone?.length ||
      incomingUserData.otpPhone !== user?.otpPhone
    ) {
      editedUser.smsNotifications = false;
      if (user.otpChannel === OTPChannels.OtpPhone && user.otpEnabled) {
        editedUser.otpChannel = OTPChannels.None;
        editedUser.otpEnabled = false;
      }
    }

    // disable email notifications if the user removes the phone
    if (
      !incomingUserData?.email?.length ||
      incomingUserData.email !== user?.email
    ) {
      editedUser.emailNotifications = false;
      if (user.otpChannel === OTPChannels.Email && user.otpEnabled) {
        editedUser.otpChannel = OTPChannels.None;
        editedUser.otpEnabled = false;
      }
    }
    return editedUser;
  }
}
