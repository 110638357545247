import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: any, keywords: string[]): any {
    if (!keywords || !Array.isArray(keywords) || !keywords.length) {
      return value;
    }
    return keywords.reduce((result, word) => {
      const reg = new RegExp(word, 'gi');
      result = result.replace(reg, '<mark>$&</mark>');
      return result;
    }, value);
  }
}
