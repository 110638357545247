import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  FontAwesomeFamily,
  FontAwesomeIcon,
  LayoutService,
} from '@fe-platform/shared-ui/intellectus';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { IntelSearchTrackerService } from 'src/app/services/intel-search-tracker/intel-search-tracker.service';
import { NotificationListService } from '../../notifications-list/notification-list.service';

@UntilDestroy()
@Component({
  selector: 'app-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavbarComponent implements AfterViewInit, OnInit {
  notificationIds = [];
  notificationsCount = 0;
  hasRegularNotifications = false;

  @Input()
  public title: string;

  @Input()
  public clickableTitle: boolean = false;

  @Input()
  public titleButtonState: boolean = false;

  @Input()
  public highlightClass: string;

  @Output()
  public readonly onTitleClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly onMenuClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly onSearchIconClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly onNotificationListClicked: EventEmitter<void> = new EventEmitter<void>();

  public readonly hamburgerIcon: FontAwesomeIcon = {
    name: 'bars',
    family: FontAwesomeFamily.REGULAR,
  };

  public readonly notificationIcon: FontAwesomeIcon = {
    name: 'bell',
    family: FontAwesomeFamily.REGULAR,
  };

  public readonly collapseIcon: FontAwesomeIcon = {
    name: 'circle-chevron-down',
    family: FontAwesomeFamily.REGULAR,
  };

  public isMobileDevice: boolean;

  public readonly allSearchCount$: Observable<number> =
    this.intelSearchTrackerService.getSearchCount();

  public readonly activeSearchCount$: Observable<number> =
    this.intelSearchTrackerService.getSearchCount(true);

  constructor(
    private readonly intelSearchTrackerService: IntelSearchTrackerService,
    private readonly layoutService: LayoutService,
    private readonly cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private notificationListService: NotificationListService
  ) {}

  public ngAfterViewInit(): void {
    this.isMobileDevice = this.layoutService.isMobileDevice();
  }

  ngOnInit(): void {
    this.notificationListService
      .getNotificationMessages()
      .pipe(untilDestroyed(this))
      .subscribe(({ count }) => {
        this.notificationsCount = count;
        this.cdRef.markForCheck();
      });
  }

  notificationClicked(): void {
    this.onNotificationListClicked.emit();
  }
}
