import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EMPTY, Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { LedgerService } from 'src/app/services/ledger/ledger.service';
import { User } from 'src/app/services/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import {
  ConcurrentPoolFeaturesLabelsMap,
  CreditPoolTypes,
  CreditPools,
} from 'src/app/shared/models/credit-pools.model';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';
import {
  AvailableConcurrentCredits,
  ConcurrentCreditsSublabel,
  ConcurrentCreditsSublabelValue,
  CreditGaugeColors,
  gaugeOptions,
} from './gauge-types';
import { createAvailableCreditBalance } from './utils/create-available-credit-balance';
import { getWarningMessageForCasesAndTargets } from './utils/get-warning-message';

@Component({
  selector: 'app-credits-gauge-concurrent',
  templateUrl: './credits-gauge-concurrent.component.html',
  styleUrls: ['./credits-gauge-concurrent.component.scss'],
})
export class CreditsGaugeConcurrentComponent
  extends BaseComponent
  implements OnInit
{
  gaugeOptions = { ...gaugeOptions };
  gaugeColors = CreditGaugeColors;
  isMobileResolution: boolean;
  availableConcurrentCredits: AvailableConcurrentCredits[] = [];
  matomo = {
    actions: matomoActions,
    categories: matomoCategories,
  };

  constructor(
    private ledgerService: LedgerService,
    protected userService: UserService,
    private applicationStateService: ApplicationStateService,
    private changeDetectorRef: ChangeDetectorRef,
    public userBillingService: UserBillingService
  ) {
    super();
    this.isMobileResolution =
      this.applicationStateService.getIsMobileResolution();
  }

  concurrentLimitBalance$: Observable<AvailableConcurrentCredits[]> =
    this.userBillingService.concurrentLimitsBalance$.pipe(
      map((limitBalance) => {
        const targetWidth =
          100 -
          (limitBalance.currentTargetCount / limitBalance.initialTargetLimit) *
            100;
        const caseWidth =
          100 -
          (limitBalance.currentCaseCount / limitBalance.initialCaseLimit) * 100;
        return [
          createAvailableCreditBalance(
            targetWidth,
            'Targets',
            limitBalance.initialTargetLimit - limitBalance.currentTargetCount,
            limitBalance.initialTargetLimit,
            ConcurrentCreditsSublabel.ANNUAL_QUOTA
          ),
          createAvailableCreditBalance(
            caseWidth,
            'Cases',
            limitBalance.initialCaseLimit - limitBalance.currentCaseCount,
            limitBalance.initialCaseLimit,
            ConcurrentCreditsSublabel.ANNUAL_QUOTA
          ),
        ];
      })
    );

  concurrentWarnings$: Observable<string | null> =
    this.concurrentLimitBalance$.pipe(
      map((concurrentLimitBalance) => {
        return getWarningMessageForCasesAndTargets(concurrentLimitBalance);
      })
    );

  ngOnInit() {
    if (this.isMobileResolution) {
      this.gaugeOptions = {
        ...this.gaugeOptions,
        foregroundColor: CreditGaugeColors.WHITE,
        backgroundColor: CreditGaugeColors.WHITE,
      };
    }

    const isTenantLoaded$: Observable<boolean> =
      this.userBillingService.isTenantLoaded();
    const isTenantExpired$: Observable<boolean> =
      this.userBillingService.isTenantExpired();

    this.subscriptions.push(
      combineLatest([isTenantExpired$, isTenantLoaded$])
        .pipe(
          switchMap(([isTenantExpired, isTenantLoaded]) => {
            if (isTenantExpired) {
              this.gaugeOptions.gaugeValue = 0;
              return EMPTY;
            }
            return this.ledgerService.getCurrentUserLedgerItem();
          })
        )
        .subscribe((user: User) => this.getUserLedgerDetails(user))
    );
  }

  private getUserLedgerDetails(user: User) {
    this.setGauge(user);
    this.getCreditPools(user);
  }

  private getCreditPools(user: User): void {
    const concurrentPools = [
      CreditPoolTypes.GEOLOCATION,
      CreditPoolTypes.SOMEDUS,
      CreditPoolTypes.WEBINT,
    ] as string[];

    const tenantInitialBalance =
      this.userBillingService.getTenantInitialBalanceCreditPools();
    const availablePools: string[] = this.userBillingService
      .getAvailablePools()
      .filter((pool) => concurrentPools.indexOf(pool.value) >= 0)
      .map((pool) => pool.value);

    this.availableConcurrentCredits = availablePools.map((pool) => {
      const currentBalance = user?.currentBalance[pool] as number;
      const maxBalance = !!tenantInitialBalance
        ? tenantInitialBalance[pool]
        : user?.initialBalance[pool];
      const width = (currentBalance / maxBalance) * 100;

      return createAvailableCreditBalance(
        width,
        ConcurrentPoolFeaturesLabelsMap[pool],
        currentBalance,
        maxBalance,
        ConcurrentCreditsSublabelValue[pool]
      );
    });

    this.changeDetectorRef.markForCheck();
  }

  setGauge(user: User) {
    const isDistributed = this.userBillingService.isDistributedBalance();
    const initialBalance: number =
      this.userBillingService.getConcurrentStartingBalance(user);
    const currentCredits: number =
      this.userBillingService.getConcurrentCurrentBalance(user);
    const from = Math.max(initialBalance, currentCredits);

    if (!(currentCredits && isDistributed)) {
      this.gaugeOptions.gaugeValue = 0;
    } else {
      this.gaugeOptions.gaugeValue = Math.floor((currentCredits / from) * 100);
    }

    this.setGaugeBackgroundColor();
  }

  creditMenuListClicked(): void {
    this.userBillingService.getTenantDetails().subscribe();
  }

  private setGaugeBackgroundColor(): void {
    this.gaugeOptions.backgroundColor = !this.gaugeOptions.gaugeValue
      ? CreditGaugeColors.RED
      : CreditGaugeColors.GREY;

    this.changeDetectorRef.markForCheck();
  }
}
