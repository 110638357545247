import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormControlStatus, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  FontAwesomeFamily,
  FontAwesomeIcon,
} from '@fe-platform/shared-ui/intellectus';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { BaseComponent } from 'src/app/base/base.component';
import { AuthDataService } from 'src/app/services/authentication/auth-data.service';
import {
  InputStatus,
  otpChannelLabel,
} from './../../../components/user-settings/user-settings.models';

@Component({
  selector: 'app-activate-qr-otp-code-input',
  templateUrl: './activate-qr-otp-code-input.component.html',
  styleUrls: ['./activate-qr-otp-code-input.component.scss'],
})
export class ActivateQrOtpCodeInputComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Input() errorMsg: string;
  @Input() showOtpImage?: boolean;
  @Input() oldChannel?: string;
  @Input() showMessageWithOldChannel = false;
  @Input() isPartOfUserSettings = false;
  @Output() otpControlChanged = new EventEmitter<InputStatus>();
  otpChannelLabel = otpChannelLabel;
  otpControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(6),
  ]);
  QRImage: BehaviorSubject<SafeUrl | undefined> = new BehaviorSubject<
    SafeUrl | undefined
  >(undefined);
  userSelectionMessage = '';

  visibility: boolean = false;
  public fAavailableIcons: { [name: string]: FontAwesomeIcon } = {
    eyeVisible: {
      name: 'eye',
      family: FontAwesomeFamily.REGULAR,
    },
    eyeHidden: {
      name: 'eye-slash',
      family: FontAwesomeFamily.REGULAR,
    },
  };

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthDataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.userSelectionMessage = `You selected to change OTP method from ${
      otpChannelLabel[this.oldChannel]
    } to application`;
    this.showOtpImage = this.showOtpImage ?? true;
    this.subscriptions.push(
      this.otpControl.statusChanges.subscribe((val: FormControlStatus) => {
        this.otpControlChanged.emit({
          value: this.otpControl.value,
          valid: val === 'VALID' ? true : false,
        });
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.showOtpImage) {
      this.subscriptions.push(
        this.authService
          .GenerateOTPAuthenticatorQRCode()
          .subscribe((imageURL) => {
            this.QRImage.next(this.sanitizer.bypassSecurityTrustUrl(imageURL));
          })
      );
    }
  }
}
