<div
  class="gauge-bars-container"
  *ngFor="let concurrentCredits of availableCredits"
>
  <div class="text-content">
    <span
      >{{ concurrentCredits.label }}: {{ concurrentCredits.currentBalance }}
      {{ 'out of' | translate }} {{ concurrentCredits.maxBalance }}
    </span>
    <span class="subtext">{{ concurrentCredits.sublabel | translate }}</span>
  </div>
  <div class="bar">
    <div
      class="val"
      [ngStyle]="{
        width: concurrentCredits.width,
        'background-color': concurrentCredits.color
      }"
    ></div>
  </div>
  <ng-container *ngIf="concurrentCredits.currentBalance === 0">
    <div
      *ngIf="
        concurrentCredits.label === creditLabels.geolocation ||
        concurrentCredits.label === creditLabels.somedus
      "
      class="message-warnings"
    >
      {{ concurrentCredits.label + ' will be updated at 12:00am' | translate }}
    </div>

    <div
      *ngIf="concurrentCredits.label === creditLabels.webint"
      class="message-warnings"
    >
      {{ concurrentCredits.label + ' will be updated next month' | translate }}
    </div>
  </ng-container>
</div>
