import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppConfigService } from '@app/config';
import { environment } from 'environment/environment';
import { Observable, skipWhile, take, tap } from 'rxjs';
import { appendExternalScript } from 'src/app/shared/util/appendExternalScript';

@Injectable({
  providedIn: 'root',
})
export class AppGuard implements CanActivate {
  constructor(private appConfig: AppConfigService) {}
  canActivate(): Observable<boolean> {
    this.appConfig.fetchAnonConfig();
    return this.appConfig.anonConfigLoaded$.pipe(
      skipWhile((loaded) => !loaded),
      take(1),
      tap(() => {
        const config = this.appConfig.getConfig();
        const hotjarKey = config.hotjarKey;
        try {
          if (hotjarKey && hotjarKey.length) {
            appendExternalScript('assets/scripts/hotjar.js', [
              { name: 'hotjarKey', value: config.hotjarKey },
            ]);
          }
        } catch (e) {
          if (!environment.production) {
            console.warn('failed to configure hotjar');
            console.error(e);
          }
        }
      })
    );
  }
}
