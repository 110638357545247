<p intellectusIsMobile class="flex-wrp user-name" *ngIf="!isLocatingDisabled">
  <span class="capitalize-none">
    {{ 'Settings' | translate }}
  </span>
</p>
<div class="toggle-btn flex-wrp" *ngIf="!isLocatingDisabled">
  <p>
    {{
      'Always obtain accurate geolocation information when available'
        | translate
    }}.
  </p>
  <mat-slide-toggle
    [checked]="accurateLocation"
    (change)="onChangeAccurateLocation.emit($event.checked)"
  >
  </mat-slide-toggle>
</div>

<div class="toggle-btn flex-wrp" *ngIf="!isLocatingDisabled && siteSettings">
  <p>{{ 'Always obtain the peer details when available' | translate }}.</p>
  <mat-slide-toggle
    [checked]="siteSettings.extractPeerInformation"
    (change)="
      onChangeExtractPeerAction.emit({
        value: $event.checked,
        action: availableExtractPeerActions.getInfo
      })
    "
  >
  </mat-slide-toggle>
</div>

<div class="toggle-btn flex-wrp" *ngIf="!isLocatingDisabled && siteSettings">
  <p>
    {{
      'Always obtain the peer details and geolocate when available' | translate
    }}.
  </p>
  <mat-slide-toggle
    [checked]="siteSettings.extractPeerInformationAndLocate"
    (change)="
      onChangeExtractPeerAction.emit({
        value: $event.checked,
        action: availableExtractPeerActions.getInfoAndLocate
      })
    "
  >
  </mat-slide-toggle>
</div>
<div
  class="toggle-btn flex-wrp"
  *ngIf="siteSettings"
  (click)="$event.stopPropagation()"
>
  <p>
    {{ 'Change language' | translate }}
  </p>
  <mat-form-field
    class="language-selector"
    appearance="outline"
    *ngIf="siteSettingsStore | async as siteSettings"
  >
    <mat-select
      class="select-language"
      [value]="language"
      (selectionChange)="onLanguageChange.emit($event)"
      [ngClass]="language"
    >
      <mat-option
        *ngFor="let language of siteSettings.languages"
        [value]="language.value"
      >
        <img with="18" height="18" [src]="language?.image" />
        <span>{{ language.display }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
