import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-header-content-footer-dialog',
  templateUrl: './header-content-footer-dialog.component.html',
  styleUrls: ['./header-content-footer-dialog.component.scss'],
})
export class HeaderContentFooterDialogComponent implements OnInit {
  @Input() title: string;
  @Input() showClose: boolean;
  @Input() showFooter: boolean;
  constructor(
    public dialogRef: MatDialogRef<HeaderContentFooterDialogComponent>
  ) {}

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }
}
