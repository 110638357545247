import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DurationFormatPipe } from 'src/app/pipes/duration-format.pipe';
import { ReverseGeoPipe } from './reverse-geo.pipe';
import { FileSizePipe } from './file-size.pipe';
import { FilterByPropertyPipe } from './filter-by-property.pipe';
import { FilterPipe } from './filter.pipe';
import { HumanizeTitleCasePipe } from './humanize-titlecase.pipe';
import { HumanizePipe } from './humanize.pipe';
import { NotAvailablePipe } from './not-available.pipe';
import { StringColorPipe } from './string-color.pipe';
import { UrlTruncatePipe } from './url-truncate.pipe';
import { ValidatorPipe } from './validator.pipe';
import { WarnPipe } from './warn.pipe';
import { ProfileIdExtractorPipe } from '@shared/pipes/profile-id-extractor.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    NotAvailablePipe,
    HumanizePipe,
    StringColorPipe,
    FileSizePipe,
    DurationFormatPipe,
    UrlTruncatePipe,
    HumanizeTitleCasePipe,
    FilterByPropertyPipe,
    ValidatorPipe,
    FilterPipe,
    ReverseGeoPipe,
    WarnPipe,
    ProfileIdExtractorPipe,
  ],
  exports: [
    NotAvailablePipe,
    HumanizePipe,
    StringColorPipe,
    FileSizePipe,
    DurationFormatPipe,
    UrlTruncatePipe,
    HumanizeTitleCasePipe,
    FilterByPropertyPipe,
    ValidatorPipe,
    FilterPipe,
    ReverseGeoPipe,
    WarnPipe,
    ProfileIdExtractorPipe,
  ],
})
export class PipesModule {}
