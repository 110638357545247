import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { head } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { ClParameterType } from 'src/app/shared/models/call-log-request.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { isValidIMSI, isValidMSISDN } from 'src/app/shared/util/helper';
import { MCCtoCountryCodeMapper } from '../models/mcc-to-country-code-mapper';
import { RequestClParameters } from '../models/request-cl-parameters.model';
import {
  CallLogsListResponseModel,
  CLPaginatedResponse,
} from '../models/targets-list-request.model';
import { AnalysisUtilService } from './analysis-util.service';
import { AnalysisService } from './analysis.service';
@Injectable({
  providedIn: 'root',
})
export class RequestClValidatorService extends BaseService {
  callLogsListData: CallLogsListResponseModel[];
  pendingRequest: { [key: string]: boolean } = {};
  private phoneNumberUtil = PhoneNumberUtil.getInstance();
  private callLogsEnabledCountries: string[];
  enableIMSICallLogs: boolean;
  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    public dialog: MatDialog,
    private analysisService: AnalysisService,
    private localStorageService: LocalStorageService,
    private analysisUtilService: AnalysisUtilService,
    private appConfigService: AppConfigService
  ) {
    super(router, snackBar);
    this.callLogsEnabledCountries = this.appConfigService.getConfigVariable(
      'callLogsEnabledCountries'
    );
    this.enableIMSICallLogs =
      this.appConfigService.getConfigVariable('enableIMSICallLogs');
  }

  prepareRequestForCallLogs(
    requestClParamters: RequestClParameters,
    statuses: string[] = []
  ): any {
    const currentUser = this.localStorageService.getCurrentUser();
    this.pendingRequest = {};
    let msisdns: string[] = [];
    let imsis: string[] = [];
    let imeis: string[] = [];

    if (requestClParamters.msisdns?.length) {
      msisdns = requestClParamters.msisdns;
      msisdns.forEach((telno) => (this.pendingRequest[telno] = false));
    }
    if (requestClParamters.imsis?.length) {
      imsis = requestClParamters.imsis;
      imsis.forEach((imsi) => (this.pendingRequest[imsi] = false));
    }
    if (requestClParamters.imeis?.length) {
      imeis = requestClParamters.imeis;
      imeis.forEach((imei) => (this.pendingRequest[imei] = false));
    }

    const requestbody = this.analysisUtilService.buildClRequestPayload({
      pageSize: '1000',
      pageNumber: '1',
      msisdns,
      imsis,
      imeis,
      statuses,
      createdBy: [currentUser.identity],
    });
    return requestbody;
  }

  getCallLogsList(requestClParamters: RequestClParameters) {
    const requestbody = this.prepareRequestForCallLogs(requestClParamters);
    return this.analysisService.getCallLogsList(requestbody).pipe(
      map((callLogs) => {
        const callLogsData: CLPaginatedResponse = head(callLogs);
        this.callLogsListData = callLogsData?.paginatedResults;
        this.updatePendingClRequests(
          requestClParamters.msisdns,
          ClParameterType.MSISDN
        );
        this.updatePendingClRequests(
          requestClParamters.imsis,
          ClParameterType.IMSI
        );
        this.updatePendingClRequests(
          requestClParamters.imeis,
          ClParameterType.IMEI
        );
        return this.pendingRequest;
      })
    );
  }

  updatePendingClRequests(requestClValues: string[], key: string) {
    if (!requestClValues) {
      return;
    }

    requestClValues.forEach((value) => {
      const hasPendingReq =
        this.callLogsListData.filter(
          (callLog) => callLog[key] === value && callLog.status === 'pending'
        ).length > 0;
      this.pendingRequest[value] = hasPendingReq;
    });
  }

  getCallLogsStatusForMsisdn(
    msisdn: string
  ): Observable<CallLogsListResponseModel> {
    const requestbody = this.prepareRequestForCallLogs({ msisdns: [msisdn] });
    return this.analysisService.getCallLogsList(requestbody).pipe(
      map((callLogs) => {
        const callLogsData: CLPaginatedResponse = head(callLogs);
        const callLogsListData = callLogsData?.paginatedResults;
        const completedReq: CallLogsListResponseModel[] =
          callLogsListData.filter(
            (callLog) =>
              callLog.msisdns.includes(msisdn) && callLog.status === 'completed'
          );
        return head(completedReq);
      })
    );
  }

  prepareRequestForPendingCl(requestClParamters: RequestClParameters): any {
    const currentUser = this.localStorageService.getCurrentUser();
    this.pendingRequest = {};
    let msisdns: string[] = [];
    let imsis: string[] = [];
    let imeis: string[] = [];

    if (requestClParamters.msisdns?.length) {
      msisdns = requestClParamters.msisdns;
    }
    if (requestClParamters.imsis?.length) {
      imsis = requestClParamters.imsis;
    }
    if (requestClParamters.imeis?.length) {
      imeis = requestClParamters.imeis;
    }

    const requestbody = this.analysisUtilService.buildPendingClRequestPayload({
      msisdns,
      imsis,
      imeis,
      statuses: ['pending'],
      createdBy: [currentUser.identity],
    });
    return requestbody;
  }

  checkForPendingClRequests(
    requestClParamters: RequestClParameters
  ): Observable<number> {
    const requestbody = this.prepareRequestForPendingCl(requestClParamters);
    return this.analysisService.getPendingCallLogsList(requestbody);
  }

  public isMsisdnAllowedForRequestCallLogs(msisdn: string): boolean {
    if (!isValidMSISDN(msisdn)) {
      return false;
    }
    const countryCode = this.phoneNumberUtil
      .parse(msisdn, '')
      .getCountryCode()
      .toString();
    return this.callLogsEnabledCountries
      ? this.callLogsEnabledCountries.includes(countryCode)
      : true;
  }

  public isIMSIAllowedForRequestCallLogs(imsi: string): boolean {
    if (!isValidIMSI(imsi)) {
      return false;
    }
    const MCC = imsi.substring(0, 3);
    return this.callLogsEnabledCountries
      ? this.callLogsEnabledCountries.includes(MCCtoCountryCodeMapper[MCC])
      : true;
  }

  public isRequestCallLogsAvailableForTarget(target: TargetItem): boolean {
    if (!target) {
      return false;
    }
    const clTelnos = target.telnos.filter((msisdn) =>
      this.isMsisdnAllowedForRequestCallLogs(msisdn)
    );
    const clImsis = target.imsis.filter((imsi) =>
      this.isIMSIAllowedForRequestCallLogs(imsi)
    );
    return !!(
      clTelnos.length ||
      (clImsis.length && this.enableIMSICallLogs) ||
      target.imeis.length
    );
  }

  public prepareRequestClFromCallLog(
    callLog: CallLogsListResponseModel
  ): RequestClParameters {
    return {
      msisdns: callLog.msisdns?.filter((msisdn) => Boolean) || [],
      imsis: callLog.imsis?.filter((imsi) => Boolean) || [],
      imeis: callLog.imeis?.filter((imei) => Boolean) || [],
    };
  }

  public prepareRequestClFromTarget(target: TargetItem): RequestClParameters {
    return {
      msisdns: target.telnos?.filter((msisdn) => Boolean) || [],
      imsis: target.imsis?.filter((imsi) => Boolean) || [],
      imeis: target.imeis?.filter((imei) => Boolean) || [],
    };
  }
}
