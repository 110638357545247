import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { GeoJsonFeatureCollection } from '@trg-commons/data-models-ts';
import { PhoneNumber, PhoneNumberUtil } from 'google-libphonenumber';
import { camelCase } from 'lodash-es';
import { Profile } from 'datalayer/models/social/profile';
import first from 'lodash-es/first';
import { Platforms } from '../models/radical-monitoring-options.model';
import _ from 'lodash';

export function transformCamelCaseToHumanized(key: string): string {
  const text = key.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
  return humanize(text);
}

export function transformSnakeToCamel(obj: any): any {
  if (!obj) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((value) => {
      if (value instanceof Object) {
        return transformSnakeToCamel(value);
      }
      return value;
    });
  }

  for (const [key, value] of Object.entries(obj)) {
    if (value instanceof Object) {
      obj[key] = transformSnakeToCamel(value);
    }

    const old = obj[key];
    delete obj[key];
    obj[camelCase(key)] = old;
  }

  return obj;
}

export function normalizeToArray<T>(object: T | T[]): T[] {
  if (!Array.isArray(object)) {
    return object ? [object] : [];
  }

  return object;
}

export function enumToArray<T>(enumme: Record<string, T>): T[] {
  return Object.keys(enumme).map((key) => enumme[key]);
}

export function stringToColor(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

export function getPlatformFromURL(link: string) {
  let platform;
  if (link.includes(Platforms.FACEBOOK)) {
    platform = Platforms.FACEBOOK;
  }
  return platform;
}

export function getProperty<T extends Record<string, any>>(
  propertyName: string,
  object: T
) {
  try {
    const parts = propertyName.split('.');
    const length = parts.length;
    let property: Object = object ?? this;
    for (let i = 0; i < length; i++) {
      if (parts[i] in property) {
        property = property[parts[i]];
      } else {
        throw new Error(`property ${parts[i]} does not exist in ${property}`);
      }
    }

    return property;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to get property for object');
  }
}

export function getObjectValues(obj: unknown): any[] {
  return obj && typeof obj === 'object'
    ? Object.values(obj)
        .map(getObjectValues)
        .reduce((a, b) => a.concat(b), [])
    : [obj];
}

export function humanize(value: string): string {
  if (typeof value !== 'string') {
    return value;
  }

  value = value.split(/(?=[A-Z])/).join(' ');
  value = value[0].toUpperCase() + value.slice(1);
  return value;
}

export const randomString = () => {
  return Math.random().toString(36).slice(2);
};

export const isMasked = (
  value: string,
  targetCharacters: string[] = ['*'],
  minConsecutiveCharacters: number = 2
): boolean => {
  for (const targetCharacter of targetCharacters) {
    if (
      value &&
      value.indexOf(''.padEnd(minConsecutiveCharacters, targetCharacter)) !== -1
    ) {
      return true;
    }
  }
  return false;
};

export const extractCoordsFromGeoJsonFeatureCollection = (
  geojson: GeoJsonFeatureCollection
): [lng: number, lat: number] | undefined => {
  const point = first(geojson?.features);
  if (point?.geometry?.coordinates)
    return [point.geometry.coordinates[0], point.geometry.coordinates[1]];
};

export const sortRelations = (relations: Profile[]) => {
  return relations.sort((a, b) =>
    (a.profileToTargetInfo?.alias || a.name)
      .toLowerCase()
      .localeCompare((b.profileToTargetInfo?.alias || b.name).toLowerCase())
  );
};

export const isNumber = (n: any) => {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
};

export const removePrefixUrl = (url: string) => {
  return url.replace('https://', '').replace('http://', '').replace('www.', '');
};

export const addPrefixUrl = (url: string) => {
  return `https://www.${url}`;
};

export const removeIllegalSignsFromUrl = (url: string) => {
  return url.replace(/^[^\w]+|[^\w]+$/gm, '');
};

export const checkUrlForSpecificSubsequence = (url: string) => {
  return ['https', 'http', 'www.', '.com'].some((subsequence) =>
    url.includes(subsequence)
  );
};

export const triggerDownload = (href: string, fileName: string) => {
  const elem = document.createElement('a');
  elem.href = href;

  elem.download = fileName;
  // append to the dom otherwise it will not work on firefox
  document.body.appendChild(elem);
  elem.click();
  elem.remove();
};

export const camelToSnakeCase = (key: string) =>
  key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export function filterOutEnumNumberKeys<
  O extends object,
  K extends keyof O = keyof O
>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

export function transformCamelToSnakeRecursive(obj: any): any {
  if (!obj) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((value) => {
      if (value instanceof Object) {
        return transformCamelToSnakeRecursive(value);
      }
      return value;
    });
  }

  for (const [key, value] of Object.entries(obj)) {
    if (value instanceof Object) {
      obj[key] = transformCamelToSnakeRecursive(value);
    }

    const old = obj[key];
    delete obj[key];
    obj[camelToSnakeCase(key)] = old;
  }

  return obj;
}

function sumDigit(number: number): number {
  let a = 0;
  while (number > 0) {
    a = a + (number % 10);
    number = parseInt((number / 10).toString(), 10);
  }
  return a;
}

export function isValidImeiIgnoreChecksum(imei: number): boolean {
  if (!imei) return false;
  const validationRegex = new RegExp(`^\\d{15}$`);
  return imei.toString().match(validationRegex) !== null;
}

export function isValidIMEIValidateChecksum(imei: number): boolean {
  if (!isValidImeiIgnoreChecksum(imei)) {
    return false;
  }
  let sum = 0;
  for (let i = imei.toString().length; i >= 1; i--) {
    let d = imei % 10;
    if (i % 2 == 0) d = 2 * d;
    sum += sumDigit(d);
    imei = parseInt((imei / 10).toString(), 10);
  }
  return sum % 10 === 0;
}

export function calculateValidImei(invalidImei: string): string | undefined {
  let sub = invalidImei.slice(0, -1);
  for (let i = 0; i < 10; i++) {
    const newImei = parseInt(sub + i);
    if (isValidIMEIValidateChecksum(newImei)) {
      return newImei.toString();
    }
  }
  return undefined;
}

export const ARGENTINA_COUNTRY_CODE = 'AR';

export function isValidMSISDN(msisdn: string): boolean {
  if (!msisdn) {
    return false;
  }
  const backendFakeNumberRegex = new RegExp(/99991111[0-3]{1}[0-9]{1}$/i);
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  let phoneNumber: PhoneNumber;
  try {
    phoneNumber = phoneNumberUtil.parse(msisdn);
  } catch (e) {
    return false;
  }
  const countryCode = phoneNumberUtil.getRegionCodeForCountryCode(
    phoneNumber.getCountryCode()
  );
  if (countryCode === ARGENTINA_COUNTRY_CODE) {
    return isValidArgentinaMsisdn(msisdn);
  }

  const validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
  return !!(validNumber || backendFakeNumberRegex.test(msisdn));
}

export function isValidArgentinaMsisdn(phoneNumber: string) {
  return /^\+549\d{2}\d{4}\d{4}$/.test(phoneNumber);
}

export function isValidIMSI(imsi: string): boolean {
  if (!imsi) {
    return false;
  }
  const imsiRegex = new RegExp(`^\\d{3}\\d{12}$`);
  const parseResult = imsiRegex.exec(imsi);
  return !(parseResult === null);
}

export function validateFormControlBy(
  validatorFunction: Function | RegExp
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (validatorFunction instanceof RegExp) {
      return validatorFunction.exec(control.value)
        ? null
        : { validate: { value: control.value } };
    }
    return validatorFunction(control.value)
      ? null
      : { validate: { value: control.value } };
  };
}

export function applyClassMixins(derivedCtor: any, constructors: any[]) {
  constructors.forEach((baseCtor) => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name) ||
          Object.create(null)
      );
    });
  });
}

export function mapByFieldList<T>(obj: T, list: string[]): Record<string, any> {
  return Object.keys(obj)
    .filter((key: string): boolean => list.includes(key))
    .reduce((acc: Record<string, any>, key: string): Record<string, any> => {
      return {
        ...acc,
        [key]: _.cloneDeep(obj[key]),
      };
    }, {});
}
