import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Chat } from 'datalayer/models/chat';
import { Group } from 'datalayer/models/platform-models';
import { ChatHelper } from '../../facebook-result/chat.helper';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss'],
  providers: [ChatHelper],
})
export class GroupDetailsComponent implements OnInit {
  @Input() groupChats: Chat[];
  @Input() hideBackButton = false;
  @Output() goBackEvent = new EventEmitter();
  communityInformationGroup: Group[] = [];

  constructor(private chatHelper: ChatHelper) {}

  ngOnInit(): void {
    for (let chat of this.groupChats) {
      const communityInformationChat = this.chatHelper.getSMGroup(chat, '');
      this.communityInformationGroup.push(communityInformationChat);
    }
  }

  goBack() {
    this.goBackEvent.emit();
  }
}
