import { ModelStore } from 'src/app/modules/data-layer/services/base/model-store';
import { Entity } from 'datalayer/models/platform-models';
import { RequestOptions } from 'src/app/modules/data-layer/services/base/request-options.interface';
import { Observable } from 'rxjs';

export interface CacheService<TYPE_MODEL extends Entity> {
  get(guid: string): TYPE_MODEL;
  getAll(requestOptions: RequestOptions): ModelStore<TYPE_MODEL>;
  put(entries: TYPE_MODEL[]): Observable<TYPE_MODEL[]>;
  delete(models: TYPE_MODEL[]): void;
  deleteAll(): void;
  getStorageComplete(): boolean;
  setStorageComplete(state: boolean): void;
}
