import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/base/base.component';
import { TacticalService } from 'src/app/services/tactical/tactical.service';
import { ImsiCatcherItem } from 'src/app/shared/models/imsi-catcher-item.model';
import { TargetFormComponent } from 'src/app/modules/profiler/components/target-form/target-form.component';
import { AppConfigService } from 'src/app/providers/app-config.service';

@Component({
  selector: 'app-ic-log-table-item',
  templateUrl: './ic-log-table-item.component.html',
  styleUrls: ['./ic-log-table-item.component.scss'],
})
export class IcLogTableItemComponent extends BaseComponent implements OnInit {
  selectedImsiCatcher;
  isGeolocTheme: Boolean = false;
  isWhiteTheme: Boolean = false;
  @Input() imsiCatcher;
  @Input() even: boolean;
  @Input() checked: boolean;
  theme: string;
  multipins = [];

  @Output() emittedImsiCatcher = new EventEmitter<ImsiCatcherItem>();
  @Output() emittedMultiselectImsiCatcher = new EventEmitter<{
    imsiCatcher: ImsiCatcherItem;
    checked: boolean;
  }>();

  constructor(
    private tacticalService: TacticalService,
    public dialog: MatDialog,
    private appConfigService: AppConfigService
  ) {
    super();
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit() {
    if (this.theme === 'GEOLOC') {
      this.isGeolocTheme = true;
    }

    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }

    const imsiCatcherSubscription =
      this.tacticalService.selectedImsiCatcher.subscribe((imsiCatcher) => {
        this.selectedImsiCatcher = imsiCatcher;
      });

    this.subscriptions.push(imsiCatcherSubscription);
  }

  selectImsiCatcher(imsiCatcher) {
    if (imsiCatcher.location) {
      this.emittedImsiCatcher.emit(imsiCatcher);
    }
    this.tacticalService.selectedImsiCatcher.next(imsiCatcher);
  }

  addTargetDialog(imsiCatcher): void {
    const imsi = imsiCatcher.imsi ? [imsiCatcher.imsi] : [];

    const target = {
      names: [],
      telnos: [],
      imsis: imsi,
      tmsis: [],
      imeis: [],
      assignedUsers: [],
      assignedGroups: [],
    };
    this.dialog.open(TargetFormComponent, {
      width: '30vw',
      height: 'auto',
      data: target,
      panelClass: 'create-target-dialog',
    });
  }

  multiselect(event, imsiCatcher) {
    this.tacticalService.selectedImsiCatcher.next(null);
    if (imsiCatcher.location) {
      this.emittedMultiselectImsiCatcher.emit({
        imsiCatcher,
        checked: event.checked,
      });
    }
  }
}
