<app-core-toastr [class.open]="!isHidden">
  <app-core-toastr-header (close)="removeGroup()">
    <div class="target-toastr__title font-medium flex-wrp">
      <span>{{ 'text analysis' | translate }}</span>
    </div>
    <mat-icon class="arrow" (click)="toggleHidden()">play_arrow</mat-icon>
  </app-core-toastr-header>
  <app-core-toastr-body [class.hidden]="isHidden">
    <div class="target-toastr__toasts">
      <ng-container [ngSwitch]="state">
        <ng-container *ngSwitchCase="ftaNotificationState.PENDING">
          <div class="target-toastr__toast-item flex-wrp">
            <div class="target-toastr__source">
              {{ notificationText }}
            </div>
            <div class="progress-loader">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="ftaNotificationState.FAILED">
          <div class="target-toastr__toast-item flex-wrp">
            <div class="target-toastr__source">
              {{ notificationText }}
            </div>
            <div>
              <span>{{ 'Failed' | translate }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="ftaNotificationState.DONE">
          <div class="target-toastr__toast-item flex-wrp">
            <div class="target-toastr__source">
              {{ notificationText }}
            </div>
            <div>
              <a
                class="clickable update-graph-btn"
                (click)="onClickUpdateGraph()"
                >{{ 'Update the graph' | translate }}</a
              >
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </app-core-toastr-body>
</app-core-toastr>
