import { environment } from 'environment/environment';
import { omit } from 'lodash-es';
import { DEFAULT_COUNTRIES } from 'src/app/components/settings/shared/default-countries';

export enum UserRole {
  USERS = 'Users',
  POWER_USERS = 'Power users',
}

export enum LanguageOptions {
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  ID = 'id',
}

export enum LanguageDisplayOptions {
  EN = 'English',
  ES = 'Español',
  FR = 'French',
  ID = 'Indonesian',
}

export const LanguageFlagsMap: { [key in LanguageOptions]: string } = {
  [LanguageOptions.EN]: 'assets/static/images/flag_us.svg',
  [LanguageOptions.ES]: 'assets/static/images/flag_mx.svg',
  [LanguageOptions.FR]: 'assets/static/images/flag_fr.png',
  [LanguageOptions.ID]: 'assets/static/images/flag_id.png',
};

interface Country {
  value: string;
  lat?: number;
  lon?: number;
  checked: boolean;
}

export interface SupportRequest {
  checked: boolean;
  value: UserRole;
}

export interface Language {
  checked: boolean;
  value: LanguageOptions;
  display: LanguageDisplayOptions;
  image?: string;
}

export interface SiteSettings {
  supportRequest: SupportRequest[];
  countries: Country[];
  extractPeerInformationAndLocate?: boolean;
  extractPeerInformation?: boolean;
  languages: Language[];
}

export const DEFAULT_SITE_SETTINGS: SiteSettings = {
  supportRequest: [
    {
      checked: true,
      value: UserRole.USERS,
    },
    {
      checked: true,
      value: UserRole.POWER_USERS,
    },
  ],
  countries: DEFAULT_COUNTRIES.map(
    (country) =>
      <Country>{
        ...omit(country, 'viewValue'),
        checked: country.value === 'none',
      }
  ),
  extractPeerInformationAndLocate: false,
  extractPeerInformation: false,
  languages: [
    {
      checked: environment['defaultLanguage'] === LanguageOptions.EN,
      value: LanguageOptions.EN,
      display: LanguageDisplayOptions.EN,
    },
    {
      checked: environment['defaultLanguage'] === LanguageOptions.ES,
      value: LanguageOptions.ES,
      display: LanguageDisplayOptions.ES,
    },
    {
      checked: environment['defaultLanguage'] === LanguageOptions.FR,
      value: LanguageOptions.FR,
      display: LanguageDisplayOptions.FR,
    },
    {
      checked: environment['defaultLanguage'] === LanguageOptions.ID,
      value: LanguageOptions.ID,
      display: LanguageDisplayOptions.ID,
    },
  ],
};
