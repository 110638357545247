import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CdrUploadStats, EventChannel } from '@trg-commons/gio-data-models-ts';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Action } from 'src/app/shared/classes/action.class';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { ClParameterType } from 'src/app/shared/models/call-log-request.model';
import { CallLogsApiService } from 'src/app/shared/modules/call-logs-shared/services/call-logs-api.service';
import { CallLogUploadRequest } from '../../modules/call-logs-shared/models/call-log-upload-request';
import { ClRequestType } from '../new-request-cl-dialog/request-cl-dialog.model';
import { ClSchemaService } from 'src/app/shared/modules/call-logs-shared/services/cl-schema.service';
import { Observable } from 'rxjs';
import { ClSchema } from '@shared/modules/call-logs-shared/models/call-log-schema.model';
import { FormControl, FormGroup } from '@angular/forms';
import { AppConfigService } from '@app/config';

interface CallLogUploadResultsNotificationEvent {
  channel: EventChannel.CdrImport;
  body: CallLogUploadResults;
}

interface CallLogUploadResults {
  parsingErrors: number;
  successfullyInserted: number;
  duplicates: number;
  totalParsed: number;
}

@Component({
  selector: 'app-cl-upload',
  templateUrl: './cl-upload.component.html',
  styleUrls: ['./cl-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClUploadComponent
  extends BaseComponent
  implements OnChanges, OnInit
{
  public selectedFile: File;
  public validFile: File;
  public clUploadResults: CallLogUploadResults | CdrUploadStats;
  public linesParsed: number;
  enableCDRUploadBySchema = false;
  clSchemas$: Observable<ClSchema[]>;
  schemaSelector = new FormGroup({
    schema: new FormControl(''),
  });
  @Output() uploadFinished: EventEmitter<Action> = new EventEmitter();
  @Input() targetId: string;
  @Input() requestId: string;
  @Input() msisdn: string;
  @Input() selectedParameter: { [key in ClParameterType]?: string };
  public fileValidatorMessage: string;
  constructor(
    private callLogsApiService: CallLogsApiService,
    private cdRef: ChangeDetectorRef,
    private translationService: TranslationService,
    private clSchemaService: ClSchemaService,
    private appConfigService: AppConfigService
  ) {
    super();
  }

  ngOnInit(): void {
    this.enableCDRUploadBySchema = this.appConfigService.getConfigVariable(
      'enableCDRUploadBySchema'
    );
    if (this.enableCDRUploadBySchema) {
      this.clSchemas$ = this.clSchemaService.getClSchemas();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedParameter']?.currentValue && this.selectedFile) {
      this.onSelectedFile(this.selectedFile);
    }
  }

  onSelectedFile(file: File) {
    const [key, value] = Object.entries(this.selectedParameter)[0];
    if (file.name.split('.')[0] !== value) {
      this.fileValidatorMessage = this.translationService.interpolate(
        'File name must match #{type} number',
        {
          type: key,
        }
      );
      this.selectedFile = file;
      this.validFile = undefined;
      return;
    }
    this.validFile = file;
    this.fileValidatorMessage = undefined;
    this.cdRef.markForCheck();
  }

  uploadCallLog() {
    this.loading = true;
    this.callLogsApiService
      .uploadCallLogsFile(
        this.validFile,
        this.prepareCallLogUploadRequestParams()
      )
      .subscribe({
        next: (v) => this.handleUploadEvent(v),
        error: (e) => {
          this.loading = false;
          this.uploadFinished.next(
            new Action({ key: 'failed', data: { message: e.error?.message } })
          );
        },
      });
  }

  private handleUploadEvent(
    event: CallLogUploadResultsNotificationEvent | any
  ): void {
    switch (event.channel) {
      case EventChannel.CdrImport:
      case EventChannel.OperationRequestsStreamEnded: {
        this.loading = false;
        this.clUploadResults = event.body;
        break;
      }
      case EventChannel.OperationRequestsUpdates: {
        this.linesParsed = event.body.progress;
        break;
      }
    }
    this.cdRef.markForCheck();
  }

  private prepareCallLogUploadRequestParams(): CallLogUploadRequest {
    const [key, value] = Object.entries(this.selectedParameter)[0];
    const callLogRequestId = this.requestId;
    const targetId = this.targetId;
    const clSchema = this.schemaSelector.value['schema'];
    return {
      uploadType: (key as ClParameterType) || ClParameterType.MSISDN,
      value: value || this.msisdn,
      ...(callLogRequestId && { callLogRequestId }),
      ...(targetId && { targetId }),
      ...{ schemaId: clSchema },
    };
  }

  public close(): void {
    this.uploadFinished.emit({
      key: 'success',
      data: { actionType: ClRequestType.FULFILL_CALL_LOG },
    });
  }

  public hideResults(): void {
    this.clUploadResults = undefined;
    this.selectedFile = undefined;
    this.validFile = undefined;
  }
}
