import { Component, Input, OnInit } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { SearchLabels } from 'src/app/modules/search-intel/models/search-intel.model';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { SearchIntelNavbarNumberValidatorService } from '../search-intel-navbar-number-validator.service';

@Component({
  selector: 'app-input-type-content',
  templateUrl: './input-type-content.component.html',
  styleUrls: ['./input-type-content.component.scss'],
  providers: [SearchIntelNavbarNumberValidatorService],
})
export class InputTypeContentComponent implements OnInit {
  infoMessage = '';
  phoneNumberUtil = PhoneNumberUtil.getInstance();
  inputTypeValueLabel: { [key: string]: { value: string; label: string } } =
    this.numberValidatorService.inputTypeValueLabel;
  searchLabels = SearchLabels;

  @Input()
  inputType: { key: string; value: string };

  constructor(
    private translationService: TranslationService,
    private numberValidatorService: SearchIntelNavbarNumberValidatorService
  ) {}

  ngOnInit(): void {
    if (
      this.inputTypeValueLabel[this.inputType.key].value ===
      this.searchLabels.POSSIBLE_TELNO
    ) {
      this.infoMessage = this.translationService.interpolate(
        'To search for a phone number, enter the complete number, including country code. Example: #{example}',
        {
          example: this.numberValidatorService.getRandomPhoneNumberForTenant(
            this.inputType.value
          ),
        }
      );
    }
  }
}
