import { OTPConfiguration } from './../../shared/models/login.model';
import { BaseComponent } from 'src/app/base/base.component';
import {
  Component,
  OnInit,
  Input,
  Optional,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { AuthDataService } from 'src/app/services/authentication/auth-data.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user/user.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { TwoStepVerificationDialogComponent } from './two-step-verification-dialog/two-step-verification-dialog.component';
@Component({
  selector: 'app-configure-two-step-verification',
  templateUrl: './configure-two-step-verification.component.html',
  styleUrls: ['./configure-two-step-verification.component.scss'],
})
export class ConfigureTwoStepVerificationComponent
  extends BaseComponent
  implements OnInit
{
  @Input() showQRScan: boolean = false;
  @Input() currentPassword: string;
  @Output() onTwoStepVerificationSetup = new EventEmitter<boolean>();

  visibility = false;
  QRscan = false;
  passwordInput = new FormControl();
  otpInput = new FormControl();
  currentUserPassword = new FormControl();
  QRImage: SafeUrl;
  errorMsg: string;
  pwdError: boolean;
  isOTPEnabled: boolean;

  constructor(
    public dialog: MatDialog,
    private authService: AuthDataService,
    private sanitizer: DomSanitizer,
    protected userService: UserService,
    private translationService: TranslationService,
    @Optional()
    public dialogRef: MatDialogRef<TwoStepVerificationDialogComponent>
  ) {
    super();
  }

  ngOnInit() {
    if (this.showQRScan) {
      this.QRscan = true;
    }
    this.userService
      .getCurrentUser()
      .subscribe((user) => (this.isOTPEnabled = user.otpEnabled));
    this.generateQRCode();
    this.passwordInput.setValue(this.currentPassword);
  }

  activateTwoStepVerification() {
    const activateOTP: OTPConfiguration = {
      enabled: true,
      password: this.passwordInput.value,
      otp_code: this.otpInput.value,
    };

    this.setOTPVerification(activateOTP);
  }

  deactivateTwoStepVerification() {
    const deactivateOTP: OTPConfiguration = {
      enabled: false,
      password: this.passwordInput.value,
    };

    this.setOTPVerification(deactivateOTP);
  }

  setOTPVerification(OTPStatus: OTPConfiguration) {
    this.authService.setUserOTPStatus(OTPStatus).subscribe(
      (status) => {
        if (this.dialogRef) {
          this.dialogRef.close(status);
        } else {
          this.onTwoStepVerificationSetup.emit(status);
        }
        this.showMessage(
          this.translationService.translate('OTP configuration completed')
        );
      },
      (error) => {
        this.errorMsg = error.message;
        if (this.errorMsg === 'Wrong password') {
          this.QRscan = false;
          this.pwdError = true;
        } else {
          this.pwdError = false;
        }
      }
    );
  }

  generateQRCode() {
    this.authService.GenerateOTPAuthenticatorQRCode().subscribe((imageURL) => {
      this.QRImage = this.sanitizer.bypassSecurityTrustUrl(imageURL);
    });
  }
}
