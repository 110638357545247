import { TextAnalysisFile } from 'src/app/modules/analysis/shared/models/case-investigations.model';

export enum UploaderState {
  FILE = 'file',
  URL = 'url',
  UPLOADING = 'uploading',
  FAILED = 'failed',
}

export enum FileState {
  UPLOADING = 'uploading',
  SUCCESS = 'success',
  FAILED = 'failed',
  NOT_ALLOWED = 'not allowed',
}

export interface FileEntry {
  file: File;
  state: FileState;
  progress: number;
  fileId: string;
  filePath: string;
}

export enum NodeIconState {
  INITIAL = 'ready to upload file',
  UPLOADING_IN_PROGRESS = 'uploading in progress',
  ONE_FAILED = 'at least one file failed to upload',
  URL_UPLOAD = 'url uploading state',
  DONE = 'finished uploading',
  STOP = 'at least one file stopped uploading',
}

export const nodeIconStatesMap = {
  [UploaderState.URL]: NodeIconState.URL_UPLOAD,
  [UploaderState.FILE]: NodeIconState.INITIAL,
  [UploaderState.UPLOADING]: NodeIconState.UPLOADING_IN_PROGRESS,
};

export const UploaderBackgroundClasses = {
  [UploaderState.FILE]: 'grey-bg',
  [UploaderState.URL]: 'grey-bg',
  [UploaderState.UPLOADING]: 'white-bg',
};

export interface GetAllFilesResponse {
  id: string;
  fileName: string;
  filePath: string;
  fileSize: number;
  uploadedAt: Date;
}

export enum FtaNotificationState {
  PENDING = 'InProgress',
  FAILED = 'Failed',
  DONE = 'Done',
}

export interface FileOperationsData {
  loading: boolean;
  removeFileId?: string;
  fileProperties?: TextAnalysisFile;
}

export interface JobStatusResponse {
  id: string;
  jobType: string;
  correlationId: string;
  status: FtaNotificationState;
  body: {
    filepath: string;
    fileId?: string;
    metadata: {
      platformContext: {
        investigationId: string;
      };
    };
  };
  tenantKey: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface RawFileResponse {
  base64Data: string;
  filePath: string;
  mimeType: string;
}
