import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { Themes } from '../shared/models/skins.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private base = 'Intellectus - ';

  private title$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  constructor(
    private router: Router,
    private appConfigService: AppConfigService,
    private ngTitleService: Title
  ) {}

  public get title(): Observable<string> {
    return this.title$.asObservable();
  }

  public startListening() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() =>
          this.getTitle(this.router.routerState, this.router.routerState.root)
        )
      )
      .subscribe((title) => {
        this.title$.next(title);
        this.ngTitleService.setTitle(this.getFullTitle(title));
      });
  }

  private getTitle(
    state,
    parent: ActivatedRoute,
    title: string = null
  ): string {
    const theme: Themes = this.appConfigService.getConfigVariable('theme');

    if (title && !parent) {
      return title;
    }

    if (parent?.snapshot.data.title) {
      title = parent.snapshot.data.title;
    }

    if (state && parent) {
      title = this.getTitle(state, state.firstChild(parent), title);
    }

    if (title === 'GIO+' && theme === Themes.WHITE) {
      title = 'Loc+';
    }

    return title;
  }

  private getFullTitle(title): string {
    let titlePage: string = '';
    switch (this.appConfigService.getConfigVariable('theme') as Themes) {
      case Themes.GEOLOC:
        titlePage = 'Geoloc - ' + title;
        break;
      case Themes.WHITE:
        titlePage = title;
        break;
      case Themes.GENERSHIELD:
        titlePage = `GenerShield - ${title}`;
        break;
      default:
        titlePage = this.base + title;
        break;
    }
    return titlePage;
  }
}
