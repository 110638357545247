import { Platform } from '@shared/schemas/common/platforms';
import { Media } from 'datalayer/models/media/media';
import {
  EntityRelationType,
  Profile as PlatformProfile,
} from 'datalayer/models/platform-models';
import { BaseTargetEntity } from 'datalayer/models/platform-models/base/base-target-entity';
import { RecoveryAccount } from 'src/app/shared/models/recovery-account.model';

export class Profile extends PlatformProfile implements BaseTargetEntity {
  targetId: string;
  platform: Platform;
  profileId?: string;
  relationType?: EntityRelationType;
  image?: Partial<Media>;
  userFriendlyUrl?: string;
  platformImage?: string;
  recoveryAccount?: RecoveryAccount;
  userIds?: string[];
  fromUserId?: string;
  dateAdded?: string;
  dateImported?: Date;

  constructor(model?: Partial<Profile>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }
}
