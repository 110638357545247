import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { LayoutService } from '../..';
import { ModalConfig } from '../type/modal-config.type';

@Component({
  selector: 'intellectus-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scrollableContent')
  public readonly scrollableContent!: ElementRef<HTMLDivElement>;

  @Input()
  public readonly config!: ModalConfig;

  @Output()
  public readonly modalClose: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public readonly breadcrumbClick: EventEmitter<number> = new EventEmitter<number>();

  private readonly bodyCssClass: string = 'modal-opened';
  public isMobile$ = this.layoutService.isMobile$;

  constructor(
    private readonly renderer2: Renderer2,
    private layoutService: LayoutService
  ) {}

  ngAfterViewInit(): void {
    this.renderer2.addClass(document.body, this.bodyCssClass);
  }

  ngOnDestroy(): void {
    this.closeDialog();
    this.renderer2.removeClass(document.body, this.bodyCssClass);
  }

  public close(): void {
    this.closeDialog();
  }

  private closeDialog(): void {
    this.modalClose.emit(true);
  }
}
