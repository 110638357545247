import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { TacticalService } from 'src/app/services/tactical/tactical.service';
import Swal from 'sweetalert2';
import { TargetFormComponent } from 'src/app/modules/profiler/components/target-form/target-form.component';
import { MatDialog } from '@angular/material/dialog';
import { LawEnforcementItem } from 'src/app/shared/models/law-enforcement-item.model';
import { AppConfigService } from 'src/app/providers/app-config.service';

@Component({
  selector: 'app-li-log-table-item',
  templateUrl: './li-log-table-item.component.html',
  styleUrls: ['./li-log-table-item.component.scss'],
})
export class LiLogTableItemComponent extends BaseComponent implements OnInit {
  selectedLawEnforcement;
  isGeolocTheme: Boolean = false;
  isWhiteTheme: Boolean = false;
  theme: string;
  @Input() lawEnforcement;
  @Input() even: boolean;
  @Input() checked: boolean;

  @Output() emittedLawEnforcement = new EventEmitter<LawEnforcementItem>();
  @Output() emittedMultiselectLawEnforcement = new EventEmitter<{
    lawEnforcement: LawEnforcementItem;
    checked: boolean;
  }>();

  constructor(
    private tacticalService: TacticalService,
    public dialog: MatDialog,
    private appConfigService: AppConfigService
  ) {
    super();
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit() {
    if (this.theme === 'GEOLOC') {
      this.isGeolocTheme = true;
    }

    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }

    const lawEnforcementSubscription =
      this.tacticalService.selectedLawEnforcement.subscribe(
        (lawEnforcement) => {
          this.selectedLawEnforcement = lawEnforcement;
        }
      );
    this.subscriptions.push(lawEnforcementSubscription);
  }

  selectLawEnforcement(lawEnforcement) {
    if (lawEnforcement.location) {
      this.emittedLawEnforcement.emit(lawEnforcement);
    }
    this.tacticalService.selectedLawEnforcement.next(lawEnforcement);
  }

  addTargetDialog(lawEnforcement): void {
    const msisdn = lawEnforcement.msisdn ? [lawEnforcement.msisdn] : [];
    const imsi = lawEnforcement.imsi ? [lawEnforcement.imsi] : [];
    const imei = lawEnforcement.imei ? [lawEnforcement.imei] : [];
    const target = {
      names: [],
      telnos: msisdn,
      imsis: imsi,
      tmsis: [],
      imeis: imei,
      assignedUsers: [],
      assignedGroups: [],
    };
    this.dialog.open(TargetFormComponent, {
      width: '30vw',
      height: 'auto',
      data: target,
      panelClass: 'create-target-dialog',
    });
  }

  multiselect(event, lawEnforcement) {
    this.tacticalService.selectedLawEnforcement.next(null);
    if (lawEnforcement.location) {
      this.emittedMultiselectLawEnforcement.emit({
        lawEnforcement,
        checked: event.checked,
      });
    }
  }

  fileReader(sms) {
    Swal.fire({
      title: 'SMS',
      text: `${sms}`,
      showCloseButton: true,
    });
  }

  audioPlayer(lawEnforcement) {
    Swal.fire({
      title: 'Audio',
      html: `<div>
              <audio controls>
                <source src="${lawEnforcement.file}" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>
            </div>
            <div>
              ${lawEnforcement.audioText}
            </div>`,
      showCloseButton: true,
    });
  }
}
