import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/base/base.component';
import { EmailService } from 'src/app/services/email/email.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent extends BaseComponent {
  contactForm: FormGroup;
  errorMsg: Boolean = false;
  uploadedFile;
  max_size_bytes = 10000000; // 10 MB

  @ViewChild('file', { static: true }) fileInput: ElementRef;

  severities = [
    { name: this.translationService.translate('Minor'), value: 'minor' },
    { name: this.translationService.translate('Major'), value: 'Major' },
    { name: this.translationService.translate('Critical'), value: 'Critical' },
  ];

  constructor(
    public dialogRef: MatDialogRef<ContactFormComponent>,
    private formBuilder: FormBuilder,
    private translationService: TranslationService,
    private localStorageService: LocalStorageService,
    private emailService: EmailService,
    private httpClient: HttpClient
  ) {
    super();
    this.contactForm = this.formBuilder.group({
      title: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
      severity: new FormControl('', Validators.required),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'),
      ]),
      file: [''],
    });
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      const currentUser = this.localStorageService.getCurrentUser();
      const emailData = new FormData();
      const content = {
        Name: this.contactForm.value.name,
        Username: currentUser.identity,
        Email: this.contactForm.value.email,
        Severity: this.contactForm.value.severity,
        Message: this.contactForm.value.message,
        'Tenant ID': '{{tenantid}}',
      };

      if (this.uploadedFile) {
        emailData.append('file', this.uploadedFile, this.uploadedFile.name);
      }

      emailData.append('title', this.contactForm.get('title').value);
      emailData.append('username', `${currentUser.identity}<br>`);
      emailData.append('message', 'sent a support request');

      this.gatherEnvInformation().subscribe((env) => {
        emailData.append(
          'content',
          this.getHumanContent({ ...content, ...env })
        );
        this.sendEmail(emailData);
        this.dialogRef.close();
      });
    } else {
      this.showMessage(
        this.translationService.translate(
          'Something went wrong. Please try again.'
        )
      );
      this.dialogRef.close();
    }
  }

  private isValidFile(): boolean {
    let isValid = true;

    if (this.uploadedFile.size > this.max_size_bytes) {
      isValid = false;
    }

    if (this.uploadedFile.type.split('/')[0] !== 'image') {
      isValid = false;
    }

    return isValid;
  }

  private sendEmail(emailData): void {
    this.emailService.supportRequest(emailData).subscribe(
      () => {
        this.showMessage(
          this.translationService.translate('Request sent successfully')
        );
      },
      () => {
        this.showMessage(
          this.translationService.translate('Request has not been sent')
        );
      }
    );
  }

  public fileUpload(event): void {
    this.errorMsg = false;

    if (event.target.files && event.target.files[0]) {
      this.uploadedFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.contactForm.get('file').setValue(this.uploadedFile);
      };
      reader.readAsDataURL(this.uploadedFile);
    }

    const isValidFile = this.isValidFile();
    if (!isValidFile) {
      this.errorMsg = true;
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private gatherEnvInformation(): Observable<{ [key: string]: string }> {
    const env = {
      'Type of OS': window.navigator.platform,
      Browser: window.navigator.userAgent,
      Language: window.navigator.language,
      Date: new Date().toString(),
      Resolution: `${window.screen.width}x${window.screen.height}`,
      Plugins: Array.from(window.navigator.plugins)
        .map((p) => p.name)
        .join('|'),
    };

    return this.getVersion().pipe(
      map((version) => {
        return {
          ...env,
          'Gio version': version,
        };
      })
    );
  }

  private getVersion() {
    return this.httpClient.get<any>(`${environment.serverAPIUri}/version`);
  }

  private getHumanContent(obj: { [key: string]: string }) {
    return Object.entries(obj)
      .map((d) => `${d[0]} : ${d[1]}`)
      .join('<br> \n');
  }
}
