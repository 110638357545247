import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { BaseComponent } from 'src/app/base/base.component';
import { Circle } from 'src/app/modules/mapV2/models/map.models';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { LocationQueryService } from 'src/app/services/query/location-query.service';
import { QueryService } from 'src/app/services/query/query.service';

@Component({
  selector: 'app-location-query',
  templateUrl: './location-query.component.html',
  styleUrls: ['./location-query.component.scss'],
})
export class LocationQueryComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  value = '';
  targets = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  removable = true;
  addOnBlur = true;
  errorMsg = false;
  errorMsgForAoI = false;
  isMobileResolution;
  areaOfInterestForm: FormGroup;
  @Input() areaOfInterestDrawn: Circle;

  constructor(
    private queryService: QueryService,
    private locationQueryService: LocationQueryService,
    private applicationStateService: ApplicationStateService
  ) {
    super();
  }

  ngOnInit() {
    this.isMobileResolution =
      this.applicationStateService.getIsMobileResolution();
    this.initForm();

    const clearInputSubscription =
      this.queryService.clearLocationInput.subscribe((flag) => {
        if (flag) {
          this.targets = [];
        }
      });

    this.subscriptions.push(clearInputSubscription);
  }

  ngOnChanges(changes: SimpleChanges) {
    const areaOfInterestDrawn = changes.areaOfInterestDrawn.currentValue;
    if (areaOfInterestDrawn && areaOfInterestDrawn.radiusMeters) {
      this.areaOfInterestForm.controls['lat'].setValue(
        areaOfInterestDrawn.center.lat
          ? areaOfInterestDrawn.center.lat.toFixed(3)
          : ''
      );
      this.areaOfInterestForm.controls['lng'].setValue(
        areaOfInterestDrawn.center.lng
          ? areaOfInterestDrawn.center.lng.toFixed(3)
          : ''
      );
      this.areaOfInterestForm.controls['radius'].setValue(
        areaOfInterestDrawn.radiusMeters
          ? (areaOfInterestDrawn.radiusMeters / 1000).toFixed(3)
          : ''
      );
      this.errorMsgForAoI = false;
    }
  }

  initForm() {
    this.areaOfInterestForm = new FormGroup({
      lat: new FormControl('', [
        Validators.required,
        Validators.min(-90),
        Validators.max(90),
      ]),
      lng: new FormControl('', [
        Validators.required,
        Validators.min(-180),
        Validators.max(180),
      ]),
      radius: new FormControl('', Validators.required),
    });
  }

  add(event: MatChipInputEvent): void {
    const { input, value } = event;

    // If the input is pasted strings separated by comma
    let values = [];
    if (value.includes(',')) {
      values = value.split(',');
      for (const item of values) {
        this.addChip(item, input);
      }
    } else if (value.includes(' ')) {
      values = value.split(' ');
      for (const item of values) {
        this.addChip(item, input);
      }
    } else {
      this.addChip(value.replace(/\s/g, ''), input);
    }
  }

  addChip(value, input) {
    if (value.trim()) {
      if (this.queryService.isValidImsi(value)) {
        if (!this.chipExists(value)) {
          this.targets.push({ value: value.trim(), imsi: value.trim() });
          this.errorMsg = false;
        }
        input.value = '';
      } else {
        this.errorMsg = true;
      }
    }
  }

  chipExists(value) {
    let entryExists = false;
    for (const entry of this.targets) {
      if (entry.value === value) {
        entryExists = true;
      }
    }
    return entryExists;
  }

  remove(target: string): void {
    const index = this.targets.indexOf(target);

    if (index >= 0) {
      this.targets.splice(index, 1);
    }
  }

  resetFields() {
    this.queryService.clearLocationInput.next(true);
  }

  submitQuery() {
    const targetsValue = [];
    for (let i = 0; i < this.targets.length; i++) {
      targetsValue.push({
        imsi: this.targets[i].imsi ? this.targets[i].imsi : '',
      });
    }

    if (this.areaOfInterestForm.valid) {
      this.locationQueryService.locationQueryData.next({});
      const data = {};
      data['targets'] = targetsValue;
      data['location'] = this.areaOfInterestForm.value;
      this.errorMsgForAoI = false;
      this.locationQueryService.requestAoIQuery(data).subscribe();
    } else {
      this.errorMsgForAoI = true;
    }
  }
}
