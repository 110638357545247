import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, map, Observable } from 'rxjs';
import { CaseInvestigation } from 'src/app/modules/analysis/shared/models/case-investigations.model';
import {
  CallLogsListRequestModel,
  CallLogsListResponseModel,
  CasesColumnsKeys,
  TargetColumnsKeys,
  TargetsListRequest,
} from 'src/app/modules/analysis/shared/models/targets-list-request.model';
import { AnalysisUtilService } from 'src/app/modules/analysis/shared/services/analysis-util.service';
import { AnalysisService } from 'src/app/modules/analysis/shared/services/analysis.service';
import { CaseInvestigationService } from 'src/app/modules/analysis/shared/services/case-investigations.service';
import { Case } from 'src/app/shared/models/case.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { DataGatheringService } from '../../../../modules/visual-investigation/services/data-gathering.service';

class InvestigationSeedQueue {
  targets: TargetItem[] = [];
  cases: Case[] = [];
  msisdns: string[] = [];
}
@Component({
  selector: 'app-create-investigation',
  templateUrl: './create-investigation.component.html',
  styleUrls: ['./create-investigation.component.scss'],
})
export class CreateInvestigationComponent implements OnInit {
  selectedSeedQueue: InvestigationSeedQueue = new InvestigationSeedQueue();
  casesList$: Observable<Case[]>;
  targetsList$: Observable<TargetItem[]>;
  msisdnsList$: Observable<CallLogsListResponseModel[]>;
  targetColumnsKeys: { [key: string]: string } = TargetColumnsKeys;
  caseCcolumnsKeys: { [key: string]: string } = CasesColumnsKeys;
  createInvestigationForm: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
      Validators.pattern(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/),
    ]),
    description: new FormControl('', [Validators.maxLength(1024)]),
    targetFilter: new FormControl('', [
      Validators.maxLength(40),
      Validators.pattern(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/),
    ]),
    caseFilter: new FormControl('', [
      Validators.maxLength(40),
      Validators.pattern(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/),
    ]),
    msisdnsFilter: new FormControl('', [
      Validators.maxLength(40),
      Validators.pattern(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/),
    ]),
  });
  isCDROnlyEnabled = false;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      withInformation: boolean;
      investigationData: CaseInvestigation;
    },
    public dialogRef: MatDialogRef<CreateInvestigationComponent>,
    private analysisService: AnalysisService,
    private analysisUtilService: AnalysisUtilService,
    private caseInvestigationService: CaseInvestigationService,
    private dataGatheringService: DataGatheringService
  ) {}

  ngOnInit(): void {
    this.getTargetsList('');
    this.getCasesList('');
    this.getMsisdnsCallLogsList('');
    this.listenToFormChanges();
  }

  listenToFormChanges(): void {
    this.createInvestigationForm
      .get('targetFilter')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((value) => {
        this.filterTargets(value);
      });
    this.createInvestigationForm
      .get('caseFilter')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((value) => {
        this.filterCases(value);
      });

    this.createInvestigationForm
      .get('msisdnsFilter')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((value) => {
        this.filterMsisdns(value);
      });
  }

  close(): void {
    this.dialogRef.close({ saved: false });
  }

  getTargetsList(searchText: string): void {
    const { params, requestbody } = this.buildRequest(
      searchText,
      this.targetColumnsKeys,
      ['alias', 'telnos', 'caseName']
    );
    this.targetsList$ = this.analysisService
      .getTargetsList(params, requestbody)
      .pipe(
        map(({ result, meta }: { meta: any; result: TargetItem[] }) => {
          return result;
        })
      );
  }
  getCasesList(searchText: string): void {
    const { params, requestbody } = this.buildRequest(
      searchText,
      this.caseCcolumnsKeys,
      ['caseName']
    );
    this.casesList$ = this.analysisService
      .getCasesList(params, requestbody)
      .pipe(
        map(({ result, meta }: { meta: any; result: Case[] }) => {
          return result;
        })
      );
  }

  getMsisdnsCallLogsList(searchText: string): void {
    const requestbody: CallLogsListRequestModel =
      this.buildRequestForCallLogs(searchText);
    this.msisdnsList$ = this.analysisService.getCallLogsList(requestbody).pipe(
      map(
        ({
          paginatedResults,
        }: {
          paginatedResults: CallLogsListResponseModel[];
        }) => {
          return paginatedResults;
        }
      )
    );
  }

  buildRequest(
    filterText: string,
    columnsKeys,
    searchFields: string[] = []
  ): {
    params: HttpParams;
    requestbody: TargetsListRequest;
  } {
    const request = {
      activeSort: '',
      direction: '',
      searchText: filterText,
      filters: {},
      pageSize: '10',
      pageNumber: '1',
      columnsKeys: columnsKeys,
      searchFields: searchFields,
    };
    return this.analysisUtilService.buildRequestPayload(request);
  }

  buildRequestForCallLogs(searchText): CallLogsListRequestModel {
    return this.analysisUtilService.buildClRequestPayload({
      createdBy: [],
      targets: [],
      direction: '',
      pageNumber: '1',
      pageSize: '10',
      msisdns: [],
      imsis: [],
      imeis: [],
      searchText: searchText,
      cases: [],
      statuses: [],
      activeSort: '',
    });
  }

  selectCaseItem(add: boolean, caseItem: Case): void {
    const indexOfSeed = this.selectedSeedQueue.cases.findIndex(
      (caseDetails) => caseDetails.id === caseItem.id
    );
    if (indexOfSeed > -1) {
      if (add) {
        return;
      }
      this.selectedSeedQueue.cases.splice(indexOfSeed, 1);
    } else {
      this.selectedSeedQueue.cases.push(caseItem);
    }
  }

  selectTargetItem(add: boolean, targetItem: TargetItem): void {
    const indexOfSeed = this.selectedSeedQueue.targets.findIndex(
      (target) => target.id === targetItem.id
    );
    if (indexOfSeed > -1) {
      if (add) {
        return;
      }
      this.selectedSeedQueue.targets.splice(indexOfSeed, 1);
    } else {
      this.selectedSeedQueue.targets.push(targetItem);
    }
  }

  selectMsisdnItem(add: boolean, msisdnItem: CallLogsListResponseModel): void {
    const indexOfSeed = this.selectedSeedQueue.msisdns.findIndex((msisdn) =>
      msisdnItem.msisdns.includes(msisdn)
    );
    if (indexOfSeed > -1) {
      if (add) {
        return;
      }
      this.selectedSeedQueue.msisdns.splice(indexOfSeed, 1);
    } else {
      this.selectedSeedQueue.msisdns.push.apply(
        this.selectedSeedQueue.msisdns,
        msisdnItem.msisdns
      );
    }
  }

  filterTargets(filterText: string): void {
    this.getTargetsList(filterText);
  }
  filterCases(filterText: string): void {
    this.getCasesList(filterText);
  }
  filterMsisdns(filterText: string): void {
    this.getMsisdnsCallLogsList(filterText);
  }

  createInvestigation(): void {
    let requestBody: CaseInvestigation;
    if (this.data?.withInformation) {
      requestBody = { ...this.data.investigationData };
      requestBody.name = this.createInvestigationForm.value.name;
      requestBody.description = this.createInvestigationForm.value.description;
      requestBody.graphFilter =
        this.dataGatheringService.callLogsFilters$.getValue();
    } else {
      requestBody = {
        name: this.createInvestigationForm.value.name,
        description: this.createInvestigationForm.value.description,
        entities: {
          targets: this.selectedSeedQueue.targets.map((target) => target.id),
          cases: this.selectedSeedQueue.cases.map(
            (caseDetails) => caseDetails.id
          ),
          msisdns: this.selectedSeedQueue.msisdns,
          files: [],
        },
      };
    }
    this.caseInvestigationService
      .createCaseInvestigation(requestBody)
      .subscribe((response) => {
        this.caseInvestigationService.refreshInvestigationsList.next(true);
        this.dialogRef.close({ saved: true, investigation: response });
      });
  }
}
