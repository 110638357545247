import { Injectable } from '@angular/core';
import { QueryContext } from '@trg-commons/data-models-ts';
import {
  AdIdAnalyzeLocationDto,
  AdIdTraceDto,
  OperationRequestDto,
  RequestStatus,
} from '@trg-commons/gio-data-models-ts';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter } from 'rxjs/operators';
import { normalizeToArray } from 'src/app/shared/util/helper';
import { Action } from './../../../shared/classes/action.class';
import { AdIdOperationRequestHistoryService } from './ad-id-operation-request-history.service';

export class AdIdStateAction extends Action {
  key:
    | 'create-new-target'
    | 'add-to-existing-target'
    | 'de-anonymization'
    | 'common-ids'
    | 'discover'
    | 'history'
    | 'analyze'
    | 'add-to-target'
    | string;
}

export class AdIdResultSelection {
  ifa?: string;
  entityId?: string;
  checked: boolean;

  constructor(partial: Partial<AdIdResultSelection> = {}) {
    Object.assign(this, partial);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AdIdStateService {
  private action = new Subject<AdIdStateAction>();
  private timeLineConfig = new Subject<{
    from?: number;
    to?: number;
    updateConfig?: boolean;
  }>();
  private operationRequest = new BehaviorSubject<OperationRequestDto>(null);
  private activeRequestStatus = new BehaviorSubject<RequestStatus>(null);
  private userSelectedOperation = new BehaviorSubject<QueryContext>(null);
  private selectedTableRow = new Subject<AdIdResultSelection>();
  tableExpansion = new Subject<boolean>();
  downloadInProgress = new Subject<boolean>();

  constructor(private requestHistory: AdIdOperationRequestHistoryService) {}

  history(): AdIdOperationRequestHistoryService {
    return this.requestHistory;
  }

  getOperationRequest(): OperationRequestDto {
    return this.operationRequest.getValue();
  }

  getActiveOperationRequestType(): QueryContext {
    const request = this.operationRequest.getValue();
    return request ? request.body.context : null;
  }

  isActiveRequestOfType(type: QueryContext | QueryContext[]): boolean {
    type = normalizeToArray(type);
    return type.includes(this.getActiveOperationRequestType());
  }

  hasActiveOperationRequest(correlationId?: string): boolean {
    const operationRequest = this.operationRequest.getValue();

    if (!correlationId || !operationRequest) {
      return !!operationRequest;
    }

    return (
      !!this.operationRequest &&
      this.operationRequest.getValue().correlationId === correlationId
    );
  }

  onOperationRequest(): Observable<OperationRequestDto> {
    return this.operationRequest.asObservable().pipe(filter((item) => !!item));
  }

  updateOperationRequest(request: OperationRequestDto) {
    if (request) {
      this.activeRequestStatus.next(RequestStatus.InProgress);
      this.requestHistory.removeFromHistory(request.correlationId);
      this.requestHistory.addToHistory(request);
    }

    if (!request) {
      this.updateActiveRequestStatus(null);
    }

    this.operationRequest.next(
      request ? new OperationRequestDto(request) : null
    );
  }

  getActiveRequestStatus(): RequestStatus {
    return this.activeRequestStatus.getValue();
  }

  onActiveRequestStatus(): Observable<RequestStatus> {
    return this.activeRequestStatus
      .asObservable()
      .pipe(filter((status) => !!status));
  }

  updateActiveRequestStatus(status: RequestStatus) {
    this.activeRequestStatus.next(status);
  }

  updateTimelineConfig(value: { from?: number; to?: number }) {
    this.timeLineConfig.next(value);
  }

  onTimelineConfigChanged() {
    return this.timeLineConfig.asObservable();
  }

  onAction(): Observable<AdIdStateAction> {
    return this.action.asObservable();
  }

  publishAction(action: AdIdStateAction) {
    this.action.next(action);
  }

  activeRequestHasGeoZones(): boolean {
    const activeRequest = this.operationRequest.getValue();
    const hasGeoZone = this.isActiveRequestOfType([
      QueryContext.AnalyzeLocation,
      QueryContext.CommonIds,
      QueryContext.Trace,
    ]);
    if (!hasGeoZone) {
      return false;
    }

    // Its regular trace
    if (this.isActiveRequestOfType(QueryContext.Trace)) {
      return (
        activeRequest.body.params &&
        !!(activeRequest.body.params as AdIdTraceDto).geoZones
      );
    }

    return hasGeoZone;
  }

  activeRequestHasMultipleGeoZones(): boolean {
    const request = this.operationRequest.getValue();
    if (
      (request && request.body.context === QueryContext.AnalyzeLocation) ||
      request.body.context === QueryContext.CommonIds
    ) {
      request.body.params = request.body.params as AdIdAnalyzeLocationDto;
      return request.body.params.geoZones.length > 1;
    }
    return false;
  }

  selectTableRow(selectedRow: AdIdResultSelection) {
    this.selectedTableRow.next(selectedRow);
  }

  onSelectedTableRow(): Observable<AdIdResultSelection> {
    return this.selectedTableRow.asObservable();
  }

  onUserSelectedOperation(): Observable<QueryContext> {
    return this.userSelectedOperation.asObservable();
  }

  updateUserSelectedOperation(operation: QueryContext) {
    return this.userSelectedOperation.next(operation);
  }

  clearUserSelectedOperation() {
    return this.userSelectedOperation.next(null);
  }

  onNewOperationRequestOrOnNewUserSelection(): Observable<
    OperationRequestDto | QueryContext
  > {
    return merge(this.onOperationRequest(), this.onUserSelectedOperation());
  }
}
