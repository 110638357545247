import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environment/environment';
import { Environment } from 'environment/models';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { BehaviorSubject, catchError, mergeMap, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private config: Partial<Environment>;
  private _authenticatedConfigLoaded: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  public authenticatedConfigLoaded$ =
    this._authenticatedConfigLoaded.asObservable();
  private _anonConfigLoaded: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public anonConfigLoaded$ = this._anonConfigLoaded.asObservable();

  constructor(private http: HttpClient) {}

  fetchAuthenticatedConfig(): void {
    if (!this._authenticatedConfigLoaded.getValue()) {
      this.http
        .get<Partial<Environment>>(environment.proxyAPIUri + '/app/config')
        .pipe(
          tap((config) => {
            this.config = this.config ? { ...this.config, ...config } : config;
            this._authenticatedConfigLoaded.next(true);
          }),
          mergeMap(() => this.authenticatedConfigLoaded$),
          catchError((e) => throwError(() => e))
        )
        .subscribe();
    }
  }

  fetchAnonConfig() {
    if (!this._anonConfigLoaded.getValue()) {
      this.http
        .get<Partial<Environment>>(environment.proxyAPIUri + '/app/anon-config')
        .pipe(
          tap((config) => {
            this.config = config;
            this._anonConfigLoaded.next(true);
          }),
          catchError((e) => throwError(() => e))
        )
        .subscribe();
    }
  }

  getConfig() {
    return this.config;
  }

  public getConfigVariable<T extends keyof Environment>(variable: T) {
    if (!this.config) return environment[variable];
    return this.config[variable] ?? environment[variable];
  }

  public getLanguage(): string {
    return this.getConfigVariable('defaultLanguage');
  }

  isCallLogPermitted(msisdn: string): boolean {
    try {
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      const callLogsEnabledCountries = this.getConfigVariable(
        'callLogsEnabledCountries'
      );

      const countryCode = phoneNumberUtil
        .parse(msisdn, '')
        .getCountryCode()
        .toString();

      return callLogsEnabledCountries.includes(countryCode);
    } catch (e) {
      return false;
    }
  }

  clearConfigValue(): void {
    this._anonConfigLoaded.next(false);
    this._authenticatedConfigLoaded.next(false);
    this.config = null;
  }
}
