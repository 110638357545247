<div mat-line class="search-list-value">
  {{ inputType.value }}
</div>
<div mat-line class="search-list-label">
  {{ inputTypeValueLabel[inputType.key].label | translate }}
</div>
<div
  class="info-message"
  *ngIf="
    inputTypeValueLabel[inputType.key].value === searchLabels.POSSIBLE_TELNO
  "
>
  <mat-icon class="icon">info</mat-icon
  ><span class="search-list-label">{{ infoMessage }}</span>
</div>
