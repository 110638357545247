import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AvatarActivityLog } from 'datalayer/models/avatar/avatar-activity-log';
import {
  AvatarActivityTask,
  AvatarActivityTaskDTO,
} from 'datalayer/models/avatar/avatar-activity-task';
import { AvatarPerson } from 'datalayer/models/avatar/avatar-person';
import { AvatarRemoteView } from 'datalayer/models/avatar/avatar-remote-view';
import { AvatarRemoteViewStatus } from 'datalayer/models/avatar/avatar-remote-view-status.enum';
import { AvatarSocialType } from 'datalayer/models/avatar/avatar-social-type.enum';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AvatarActivity } from 'src/app/modules/avatar/models/avatar-activities.class';
import { DataLayerModule } from 'src/app/modules/data-layer/data-layer.module';
import { Avatar } from 'src/app/modules/data-layer/models';
import {
  BaseApiService,
  RequestOptions,
} from 'src/app/modules/data-layer/services/base';
import { environment } from 'src/environments/environment';
import { AvatarActivityLogDTO } from './avatar-activity-log-dto';
import { AvatarDataChangeType } from './avatar-data-change-motification';
import { AvatarDTO } from './avatar-dto';
import { AvatarPersonDTO } from './avatar-person-dto';
import { AvatarErrorMessages } from 'datalayer/models/avatar/avatar-error-messages.enum';
import { AvatarImportStatus } from 'datalayer/models/avatar/avatar-import';

@Injectable({
  providedIn: DataLayerModule,
})
export class AvatarApiService extends BaseApiService<
  Avatar,
  AvatarDTO,
  AvatarDataChangeType
> {
  constructor(httpClient: HttpClient) {
    super(httpClient, AvatarDTO);
    this.baseRoute = environment['avatarV1API'];
  }

  public create(model: Avatar): Observable<Avatar> {
    const route: string = `${this.baseRoute}/avatars`;
    return this.request<AvatarDTO>('POST', route, this.encode(model)).pipe(
      map((response: AvatarDTO) => {
        return this.decode(response);
      }),
      catchError(() =>
        throwError(() => new Error(AvatarErrorMessages.CreateError))
      )
    );
  }

  public getAvatarActivityTasks(): Observable<AvatarActivityTask[]> {
    const route: string = `${environment.avatarV2API}/avatarActivityTasks`;
    return this.request<AvatarActivityTaskDTO[]>('GET', route, null).pipe(
      map((response: AvatarActivityTaskDTO[]) => {
        return response.map((dto: AvatarActivityTaskDTO) => {
          return new AvatarActivityTaskDTO(dto).toModel();
        });
      })
    );
  }

  public onRunAction(activity: AvatarActivity): Observable<AvatarActivityTask> {
    const route: string = `${environment.avatarV2API}/avatarActivityTasks/${activity.id}/run`;
    return this.request<AvatarActivityTaskDTO>('PUT', route, null).pipe(
      map((response: AvatarActivityTaskDTO) => {
        return new AvatarActivityTaskDTO(response).toModel();
      })
    );
  }

  public onStopAction(
    activity: AvatarActivity
  ): Observable<AvatarActivityTask> {
    const route: string = `${environment.avatarV2API}/avatarActivityTasks/${activity.id}/stop`;
    return this.request<AvatarActivityTaskDTO>('PUT', route, null).pipe(
      map((response: AvatarActivityTaskDTO) => {
        return new AvatarActivityTaskDTO(response).toModel();
      })
    );
  }
  public createInteraction(model): Observable<AvatarActivityTask> {
    const route: string = `${environment.avatarV2API}/avatarActivityTasks`;
    return this.request<AvatarActivityTaskDTO>('POST', route, model).pipe(
      map((response: AvatarActivityTaskDTO) => {
        return new AvatarActivityTaskDTO(response).toModel();
      })
    );
  }

  public getAll(options?: RequestOptions): Observable<Avatar[]> {
    const route: string = `${this.baseRoute}/avatars`;
    return this.request<{ result: AvatarDTO[] }>(
      'GET',
      route,
      null,
      options
    ).pipe(
      map((response: { result: AvatarDTO[] }) => {
        return response.result.map((dto: AvatarDTO) => {
          return this.decode(dto);
        });
      })
    );
  }

  public getModelName(): string {
    return Avatar.name;
  }

  public getAvatarActivityLog(
    avatarId: string
  ): Observable<AvatarActivityLog[]> {
    const route: string = `${this.baseRoute}/avatars/${avatarId}/activityLog`;
    return this.request<{ result: AvatarActivityLogDTO[] }>(
      'GET',
      route,
      null
    ).pipe(
      map((response: { result: AvatarActivityLogDTO[] }) => {
        return response.result.map((dto: AvatarActivityLogDTO) => {
          return new AvatarActivityLogDTO(dto).toModel();
        });
      })
    );
  }

  public availableAvatars(options?: RequestOptions): Observable<Avatar[]> {
    const route: string = `${this.baseRoute}/avatars/availableAvatars`;
    return this.request<{ result: AvatarDTO[] }>(
      'GET',
      route,
      null,
      options
    ).pipe(
      map((response: { result: AvatarDTO[] }) => {
        return response.result.map((dto: AvatarDTO) => {
          return this.decode(dto);
        });
      })
    );
  }

  public generateCredentials(
    options?: RequestOptions
  ): Observable<{ name: string; lastName: string }> {
    const route: string = `${this.baseRoute}/persons/generateCredentials`;
    return this.request<{ name: string; lastname: string }>(
      'GET',
      route,
      null,
      options
    ).pipe(
      map((response: { name: string; lastname: string }) => {
        return {
          name: response.name,
          lastName: response.lastname,
        };
      })
    );
  }

  public getPersons(options?: RequestOptions): Observable<AvatarPerson[]> {
    const route: string = `${this.baseRoute}/persons`;
    return this.request<{ result: AvatarPersonDTO[] }>(
      'GET',
      route,
      null,
      options
    ).pipe(
      map((response: { result: AvatarPersonDTO[] }) => {
        return response.result.map((dto) => {
          return new AvatarPersonDTO(dto).toModel(this.baseRoute);
        });
      })
    );
  }

  public bindToUser(model: Avatar): Observable<boolean> {
    const route: string = `${this.baseRoute}/avatars/${model.id}/bindToUser`;
    return this.request<boolean>('POST', route, null);
  }

  public reserveRemoteView(
    avatarId: string,
    socialPlatform: AvatarSocialType,
    options?: RequestOptions
  ): Observable<AvatarRemoteView> {
    const route: string = `${this.baseRoute}/avatars/${avatarId}/${socialPlatform}/remoteView`;
    return this.request<AvatarRemoteView>('GET', route, null, options).pipe(
      map((response) => {
        return new AvatarRemoteView(response);
      })
    );
  }

  public checkRemoteViewStatus(
    avatarId: string,
    socialPlatform: AvatarSocialType
  ): Observable<AvatarRemoteView> {
    const route: string = `${this.baseRoute}/avatars/${avatarId}/${socialPlatform}/remoteView/status`;
    return this.request<AvatarRemoteView>('GET', route, null).pipe(
      map((response) => {
        return new AvatarRemoteView(response);
      })
    );
  }

  public releaseRemoteView(
    avatarId: string,
    socialPlatform: AvatarSocialType
  ): Observable<AvatarRemoteViewStatus> {
    const route: string = `${this.baseRoute}/avatars/${avatarId}/${socialPlatform}/remoteView/release`;
    return this.request<{ status: AvatarRemoteViewStatus }>(
      'GET',
      route,
      null
    ).pipe(
      map((response) => {
        return response.status;
      })
    );
  }

  public decode(dto: AvatarDTO): Avatar {
    return new AvatarDTO(dto).toModel(this.baseRoute);
  }

  public onResourceChanged(data: {
    type: AvatarDataChangeType;
    dto: AvatarDTO;
    message?: string;
  }): void {
    this.resourceChanged.next({
      type: data.type,
      models: [this.decode(data.dto)],
      message: data.message,
    });
  }

  public renew(model: Avatar): Observable<boolean> {
    const route = `${this.baseRoute}/avatars/${model.id}/renewUserBidding`;
    return this.request<boolean>('POST', route, null);
  }

  public import(body): Observable<AvatarDTO[]> {
    const route: string = `${this.baseRoute}/avatars/importSocials`;
    return this.request('POST', route, body);
  }

  public getImportStatus(
    avatarId: string,
    platform: AvatarSocialType
  ): Observable<AvatarImportStatus> {
    const route: string = `${this.baseRoute}/avatars/${avatarId}/${platform}/importSocialStatus`;
    return this.request<AvatarImportStatus>('GET', route, null);
  }

  public setUserActivityResult(
    avatarId: string,
    platform: AvatarSocialType,
    payload: string
  ): Observable<void> {
    const route: string = `${this.baseRoute}/avatars/${avatarId}/${platform}/remoteView/setUserActivityResult/${payload}`;
    return this.request<void>('GET', route, null);
  }
}
