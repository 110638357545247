import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-photo-carousel-modal',
  templateUrl: './photo-carousel-modal.component.html',
  styleUrls: ['./photo-carousel-modal.component.scss'],
})
export class PhotoCarouselModalComponent implements OnInit {
  index = 0;
  speed = 1000;
  infinite = true;
  direction = 'right';
  directionToggle = true;
  autoplay = false;
  photos;
  imgWidth: number;

  constructor(
    public dialogRef: MatDialogRef<PhotoCarouselModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.imgDimensions(this.data.index);
    if (!!this.data) {
      this.setPhotos();
      this.setIndex();
    }
  }

  private setPhotos(): void {
    this.photos = this.data.photos.map((photo) => ({
      image: photo.url || photo.image,
    }));
  }

  private setIndex(): void {
    this.index = this.photos.findIndex((img) =>
      typeof this.data.index === 'number'
        ? img.image === this.photos[this.data.index].image
        : img.image === this.data.index
    );
  }

  moveSlider(i) {
    this.imgDimensions(this.photos[i].image);
  }

  imgDimensions(imgSrc) {
    const img = new Image();
    img.src = imgSrc;

    img.addEventListener('load', () => {
      this.imgWidth = img.width;
    });
  }
}
