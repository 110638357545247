import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveToast } from 'ngx-toastr';
import { CaseInvestigationService } from 'src/app/modules/analysis/shared/services/case-investigations.service';
import { InvestigationNavigatedFrom } from 'src/app/modules/search-intel/models/intel-results-view.model';
import { FtaNotificationState } from 'src/app/modules/visual-investigation/models/text-analysis-uploader.model';
import { DataGatheringService } from 'src/app/modules/visual-investigation/services/data-gathering.service';
import { GraphEntitiesService } from 'src/app/modules/visual-investigation/services/graph-entities.service';
import { FtaNotificationService } from '../../services/fta-notification.service';

@Component({
  selector: 'app-fta-notification',
  templateUrl: './fta-notification.component.html',
  styleUrls: ['./fta-notification.component.scss'],
})
export class FtaNotificationComponent implements OnInit {
  public isHidden = false;
  public toast: ActiveToast<unknown>;
  public notificationText: string;
  public state: FtaNotificationState;
  public ftaNotificationState = FtaNotificationState;
  public investigationId: string = '';
  private fileId: string;

  constructor(
    private graphEntitiesService: GraphEntitiesService,
    private caseInvestigationService: CaseInvestigationService,
    private dataGatheringService: DataGatheringService,
    private router: Router,
    private ftaNotificationService: FtaNotificationService
  ) {}

  ngOnInit(): void {
    this.changeStateTo(FtaNotificationState.PENDING);
  }

  public removeGroup() {
    this.toast.toastRef.close();
  }

  public updateNotificationData(notificationText: string, fileId: string) {
    this.notificationText = notificationText;
    this.fileId = fileId;
  }

  public toggleHidden() {
    this.isHidden = !this.isHidden;
  }

  public changeStateTo(newState: FtaNotificationState) {
    if (this.state === this.ftaNotificationState.DONE) return;
    this.state = newState;
  }

  public onClickUpdateGraph() {
    const fileExistsInCurrentInvestigation =
      this.caseInvestigationService.currentInvestigation.id ===
      this.investigationId;
    const isInvestigationRoute = this.router.url.includes(this.investigationId);
    if (!isInvestigationRoute) {
      this.ftaNotificationService.navigateToInvestigation(this.investigationId);
    } else if (fileExistsInCurrentInvestigation) {
      this.graphEntitiesService.fetchGraphData(this.fileId);
    } else {
      this.dataGatheringService.clearAll();
      this.dataGatheringService.clearingData.next();
      this.ftaNotificationService.navigateToInvestigation(this.investigationId);
    }
    this.removeGroup();
  }
}
