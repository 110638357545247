import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { BillingActions } from '@shared/models/billing-action.model';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { IntelSearchArgTypes } from 'src/app/modules/search-intel/models/intel-search-arg-types.model';
import { SearchLabels } from 'src/app/modules/search-intel/models/search-intel.model';
import { BaseService } from 'src/app/services/base.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import {
  DashboardService,
  DashboardView,
} from 'src/app/services/dashboard/dashboard.service';
import { InstantMessagesStore } from 'src/app/services/instant-messages.store';
import { QueryService } from 'src/app/services/query/query.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';

@Injectable()
export class SearchIntelNavbarNumberValidatorService extends BaseService {
  phoneNumberUtil = PhoneNumberUtil.getInstance();
  tenantCountryCodes: number[];
  searchDrop = false;

  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    private appConfigService: AppConfigService,
    private targetService: TargetService,
    private queryService: QueryService,
    private translationService: TranslationService,
    private userBillingService: UserBillingService,
    private instantMessagesFetcher: InstantMessagesStore,
    private dashboardService: DashboardService
  ) {
    super(router, snackBar);
    this.tenantCountryCodes =
      this.appConfigService.getConfigVariable('tenantCountryCodes');
  }

  inputTypeValueLabel: { [key: string]: { value: string; label: string } } = {
    [IntelSearchArgTypes.TELNO]: {
      value: SearchLabels.PHONE,
      label: 'Phone number',
    },
    [IntelSearchArgTypes.POSSIBLE_TELNO]: {
      value: SearchLabels.POSSIBLE_TELNO,
      label: 'Phone number',
    },
    [IntelSearchArgTypes.NAME_WITH_SURNAME]: {
      value: SearchLabels.NAME_WITH_SURNAME,
      label: 'Name Surname',
    },
    [IntelSearchArgTypes.NAME_WITH_MIDDLE_AND_SURNAME]: {
      value: SearchLabels.NAME_WITH_MIDDLE_AND_SURNAME,
      label: 'Name Middle Surname',
    },
    [IntelSearchArgTypes.NAME]: {
      value: SearchLabels.NAME,
      label: 'Full Name',
    },
    [IntelSearchArgTypes.EMAIL]: { value: SearchLabels.EMAIL, label: 'Email' },
    [IntelSearchArgTypes.URL]: {
      value: SearchLabels.URL,
      label: 'Profile url for Facebook, Twitter, Instagram, Linkedin, Tiktok',
    },
    [IntelSearchArgTypes.USERNAME]: {
      value: SearchLabels.USERNAME,
      label: 'Username',
    },
    [IntelSearchArgTypes.NIK]: {
      value: SearchLabels.NIK,
      label: 'NIK',
    },
    [IntelSearchArgTypes.HASHTAG]: {
      value: SearchLabels.HASHTAG,
      label: 'Twitter hashtag',
    },
    [IntelSearchArgTypes.CURP]: { value: SearchLabels.CURP, label: 'CURP' },
  };

  isValidImsi(number, searchText: FormControl) {
    const queries = [];
    if (this.queryService.isValidImsi(number)) {
      queries.push({ imsi: number });
      this.submitQuickQuery(queries);
      searchText.setValue('');
      return true;
    }
  }

  getRandomPhoneNumberForTenant(phoneNo?: string): string {
    try {
      if (phoneNo) {
        const parsedPhone = this.phoneNumberUtil.parse(phoneNo, '');
        const countryCode =
          this.phoneNumberUtil.getRegionCodeForNumber(parsedPhone);
        const num = this.phoneNumberUtil.getExampleNumber(countryCode);
        return `+${num.getCountryCode()}${num.getNationalNumber()}`;
      }
      const countryCodeNum =
        this.tenantCountryCodes?.length > 0 ? this.tenantCountryCodes[0] : null;

      if (!countryCodeNum) throw Error();

      const countryCode =
        this.phoneNumberUtil.getRegionCodeForCountryCode(countryCodeNum);
      const num = this.phoneNumberUtil.getExampleNumber(countryCode);

      const number = `+${num.getCountryCode()}${num.getNationalNumber()}`;

      return number;
    } catch (e) {
      return '+5215513336419';
    }
  }

  isValidPhone(phoneNumber: string, searchText: FormControl) {
    try {
      const queries = [];
      const validPhone = this.targetService.getValidPhone(
        this.phoneNumberUtil,
        phoneNumber
      );

      if (validPhone) {
        searchText.setValue('');

        queries.push({ telno: validPhone });
        this.submitQuickQuery(queries);
      } else {
        this.showMessage(
          this.translationService.interpolate(
            `Enter the complete phone number including country code. Example: #{example}`,
            {
              example: this.getRandomPhoneNumberForTenant(phoneNumber),
            }
          )
        );
      }
    } catch (e) {
      this.showMessage(
        this.translationService.interpolate(
          `Enter the complete phone number including country code. Example: #{example}`,
          {
            example: this.getRandomPhoneNumberForTenant(phoneNumber),
          }
        )
      );
    }
  }

  submitQuickQuery(queries) {
    this.searchDrop = false;
    if (
      this.userBillingService.userHasEnoughCredits([
        BillingActions.QUERY_LOCATION,
      ])
    ) {
      const quickQuerySubscription = this.queryService
        .quickQuery(queries)
        .subscribe({
          next: (response: { result: any }) => {
            const [query] = response.result;
            if (query.query_args.telno) {
              this.instantMessagesFetcher.fetchAllImPlatforms(
                query.query_args.telno,
                query.id,
                true
              );
            }
            this.showMessage(
              this.translationService.translate('Query created successfully!')
            );
            this.dashboardService.componentsView.next(DashboardView.LOG);
            this.dashboardService.showLogTab.next(true);
            quickQuerySubscription.unsubscribe();
          },
          error: (err) => {
            this.queryService.handleQueryError(err);
            quickQuerySubscription.unsubscribe();
          },
        });
    }
  }

  public getCountryCode(searchTextValue: string) {
    const parsedNumber = this.phoneNumberUtil.parse(searchTextValue, '');
    return this.phoneNumberUtil
      .getRegionCodeForNumber(parsedNumber)
      ?.toLocaleLowerCase();
  }

  public checkForValidMsisdn(searchInputValue: string) {
    const parsedMsisdn =
      this.queryService.deleteInvalidCharactersFromMsisdn(searchInputValue);
    const isValid = this.targetService.getValidPhone(
      this.phoneNumberUtil,
      parsedMsisdn
    );
    if (!!isValid) {
      searchInputValue = parsedMsisdn;
    }
    return isValid;
  }
}
