import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MarkerSectorService } from '@app/services';
import { DataSource } from 'datalayer/models/platform-models';
import { RefreshLocalCacheService } from 'datalayer/services/base/refresh-data.service';
import { format } from 'date-fns';
import { get, head, uniqWith } from 'lodash-es';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ImportDataRequestDialogComponent } from 'src/app/components/analytics/import-data-request-dialog/import-data-request-dialog.component';
import { MapComponent } from 'src/app/modules/mapV2/components/map/map.component';
import {
  Button,
  Circle,
  DrawMode,
  Feature,
  IconMarker,
  MapOptions,
  Marker,
  Point,
  Polygon,
  Polyline,
} from 'src/app/modules/mapV2/models/map.models';
import { ProfilerService } from 'src/app/modules/profiler/services/profiler.service';
import {
  ProfilerDashboardSectionsRoutes,
  ProfilerOverviewHeaderMapOptions,
} from 'src/app/modules/profiler/shared/models/profiler-dashboard-sections.model';
import { AppConfigService } from 'src/app/providers/app-config.service';
import {
  AnalyticsService,
  IMPORT_DATA_THRESHOLD_PER,
} from 'src/app/services/analytics/analytics.service';
import { MapHelperService } from 'src/app/services/map-helper/map-helper.service';
import { NearbyLocationsService } from 'src/app/services/nearby-locations.service';
import { QueryService } from 'src/app/services/query/query.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { UserBehaviorService } from 'src/app/services/user-behavior.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { FeedItem } from 'src/app/shared/models/feed.model';
import { NearbyLocationsProfile } from 'src/app/shared/models/nearby-locations.model';
import { PredictedLocationsTimeFilters } from 'src/app/shared/models/predicted-locations-time-filters.interface';
import {
  IntervalMinutes,
  Query,
  QueryType,
  SortMode,
} from 'src/app/shared/models/query-item.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { debounce } from 'src/app/shared/util/debounce.decorator';
import {
  randomString,
  transformSnakeToCamel,
} from 'src/app/shared/util/helper';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { PlayerState } from '../../../components/timeline/timeline.component';
import { ProfilerIconMarker } from '../../../shared/models/profiler-icon-marker';
import { TargetLocationIconMarker } from '../../../shared/models/target-location-icon-marker';
import { MarkerCollectionOptions } from '../models/marker-collection-options.enum';
import { MarkerCollection } from '../models/marker-collection.model';
import { MarkerIcon } from '../models/marker-icons.enum';
import { ProfilerMapDataService } from '../services/profiler-map-data.service';
import { ProfilerMapService } from '../services/profiler-map.service';

@Component({
  selector: 'app-profiler-map',
  template: '',
})
export class ProfilerMapComponent extends BaseComponent implements OnInit {
  @ViewChild('map') mapComponent: MapComponent;

  @Input() clearGeofenceArea: boolean;
  @Input() enableGeofenceLayer: boolean;

  @Input() placesOfInterestVisibility: boolean;
  @Input() selectedSection: ProfilerOverviewHeaderMapOptions =
    ProfilerOverviewHeaderMapOptions.MAP;

  @Output() maximizeClick = new EventEmitter<any>();
  @Output() disableGeofenceLayer = new EventEmitter<void>();
  @Output() emptyData = new EventEmitter<
    Partial<{ [key in ProfilerOverviewHeaderMapOptions]: boolean }>
  >();
  @Output() mapLoaded = new EventEmitter<void>();
  @Input() showTimeline: boolean;

  markerCollection = new MarkerCollection();
  public markersChange: BehaviorSubject<Marker[]> = new BehaviorSubject<
    Marker[]
  >([]);
  public markers$: Observable<Marker[]> = this.markersChange.asObservable();
  zoom = 5;
  center: Point;
  circles: Circle[] = [];
  polygons: Polygon[] = [];
  heatmapEnabled = false;
  historyTimelineEnabled = false;
  clusteringEnabled = false;
  enableDrawing = DrawMode.None;
  enableSearching = false;
  buttons: Button[] = [];
  feature: Feature;
  trafficEnabled = false;
  renderTimeline = false;
  targetLocations: Partial<Query | any>[] = [];
  locations = [];
  imagesPath = 'assets/static/images/profiler/';
  targetId: string;
  target: TargetItem;
  maximizedMap = false;
  overviewHeaderSection: string = ProfilerOverviewHeaderMapOptions.MAP;
  availableAnalyticsData = false;
  allFeeds: FeedItem[] = [];
  isProfilerOverview = false;
  theme: string;
  socialAddresses = {};
  placesOfInterestIsActive: boolean;
  quarantineGeofenceData: any;
  activeGeofences = { telno: {}, imsi: {} };

  geofencingIsEnabled = false;
  headMapPoints: Point[] = [];
  polyLines: Polyline[] = [];
  mapOptions: MapOptions = new MapOptions({
    disableDefaultUI: true,
    updateMapBounds: true,
    minZoom: 17,
  });

  customAlert: string;
  initialMarkersNumber: number = 100;

  playerState: PlayerState = 'stopped';
  showPlaybackControls: boolean = true;
  filters: string[] = [];
  scheduleDialogOpened?: boolean;
  dataSourceCount: number = 0;
  profilerOverviewHeaderMapOptions = ProfilerOverviewHeaderMapOptions;
  predictedLocationTimeFilters: PredictedLocationsTimeFilters;
  isLocatingDisabled = false;

  constructor(
    public dialog: MatDialog,
    public mapHelperService: MapHelperService,
    public profilerService: ProfilerService,
    public route: ActivatedRoute,
    public queryService: QueryService,
    public analyticsService: AnalyticsService,
    public translationService: TranslationService,
    public router: Router,
    public appConfigService: AppConfigService,
    public userBehaviorService: UserBehaviorService,
    public targetService: TargetService,
    public profilerMapService: ProfilerMapService,
    public markerSectorService: MarkerSectorService,
    public nearbyLocationsService: NearbyLocationsService,
    public refreshLocalCacheService: RefreshLocalCacheService,
    public profilerMapDataService: ProfilerMapDataService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.targetId = this.route.snapshot.paramMap.get('id');
    this.profilerService.targetId.next(this.targetId);
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.isProfilerOverview = !!this.router.url.includes(
      ProfilerDashboardSectionsRoutes.OVERVIEW
    );
    this.isLocatingDisabled = this.appConfigService.getConfigVariable(
      'disableLocatingFunctionality'
    );
  }

  ngOnInit() {
    this.setFilterChangeListener();
    this.setMaximizeListener();
  }

  @debounce(12000)
  maybeDataLoaded(): void {
    if (this.dataSourceCount === 0) {
      this.mapLoaded.emit();
    }
  }

  registerDataSource<T>(dataSource: Observable<T>): Observable<T> {
    this.dataSourceCount++;
    return dataSource.pipe(
      tap({
        next: (payload: T) => {},
        error: (error) => {
          this.dataSourceCount--;
          this.maybeDataLoaded();
        },
        complete: () => {
          this.dataSourceCount--;
          this.maybeDataLoaded();
        },
      })
    );
  }

  getTargetData(): Observable<TargetItem | null> {
    return this.profilerService.getTargetData(this.targetId).pipe(
      map((target: TargetItem) => {
        if (!target || this.target) {
          return;
        }

        this.target = target;
        const newAddress = get(target, 'addresses');
        this.socialAddresses = {};

        if (newAddress?.length) {
          this.markersChange.next(
            this.markersChange
              .getValue()
              .filter((c) => !c.id.includes('home-address'))
          );
          newAddress.forEach((e) => {
            if (!this.socialAddresses[e]) {
              this.socialAddresses[e] = 'home';
            }
            this.addCustomAddresses();
          });
        }

        return target;
      })
    );
  }

  @debounce(1500)
  setMarkers(): void {
    this.resetMap();

    switch (this.selectedSection) {
      case ProfilerOverviewHeaderMapOptions.MAP:
        this.populateLastSeen();

        break;
      case ProfilerOverviewHeaderMapOptions.HEATMAP:
        this.populateHeatmap();

        break;
      case ProfilerOverviewHeaderMapOptions.TIMELINE:
        this.populateTimeline();

        break;
      case ProfilerOverviewHeaderMapOptions.PREDICTEDLOCATIONS:
        this.populatePredictedLocations();

        break;
      case ProfilerOverviewHeaderMapOptions.HEATMAPLASTSEEN:
        this.populateLastSeen();
        this.populateHeatmap();
        break;
      case ProfilerOverviewHeaderMapOptions.FEEDMAP:
        this.markersChange.next(
          Object.values(this.markerCollection.markers.feed)
        );
        break;
    }

    this.setInitialLoadCount();
    this.populatePointsOfIntrests();
    this.emitEmptyData();
    this.changeDetectorRef.markForCheck();
  }

  private setSources(items: IconMarker[]): void {
    if (!Array.isArray(items)) {
      return;
    }

    const itemSources = items.map(
      (marker) => (marker as ProfilerIconMarker).source
    );
    const placesOfInterestSources = Object.values(
      this.markerCollection.markers.placesOfInterest
    ).map((marker) => (marker as ProfilerIconMarker).source);

    this.profilerMapService.setSources([
      ...itemSources,
      ...placesOfInterestSources,
    ]);
  }

  private setFilterChangeListener(): void {
    this.subscription = this.profilerMapService.activeFilters.subscribe(
      (filters: string[]) => {
        if (!filters) {
          this.filters = [...Object.values(DataSource)];
        } else {
          this.filters = filters;
        }

        this.setMarkers();
      }
    );
  }

  private getFilteredMarkers(markers: IconMarker[]): IconMarker[] {
    const somedusMarkers = markers.filter(
      (marker) => marker['source'] === DataSource.Somedus
    );
    const geolocationMarkers = markers.filter(
      (marker) => marker['source'] === DataSource.GeoLocation
    );

    let mapMarkers = [...somedusMarkers, ...geolocationMarkers, ...markers];
    mapMarkers = uniqWith(
      mapMarkers,
      (locationA, locationB) =>
        locationA.lat === locationB.lat && locationA.lng === locationB.lng
    );

    return mapMarkers.filter((marker) => {
      const profilerMarker = marker as ProfilerIconMarker;
      if (!profilerMarker?.source && this.filters.includes(DataSource.Other)) {
        return true;
      }

      return this.filters.includes(profilerMarker.source);
    });
  }

  setImsiLocations(): void {
    if (!Array.isArray(this.target?.imsis)) {
      return;
    }

    this.target.imsis.forEach((imsi) => {
      if (!imsi) {
        return;
      }

      this.subscription = this.registerDataSource(
        this.queryService.getTimelineAndHeatmapQueries(
          null,
          imsi,
          null,
          SortMode.DESC
        )
      ).subscribe((queries: Query[]) => {
        if (!queries?.length) {
          return;
        }

        this.targetLocations.push(...queries);

        this.setTargetLocations();
        this.setMarkers();
      });
    });
  }

  setTelnoLocations(): void {
    if (!Array.isArray(this.target?.telnos)) {
      return;
    }

    this.target.telnos.forEach((telno) => {
      if (!telno) {
        return;
      }

      this.subscription = this.registerDataSource(
        this.queryService.getTimelineAndHeatmapQueries(
          telno,
          null,
          null,
          SortMode.DESC
        )
      ).subscribe((queries: Query[]) => {
        if (!queries?.length) {
          return;
        }

        this.targetLocations.push(...queries);

        this.setTargetLocations();
        this.setMarkers();
      });
    });
  }

  setNearbyLocationProfile() {
    this.markerCollection.markers.nearbyProfileLocations = {};
    this.subscription = this.nearbyLocationsService
      .getNearbyLocationProfileByTargetId(this.target.id)
      .pipe(
        mergeMap((profiles: NearbyLocationsProfile[]) =>
          transformSnakeToCamel(profiles)
        )
      )
      .subscribe(
        (profile: NearbyLocationsProfile) => {
          let popUpText: string = '';
          const fullName = `${profile.firstName || ''} ${
            profile.lastName || ''
          }`;
          if (profile.phone) {
            popUpText = profile.phone;
          } else if (profile.username) {
            popUpText = profile.username;
          } else if (fullName.trim().length) {
            popUpText = fullName;
          }

          const marker = new ProfilerIconMarker({
            id: `${profile.userId}-${randomString()}`,
            lat: profile.latitude,
            lng: profile.longitude,
            extendMapBounds: false,
            popupHTML: `${popUpText}<br>${format(
              new Date(profile.validAt),
              'dd.MM.yyyy HH:mm'
            )}`,
            isPopupWindowOpen: true,
            date: new Date(profile.validAt),
            source: DataSource.Telegram,
            iconUrl: MarkerIcon.Grey,
            zIndex: 100,
          });
          this.markerCollection.addMarker(
            MarkerCollectionOptions.NEARBYPROFILELOCATIONS,
            [marker]
          );
        },
        () => {},
        () => {
          this.setMarkers();
        }
      );
  }

  showLocationProbability() {
    if (!this.target?.telnos?.length) {
      return;
    }

    this.userBehaviorService
      .userBehavior('osint_map_overview_location_probability')
      .subscribe();
    if (this.availableAnalyticsData) {
      this.analyticsService.showDayTimeSelector.next(true);
    } else if (!this.scheduleDialogOpened && !this.isLocatingDisabled) {
      this.scheduleDialogOpened = true;

      const dialogRef = this.dialog.open(ImportDataRequestDialogComponent, {
        height: '250px',
        width: '500px',
        panelClass: this.getThemeClassName(),
        data: this.target,
      });

      this.subscription = dialogRef.afterClosed().subscribe(() => {
        this.scheduleDialogOpened = false;
      });
    }
  }

  addCustomAddresses() {
    const targetAdresses: string[] = Object.keys(this.socialAddresses);
    if (targetAdresses.length) {
      this.registerDataSource(
        this.analyticsService.getLatLongByAddress(
          this.profilerMapDataService.contryCodeToCountryName(targetAdresses)
        )
      ).subscribe((result: any[]) => {
        if (result.length) {
          const profileLocationMarkers = [];
          result.forEach((item) => {
            const marker = new ProfilerIconMarker({
              id: `home-address${item.latitude}+${item.latitude}`,
              lat: item.latitude,
              lng: item.longitude,
              popupHTML: `${item.display}`,
              isPopupWindowOpen: true,
              source: DataSource.GeoLocation,
              iconUrl: `/assets/static/images/analytics/pin_${
                this.socialAddresses[item.display] || 'home'
              }.svg`,
            });
            profileLocationMarkers.push(marker);
          });
          this.markerCollection.addMarker(
            MarkerCollectionOptions.PLACESOFINTEREST,
            profileLocationMarkers
          );
          this.setMarkers();
        }
      });
    }
  }

  @debounce(500)
  setTargetLocations() {
    this.userBehaviorService
      .userBehavior('osint_map_overview_timeline')
      .subscribe();

    this.markerCollection.markers.targetLocations = {};
    const targetLocationMarkers = [];
    this.targetLocations
      .sort((a, b) => {
        return (
          new Date(a.locationReceivedAt).getTime() -
          new Date(b.locationReceivedAt).getTime()
        );
      })
      .forEach((locationQuery) => {
        if (
          locationQuery &&
          locationQuery?.location &&
          locationQuery?.location?.coordinates.length
        ) {
          let target: string;
          const markerPopUpDate: string = format(
            new Date(locationQuery.locationReceivedAt),
            'dd.MM.yyyy HH:mm'
          );

          if (!locationQuery.callLogPopUp) {
            target =
              locationQuery?.queryArgs?.telno || locationQuery?.queryArgs?.imsi;
          }
          const sectors =
            this.markerSectorService.getSectorsFromQuery(locationQuery);
          // This is needed untill MapModuleV2 is present in the mapHelperService
          const circles = this.mapHelperService.showQueryAccuracyCircles(
            locationQuery as Query
          ) as Circle[];
          const marker = new TargetLocationIconMarker({
            id: locationQuery.id,
            lat: locationQuery.location.coordinates[1],
            lng: locationQuery.location.coordinates[0],
            popupHTML:
              locationQuery.callLogPopUp || `${target}<br>${markerPopUpDate}`,
            isPopupWindowOpen: true,
            date: new Date(locationQuery.locationReceivedAt),
            sectors: sectors,
            circles: circles,
            source: DataSource.GeoLocation,
            iconUrl: MarkerIcon.Grey,
            zIndex: 100,
            extendMapBounds: !!sectors?.length && !!circles?.length,
          });
          targetLocationMarkers.push(marker);
        }
      });
    this.markerCollection.addMarker(
      MarkerCollectionOptions.TARGETLOCATIONS,
      targetLocationMarkers
    );
    this.locations = [...this.targetLocations];

    this.setMarkers();
  }

  private setInitialLoadCount() {
    const locations = this.markerCollection.all.value;
    const overviewMapInitialMarkerNumber =
      this.appConfigService.getConfigVariable('overviewMapInitialMarkerNumber');

    if (
      locations.length > 0 &&
      locations.length < overviewMapInitialMarkerNumber
    ) {
      this.initialMarkersNumber = locations.length;
    } else {
      this.initialMarkersNumber = overviewMapInitialMarkerNumber;
    }
  }

  calculateDataAvailability() {
    if (!this.target?.telnos?.length) {
      return;
    }

    const observables = this.target.telnos
      .filter((telno) => !!telno)
      .map((telno) => this.analyticsService.getDataAvailability(telno));

    this.subscription = forkJoin(observables).subscribe((results) => {
      this.availableAnalyticsData = results.some(
        (result) => result.tenantPercentage >= IMPORT_DATA_THRESHOLD_PER
      );
    });
  }

  onCircleDrawn(circle: Circle) {
    this.openAreaOfInterestQueryConfirmation(circle);
  }

  openAreaOfInterestQueryConfirmation(circle: Circle) {
    Swal.fire({
      title: this.translationService.translate('Area of Interest Query'),
      text: this.translationService.translate(
        'Do you wish to redraw the area or submit the query?'
      ),
      showCancelButton: true,
      html: `<div class="quick-aoi-radio-buttons">
        <input type="radio" name="aoi-position" value="inside">${this.translationService.translate(
          'Inside of area'
        )}
        <input type="radio" name="aoi-position" value="outside" checked>${this.translationService.translate(
          'Outside of area'
        )}
      </div>`,
      confirmButtonText: this.translationService.translate('Submit'),
      cancelButtonText: this.translationService.translate('Redraw'),
    }).then((result) => {
      if (result.value) {
        this.quickAreaOfInterestQuery(circle);
      }
    });
  }

  private quickAreaOfInterestQuery(circle: Circle) {
    const position = document
      .querySelectorAll('input[name="aoi-position"]:checked')[0]
      .getAttribute('value');
    const outside = position !== 'inside';

    const data = {
      queryType: this.isProfilerOverview
        ? QueryType.FENCED_QUARANTINE
        : QueryType.FENCED_AREA,
      frequency:
        this.isProfilerOverview && environment.enabledOnlyForDemo
          ? IntervalMinutes.MAX
          : IntervalMinutes.HIGH,
      startAt: this.queryService.getQuickScheduleDates(
        this.isProfilerOverview
      )[0],
      endAt: this.queryService.getQuickScheduleDates(
        this.isProfilerOverview
      )[1],
      outside,
      radius: (circle.radiusMeters / 1000).toFixed(3),
      lng: circle.center.lng,
      lat: circle.center.lat,
    };

    if (!this.isProfilerOverview) {
      this.queryService.numbersToBeQueried.next([
        this.queryService.onQuerySelection.getValue().queryArgs,
      ]);
    }

    const areOfInterestQuerySubscription = this.queryService
      .createAreaOfInterestQuery(data)
      .subscribe(
        () => {
          this.showMessage(
            this.translationService.translate('Query created successfully!')
          );

          this.buttons = this.buttons.filter(
            (button) => button.id !== 'circle'
          );
          this.enableDrawing = DrawMode.None;

          this.circles = [circle];
          this.getQuarantineGeofence();
          this.updateActiveTasks(this.queryService.onQuerySelection.getValue());
          areOfInterestQuerySubscription.unsubscribe();
        },
        () => {
          this.showMessage(
            this.translationService.translate('Query has not been created')
          );
          areOfInterestQuerySubscription.unsubscribe();
        }
      );
  }

  getQuarantineGeofence() {
    this.targetService
      .getTargetQuarantineGeofence(this.target.id)
      .subscribe((data) => {
        this.targetService.quarantineGeofenceData.next(data);
        if (data && data.payload && data.payload.area) {
          const area: Circle = {
            extendMapBounds: !this.markersChange.getValue().length,
            center: {
              lat: data.payload.area.location.coordinates[1],
              lng: data.payload.area.location.coordinates[0],
            },
            radiusMeters: data.payload.area.radiusMeters,
            id: uuidv4(),
          };
          this.circles = [area];
        }
      });
  }

  private updateActiveTasks(query): void {
    this.queryService.getTasks('false').subscribe((tasks) => {
      this.activeGeofences.telno = {};
      this.activeGeofences.imsi = {};
      tasks.forEach((task) => {
        const { telno: taskTelno, imsi: taskImsi } = task.payload.queryArgs;
        const scheduleType = task.scheduleType;
        if (taskTelno) {
          if (scheduleType === QueryType.FENCED_AREA.toLowerCase()) {
            this.activeGeofences.telno[taskTelno] = task;
          }
        }
        if (taskImsi) {
          if (scheduleType === QueryType.FENCED_AREA.toLowerCase()) {
            this.activeGeofences.imsi[taskImsi] = task;
          }
        }
      });
      if (!query) {
        return;
      }
      const { telno, imsi } = query.queryArgs;
      const activeGeofence = telno
        ? this.activeGeofences.telno[telno]
        : this.activeGeofences.imsi[imsi];

      this.geofencingIsEnabled = !!activeGeofence;
    });
  }

  maximizeMap() {
    this.maximizedMap = !this.maximizedMap;
    this.maximizeClick.emit();
  }

  private emitEmptyData(): void {
    const locations = Object.values(this.markerCollection?.all);
    const placesOfInterest = Object.values(
      this.markerCollection?.markers.placesOfInterest
    );

    this.emptyData.emit({
      [ProfilerOverviewHeaderMapOptions.TIMELINE]: locations.length < 1,
      [ProfilerOverviewHeaderMapOptions.HEATMAP]: locations.length < 1,
      [ProfilerOverviewHeaderMapOptions.POI]: placesOfInterest.length === 0,
      [ProfilerOverviewHeaderMapOptions.PREDICTEDLOCATIONS]: !this.target,
    });
  }

  private getThemeClassName(): string {
    switch (this.theme) {
      case 'GEOLOC':
        return 'geoloc-theme';
      case 'WHITE':
        return 'white-theme';
      default:
        return '';
    }
  }

  private setMaximizeListener() {
    this.subscription = this.profilerService.maximizedMap.subscribe(
      (value: boolean) => {
        this.maximizedMap = value;
      }
    );
  }

  protected resetMap(): void {
    this.markersChange.next([]);
    this.polyLines = [];
    this.polygons = [];
    this.circles = [];
    this.headMapPoints = [];
    this.renderTimeline = false;
  }

  @debounce(500)
  private populateLastSeen(): void {
    const markerCollection = this.markerCollection.all.value;
    const filteredMarkers = this.getFilteredMarkers(markerCollection);
    const filteredMarker = filteredMarkers[
      filteredMarkers.length - 1
    ] as TargetLocationIconMarker;
    this.setSources(markerCollection);

    if (filteredMarker) {
      filteredMarker.isPopupWindowOpen = !!filteredMarker.isPopupWindowOpen;
      const sectors = filteredMarker?.sectors;
      const circles = filteredMarker?.circles;

      this.polygons = sectors ? [...sectors] : null;
      this.circles = circles ? [...circles] : null;
      this.markersChange.next([filteredMarker]);
    }
  }

  private populateHeatmap(): void {
    this.heatmapEnabled = true;
    this.showPlaybackControls = true;
    this.renderTimeline = true;

    const markerCollection = Object.values(
      this.markerCollection.markers.filteredTimeline
    );
    const filteredMarkes = this.getFilteredMarkers(markerCollection);

    this.setSources(this.markerCollection.all.value);

    this.headMapPoints = [...filteredMarkes];
  }

  private populateTimeline(): void {
    this.showPlaybackControls = true;
    this.renderTimeline = true;
    const markerCollection = Object.values(
      this.markerCollection.markers.filteredTimeline
    );
    const filteredMarkes = this.getFilteredMarkers(markerCollection);
    filteredMarkes.forEach((marker) => {
      if (marker instanceof TargetLocationIconMarker) {
        const { sectors, circles } = marker;
        sectors ? this.polygons.push(...sectors) : null;
        circles ? this.circles.push(head(circles)) : null;
      }
      marker.isPopupWindowOpen = false;
    });

    this.setSources(this.markerCollection.all.value);

    this.markersChange.next(
      this.setMarkerPopupVisibility([...filteredMarkes], false)
    );
    const markers = this.markersChange.getValue();
    const linePoints = markers.map((marker) => {
      return {
        lat: marker.lat,
        lng: marker.lng,
      };
    });

    if (markers.length > 1) {
      this.polyLines = [
        new Polyline({
          points: linePoints,
          strokeColor: '#008df6',
          strokeWeight: 1,
        }),
      ];
    }
  }

  private populatePredictedLocations(): void {
    if (
      !this.markerCollection.markers.cdrProbabilities.length &&
      !this.markerCollection.markers.geolocationProbabilities.length &&
      !this.predictedLocationTimeFilters
    ) {
      this.showLocationProbability();
      return;
    }
    const allProbabilities = [
      ...Object.values(this.markerCollection.markers.cdrProbabilities),
      ...Object.values(this.markerCollection.markers.geolocationProbabilities),
    ];
    this.setSources(allProbabilities);

    const filteredMarkes = this.getFilteredMarkers(allProbabilities);

    this.markersChange.next([...filteredMarkes]);
  }

  private populatePointsOfIntrests(): void {
    if (!this.placesOfInterestVisibility) {
      return;
    }

    const filteredMarkes = this.getFilteredMarkers(
      Object.values(this.markerCollection.markers.placesOfInterest)
    );

    this.markersChange.next([
      ...this.markersChange.getValue(),
      ...filteredMarkes,
    ]);
  }

  private setMarkerPopupVisibility(
    markers: IconMarker[],
    visibility: boolean
  ): IconMarker[] {
    return markers.map((marker) => {
      marker.isPopupWindowOpen = visibility;

      return marker;
    });
  }
}

export const PLAY_BACK_MARKER_ID: string = 'playback_marker';
