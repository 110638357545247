import { Injectable } from '@angular/core';
import { Link, Node } from '@trg-ui/link-analysis';
import { BehaviorSubject, Subject } from 'rxjs';
import { TargetItem } from 'src/app/shared/models/target-item.model';

@Injectable({
  providedIn: 'root',
})
export class InvestigationHelperService {
  public pendingActionIds: BehaviorSubject<{ [key: string]: string }> =
    new BehaviorSubject({});
  public pendingActionIds$ = this.pendingActionIds.asObservable();
  completedOsintId: Subject<string> = new Subject();

  public currentGraphData: BehaviorSubject<{ [key: string]: Node | Link }> =
    new BehaviorSubject({});

  constructor() {}

  public updatePendingNodeActionsIds(key: string, value: string) {
    let ids = this.pendingActionIds.getValue();
    ids[key] = value;
    this.pendingActionIds.next({ ...ids });
  }

  public checkIfPendingQueryExistsAndRemove(id: string): boolean {
    const pendingIds = this.pendingActionIds.getValue();
    if (pendingIds[id]) {
      delete pendingIds[id];
      this.pendingActionIds.next(pendingIds);
      return true;
    }
    return false;
  }

  public getTargetFromRelatedPersonNode(
    identifier: string
  ): TargetItem | undefined {
    return Object.values(this.currentGraphData.getValue())
      .filter(
        (element) => element.type === 'node' && element.d.type === 'Person'
      )
      .find(
        (node) =>
          node.d['msisdns'].includes(identifier) ||
          node.d['imsis'].includes(identifier) ||
          node.d['imeis'].includes(identifier) ||
          node.d['id'] === identifier
      )?.d?.target;
  }
}
