import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { BaseService } from 'src/app/services/base.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { FtaNotificationState } from '../../visual-investigation/models/text-analysis-uploader.model';
import { FtaNotificationComponent } from '../components/fta-notification/fta-notification.component';
import { InvestigationNavigatedFrom } from '../../search-intel/models/intel-results-view.model';

@Injectable({
  providedIn: 'root',
})
export class FtaNotificationService extends BaseService {
  activeToasts: {
    [correlationId: string]: ActiveToast<FtaNotificationComponent>;
  } = {};
  constructor(
    private toastr: ToastrService,
    protected router: Router,
    protected snackBar: MatSnackBar,
    private translationService: TranslationService
  ) {
    super(router, snackBar);
  }

  addToast(
    filename: string,
    fileId: string,
    correlationId: string,
    investigationId: string
  ) {
    const toast = this.toastr.success(null, null, {
      toastComponent: FtaNotificationComponent,
      positionClass: 'toast-bottom-right',
    });
    toast.toastRef.componentInstance.toast = toast;
    toast.toastRef.componentInstance.updateNotificationData(filename, fileId);
    this.activeToasts[correlationId] = toast;
    this.activeToasts[
      correlationId
    ].toastRef.componentInstance.investigationId = investigationId;
  }

  removeToast(correlationId: string) {
    this.activeToasts[correlationId]?.toastRef.close();
  }

  changeStateOfNotificationToaster(
    correlationId: string,
    newState: FtaNotificationState,
    investigationId: string,
    filename?: string,
    fileId?: string
  ) {
    const shouldAddToast =
      !this.activeToasts[correlationId] && filename && fileId;
    if (shouldAddToast) {
      this.addToast(filename, fileId, correlationId, investigationId);
    }

    if (newState === FtaNotificationState.FAILED) {
      this.showMessage(
        this.translationService.translate(
          'Something went wrong with File Text Analysis'
        )
      );
    }
    this.activeToasts[correlationId].toastRef.componentInstance.changeStateTo(
      newState
    );
  }

  navigateToInvestigation(investigationId: string) {
    this.router.navigate(['case-investigation'], {
      queryParams: {
        investigationId: investigationId,
        returnUrl: InvestigationNavigatedFrom.INVESTIGATION,
      },
    });
  }
}
