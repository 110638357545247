import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notAvailable',
})
export class NotAvailablePipe implements PipeTransform {
  transform(value: any, text: string = 'n/a'): any {
    if (
      typeof value === 'undefined' ||
      value === '' ||
      value === 'null' ||
      value == null ||
      value == 0
    ) {
      return text;
    }

    return value;
  }
}
