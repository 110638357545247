import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ActiveToast, ComponentType, ToastrService } from 'ngx-toastr';
import { DrawerComponent } from './drawer.component';
import { DrawerOptions, DrawerState } from './drawer.model';
import { DOCUMENT } from '@angular/common';
import { LayoutService } from '../services/layout.service';

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private toastContainer: HTMLElement | null | undefined;

  private renderer2: Renderer2;

  private isMobile = false;

  private readonly mobileToastClass: string = 'mobile-drawer-position';

  private readonly defaultToastClass: string = 'toast-bottom-right';

  constructor(
    private readonly toastr: ToastrService,
    private readonly rendererFactory: RendererFactory2,
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly layoutService: LayoutService
  ) {
    this.renderer2 = this.rendererFactory.createRenderer(null, null);
    this.createLayoutServiceSubscription();
  }
  public show(
    component: ComponentType<unknown>,
    options: DrawerOptions
  ): ActiveToast<DrawerComponent> {
    const toastClass: string = this.isMobile
      ? this.mobileToastClass
      : this.defaultToastClass;
    const toast = this.toastr.success(undefined, undefined, {
      toastComponent: DrawerComponent,
      positionClass: options?.positionClass || toastClass,
    });
    toast.toastRef.componentInstance.options = options;
    toast.toastRef.componentInstance.component = component;
    toast.toastRef.componentInstance.expanded$.subscribe((): void => {
      options.onExpand();
    });
    toast.toastRef.componentInstance.closed$.subscribe((): void => {
      this.renderer2.removeClass(
        this.toastContainer,
        'toast-container-bottom-pushed'
      );
      options.onClose();
      toast.toastRef.close();
    });
    toast.toastRef.componentInstance.stateToggled$.subscribe(
      (state: DrawerState): void => {
        options.onStateToggled(state);
      }
    );

    this.getToastContainer();
    if (this.toastContainer) {
      this.replaceDrawerToBottom();
    }
    return toast;
  }

  private replaceDrawerToBottom(): void {
    const drawerElement: Element | undefined = this.getDrawerElement();

    if (!drawerElement) {
      return;
    }

    this.renderer2.removeChild(this.toastContainer, drawerElement);
    this.renderer2.appendChild(this.toastContainer, drawerElement);
    this.renderer2.addClass(
      this.toastContainer,
      'toast-container-bottom-pushed'
    );
  }

  private getToastContainer(): void {
    if (!this.toastContainer) {
      this.toastContainer = this.document.getElementById('toast-container');
    }
  }

  private getDrawerElement(): Element | undefined {
    if (!this.toastContainer) {
      return;
    }

    return Array.from((this.toastContainer as HTMLElement).children).find(
      (item: Element): boolean => item.tagName === 'INTELLECTUS-DRAWER'
    );
  }

  private createLayoutServiceSubscription(): void {
    this.layoutService.isMobile$.subscribe((isMobile: boolean): void => {
      this.isMobile = isMobile;
      if (!this.getDrawerElement()) {
        return;
      }

      if (this.isMobile) {
        this.renderer2.removeClass(this.toastContainer, this.defaultToastClass);
        this.renderer2.addClass(this.toastContainer, this.mobileToastClass);
      } else {
        this.renderer2.removeClass(this.toastContainer, this.mobileToastClass);
        this.renderer2.addClass(this.toastContainer, this.defaultToastClass);
      }
    });
  }
}
