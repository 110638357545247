import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseDialogComponent } from '../../classes/base-dialog.component';
import { Query } from '../../models/query-item.model';

export enum ExportPeerAction {
  GET_INFO_AND_LOCATE = 'extractPeerInformationAndLocate',
  GET_INFO = 'extractPeerInformation',
}

export interface ExtractPeerActionResult {
  selection: ExportPeerAction;
  action: ExportPeerAction;
}

@Component({
  selector: 'app-extract-peer-dialog',
  templateUrl: './extract-peer-dialog.component.html',
  styleUrls: ['./extract-peer-dialog.component.scss'],
})
export class ExtractPeerDialogComponent
  extends BaseDialogComponent
  implements OnInit
{
  constructor(
    public dialogRef: MatDialogRef<ExtractPeerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      query: Query;
      extractPeerInformationAndLocate: boolean;
      extractPeerInformation: boolean;
    },
    router: Router
  ) {
    super(dialogRef, router);
  }

  counterInterval: NodeJS.Timer;
  popUpTimer: NodeJS.Timer;
  counterValue: number = 60;
  progress: number = 0;
  availableActions = {
    getInfoAndLocate: ExportPeerAction.GET_INFO_AND_LOCATE,
    getInfo: ExportPeerAction.GET_INFO,
  };

  ngOnInit() {
    this.startCountdown();
    this.handleCounterValue();
  }

  onSuccess() {
    this.dialog.close();
  }

  private handleCounterValue(): void {
    this.counterInterval = setInterval(() => {
      this.counterValue = this.counterValue - 1;

      this.progress = (this.counterValue / 60) * 100;
    }, 1000);
  }

  private startCountdown(): void {
    this.popUpTimer = setTimeout(() => {
      clearInterval(this.counterInterval);
      this.dialog.close();
    }, this.counterValue * 1000);
  }

  onSuccessAction(action: ExportPeerAction) {
    let localSelection = null;
    if (this.data.extractPeerInformation) {
      localSelection = ExportPeerAction.GET_INFO;
    }
    if (this.data.extractPeerInformationAndLocate) {
      localSelection = ExportPeerAction.GET_INFO_AND_LOCATE;
    }

    this.dialog.close(<ExtractPeerActionResult>{
      selection: localSelection,
      action,
    });
  }
}
