import { Injectable } from '@angular/core';
import { CdrTarget } from '@trg-commons/data-models-ts';
import {
  AdIdLocationHistoryDto,
  EventChannel,
} from '@trg-commons/gio-data-models-ts';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CallLogsApiService } from './call-logs-api.service';
import { CallLogsMapStore } from './calls-logs-map.store';

@Injectable({ providedIn: 'any' })
export class CallLogsService {
  constructor(
    private clApiService: CallLogsApiService,
    private mapStore: CallLogsMapStore
  ) {}

  createLocationHistoryRequest(
    request: AdIdLocationHistoryDto
  ): Observable<CdrTarget[]> {
    return this.createLocationHistoryRequestWithStore(request, this.mapStore);
  }

  createLocationHistoryRequestWithStore(
    request: AdIdLocationHistoryDto,
    clMapStore?: CallLogsMapStore
  ): Observable<CdrTarget[]> {
    let mapStore = clMapStore ? clMapStore : this.mapStore;
    const requestHash = this.clApiService.hashRequest(request);
    if (
      this.clApiService.prevRequestHash === requestHash &&
      mapStore.getValue()?.length
    ) {
      return new Observable((observable) => {
        if (this.clApiService.listeners[this.clApiService.prevCorrelationId]) {
          this.clApiService.listeners[
            this.clApiService.prevCorrelationId
          ].subscribe({
            complete: () => {
              observable.next(mapStore.getValue());
              observable.complete();
            },
          });

          return;
        }

        observable.next(mapStore.getValue());
        observable.complete();
      });
    }

    this.clApiService.prevRequestHash = requestHash;

    this.mapStore.reset();

    if (request.imeis?.length) {
      mapStore.setRequestParameters('imeis', request.imeis);
    }

    mapStore.queryDates = { from: request.startTime, to: request.endTime };
    mapStore.setUpdating(true);

    return this.clApiService.locationHistoryRequest(request).pipe(
      filter((event) => !!event),
      tap((event) => {
        if (event.channel === EventChannel.CdrStream) {
          mapStore.append(event.body);
        } else if (
          event.channel === EventChannel.OperationRequestsStreamEnded
        ) {
          mapStore.setUpdating(false);
          mapStore.setStreamEndEvent(true);
          this.clApiService.listeners.get(event.correlationId).complete();
        }
      }),
      map((event) => event.body)
    );
  }
}
