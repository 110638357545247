import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/base/base.component';
import { UserService } from 'src/app/services/user/user.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {
  resetPasswordForm: FormGroup;

  constructor(
    private translationService: TranslationService,
    protected userService: UserService,
    public dialogRef: MatDialogRef<ResetPasswordComponent>
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.resetPasswordForm = new FormGroup({
      currentPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmNewPassword: new FormControl('', Validators.required),
    });
  }

  onResetPassword() {
    if (this.resetPasswordForm.valid) {
      if (
        this.resetPasswordForm.value.newPassword ===
        this.resetPasswordForm.value.confirmNewPassword
      ) {
        this.userService
          .resetPassword(
            this.resetPasswordForm.value.currentPassword,
            this.resetPasswordForm.value.newPassword
          )
          .subscribe(
            () => {
              this.showMessage(this.translationService.translate('Saved!'));
              this.resetPasswordForm.reset();
              this.dialogRef.close();
            },
            () =>
              this.showMessage(
                this.translationService.translate('Could not save')
              )
          );
      } else {
        this.showMessage(
          this.translationService.translate(`Passwords don't match.`)
        );
      }
    } else {
      this.showMessage(
        this.translationService.translate(
          'Invalid values. Please fill in all fields.'
        )
      );
    }
  }

  resetForm() {
    this.resetPasswordForm.reset();
  }
}
