import { Injectable } from '@angular/core';
import { Chat } from 'datalayer/models/platform-models/chat/chat';
import {
  ChatType,
  CommunityInfo,
  DataSource,
  EntityType,
} from 'datalayer/models/platform-models/enums';
import { Group } from 'datalayer/models/social';
import { ChatService } from 'datalayer/services/chat/chat.service';
import { GroupService } from 'datalayer/services/social/group/group.service';
import { Observable, map } from 'rxjs';
import { RequestOptions } from 'src/app/modules/data-layer/services/base/request-options.interface';
import { ImageService } from 'src/app/services/image/image.service';
import { Platform } from 'src/app/shared/schemas/common/platforms';

@Injectable()
export class ChatHelper {
  constructor(
    private imageService: ImageService,
    private chatService: ChatService,
    private groupService: GroupService
  ) {}

  getChatFilters(targetId: string): RequestOptions {
    return {
      filters: {
        source: [DataSource.Somedus],
        targetId: targetId,
        type: EntityType.Chat,
        limit: 100,
      },
    };
  }

  getChatFiltersNoLimits(targetId: string): RequestOptions {
    return {
      filters: {
        source: [DataSource.Somedus],
        targetId: targetId,
        type: EntityType.Chat,
      },
    };
  }

  getGroupFilters(targetId: string): RequestOptions {
    return {
      filters: {
        source: [DataSource.Facebook],
        targetId: targetId,
        type: EntityType.Group,
        limit: 15,
      },
    };
  }

  getFacebookGroups(targetId: string): Observable<Group[]> {
    const groupFilters: RequestOptions = this.getGroupFilters(targetId);
    return this.groupService.getAll(groupFilters).pipe(
      map((result: { [key: string]: Group }) => Object.values(result)),
      map(this.addImages.bind(this))
    );
  }

  getTelegramChats(targetId: string): Observable<Chat[]> {
    const chatFilters = this.getChatFilters(targetId);
    return this.chatService
      .getAll(chatFilters)
      .pipe(map((result: { [key: string]: Chat }) => Object.values(result)));
  }

  getSMGroup(chat: Chat, targetId: string): Group {
    return {
      platform: Platform.TELEGRAM,
      chatType: chat.chatType,
      name: chat.name,
      isPrivate: this.groupIsPrivate(chat.chatType),
      targetId: targetId,
      communityInfo: this.getCommunityInformation(chat),
    };
  }

  groupIsPrivate(chatType: string): boolean {
    return !!chatType?.includes('private');
  }

  getCommunityInformation(chat: Chat): CommunityInfo {
    let messagesCount = chat?.messages?.length || 0;
    let membersCount = chat?.members?.length || 0;
    let activeMembersCount = new Set(
      chat.messages.map((message) => message.from)
    ).size;
    let mediaCount = chat.messages.filter((message) => message.file).length;
    return { messagesCount, activeMembersCount, mediaCount, membersCount };
  }

  addImages(profiles: Group[]): Group[] {
    return profiles.reduce((base, item) => {
      const image =
        item.image && Object.keys(item.image).length
          ? item.image.url
          : 'assets/static/images/user.svg';
      const imageUrl = <string>this.imageService.getPhotoUrl(image, true);
      const profile = {
        ...item,
        imageUrl,
      };
      base.push(profile);
      return base;
    }, []);
  }

  createTelegramGroupsFromChats(
    targetId: string,
    telegramChats: Chat[]
  ): Group[] {
    const telegramGroups: Group[] = [];
    for (const chat of telegramChats) {
      const group = this.getSMGroup(chat, targetId);
      if (
        [
          ChatType.PUBLIC_SUPERGROUP,
          ChatType.PRIVATE_GROUP,
          ChatType.PUBLIC_CHANNEL,
        ].includes(chat?.chatType)
      ) {
        telegramGroups.push(group);
      }
    }

    return telegramGroups;
  }
}
