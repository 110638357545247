import { Injectable } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { Observable, catchError, of } from 'rxjs';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Country } from '../../../app/shared/models/country.model';
import { Region } from '../../../app/shared/models/region.model';

@Injectable({
  providedIn: 'root',
})
export class InvestigationFiltersHelpersService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
  }

  public fetchCountries(msisdns: string[]): Observable<Country[]> {
    const msisdnsParams = msisdns
      .map((msisdn) => `msisdns=${msisdn.replace('+', '%2B')}`)
      .join('&');

    const url = `${this.proxyApiUrl}/file-text-analysis/countries/?${msisdnsParams}`;

    return this.httpClient
      .get<Country[]>(url)
      .pipe(catchError((error) => of(error)));
  }

  public fetchRegions(msisdns: string[]): Observable<Region[]> {
    const msisdnsParams = msisdns
      .map((msisdn) => `msisdns=${msisdn.replace('+', '%2B')}`)
      .join('&');

    const url = `${this.proxyApiUrl}/file-text-analysis/regions/?${msisdnsParams}`;

    return this.httpClient
      .get<Region[]>(url)
      .pipe(catchError((error) => of(error)));
  }
}
