import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, tap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  DEFAULT_SITE_SETTINGS,
  Language,
  LanguageFlagsMap,
  LanguageOptions,
  SiteSettings,
} from '../shared/models/site-settings.model';
import { camelToSnakeCase, transformSnakeToCamel } from '../shared/util/helper';
import { AuthService } from './authentication/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SiteSettingsService {
  private readonly BASE_URL: string = environment.serverAPIUri;
  private store$: ReplaySubject<SiteSettings> = new ReplaySubject<SiteSettings>(
    1
  );

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.authService.isAuthenticated.subscribe(() =>
      this.getSiteSettings().subscribe()
    );
  }

  public getSiteSettings(): Observable<SiteSettings> {
    return this.httpClient
      .get<{ result: SiteSettings }>(`${this.BASE_URL}/site-settings`)
      .pipe(
        map(({ result }) => transformSnakeToCamel(result)),
        catchError(() => of(DEFAULT_SITE_SETTINGS)),
        map((result: SiteSettings) =>
          Object.keys(result).length ? result : DEFAULT_SITE_SETTINGS
        ),
        map((result: SiteSettings) => {
          if (!result.languages?.length) {
            result.languages = DEFAULT_SITE_SETTINGS.languages;
          }
          return result;
        }),
        tap((settings: SiteSettings) => this.setSiteSettings(settings))
      );
  }

  public updateSiteSettings(siteSettings: Partial<SiteSettings>) {
    const requestParam: object = {};
    Object.keys(siteSettings).forEach(
      (key) => (requestParam[camelToSnakeCase(key)] = siteSettings[key])
    );
    return this.httpClient
      .put<{ result: SiteSettings }>(
        `${this.BASE_URL}/site-settings`,
        requestParam
      )
      .pipe(
        map(({ result }) => transformSnakeToCamel(result)),
        catchError(() => of(DEFAULT_SITE_SETTINGS)),
        map((result: SiteSettings) =>
          Object.keys(result).length ? result : DEFAULT_SITE_SETTINGS
        ),
        tap((settings: SiteSettings) => this.setSiteSettings(settings))
      );
  }

  public setSiteSettings(settings: SiteSettings): void {
    settings.languages = this.addLanguageImage(settings.languages);
    this.store$.next(settings);
  }

  public getStoreSiteSettings(): Observable<SiteSettings> {
    return this.store$.asObservable();
  }

  public getSelectedCountry() {
    return this.getStoreSiteSettings().pipe(
      map((settings: SiteSettings) => {
        return settings.countries.find((country) => country.checked)?.value;
      })
    );
  }

  public updateSiteLanguage(languageOption: LanguageOptions): void {
    this.getStoreSiteSettings()
      .pipe(
        take(1),
        map((storeValue) => {
          return {
            ...storeValue,
            languages: storeValue.languages.map(({ value, display }) => ({
              value,
              display,
              checked: languageOption === value,
            })),
          };
        }),
        tap((updatedSiteSettingsValue) => {
          this.updateSiteSettings(updatedSiteSettingsValue)
            .pipe(take(1))
            .subscribe();
        })
      )
      .subscribe();
  }

  private addLanguageImage(languages: Language[]): Language[] {
    return languages.map((language) => ({
      ...language,
      image: LanguageFlagsMap[language.value],
    }));
  }
}
