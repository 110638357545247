import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  IntelSearchTrackerService,
  searchIsComplete,
} from '../../../services/intel-search-tracker/intel-search-tracker.service';
import { IntelSearchMapByID } from '../../../services/intel-search-tracker/type/intel-search-by-id';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  FontAwesomeFamily,
  FontAwesomeIcon,
  LayoutService,
} from '@fe-platform/shared-ui/intellectus';
import { Router } from '@angular/router';
import { RedirectionRoutes } from 'src/app/services/authentication/types';
import { IntelSearchState } from 'src/app/services/intel-search-tracker/type/intel-search-state';

@UntilDestroy()
@Component({
  selector: 'app-intel-search-manager',
  templateUrl: './intel-search-manager.component.html',
  styleUrls: ['./intel-search-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntelSearchManagerComponent implements OnInit, AfterViewInit {
  public state: Record<string, IntelSearchState>;

  public searchIDs: string[] = [];

  public completedSearches: Record<string, boolean> = {};

  public isMobileDevice: boolean;

  protected readonly faChevronRight: FontAwesomeIcon = {
    name: 'chevron-right',
    family: FontAwesomeFamily.SOLID,
  };

  private isMobile: boolean = false;

  constructor(
    private readonly intelSearchTrackerService: IntelSearchTrackerService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly layoutService: LayoutService
  ) {}

  public ngOnInit(): void {
    this.intelSearchTrackerService
      .selectSearch()
      .pipe(untilDestroyed(this))
      .subscribe((state: IntelSearchMapByID): void => {
        this.state = this.transformToState(state);
        this.searchIDs = Object.keys(this.state);
        this.findCompletedSearches();
        this.cdRef.markForCheck();
      });

    this.layoutService.isMobile$
      .pipe(untilDestroyed(this))
      .subscribe((isMobile: boolean): void => {
        this.isMobile = isMobile;
      });
  }

  public ngAfterViewInit(): void {
    this.isMobileDevice = this.layoutService.isMobileDevice();
  }

  public onViewClickedHandler(id: string): void {
    this.intelSearchTrackerService.onViewResults(id);

    if (this.isMobile) {
      this.intelSearchTrackerService.minimizeDrawer();
    }
  }

  public onViewAllQueriesHandler(): void {
    this.router
      .navigate([{ outlets: { modal: [RedirectionRoutes.WEBINT, 'history'] } }])
      .then();

    if (this.isMobile) {
      this.intelSearchTrackerService.minimizeDrawer();
    }
  }

  private findCompletedSearches(): void {
    for (let [id, state] of Object.entries(this.state)) {
      if (searchIsComplete(state)) {
        this.completedSearches[id] = true;
      }
    }
  }

  private transformToState(
    state: IntelSearchMapByID
  ): Record<string, IntelSearchState> {
    return Array.from(state.keys()).reduce(
      (
        acc: Record<string, IntelSearchState>,
        searchID: string
      ): Record<string, IntelSearchState> => {
        const search: IntelSearchState = state.get(searchID);
        return !!search.searchText
          ? {
              ...acc,
              [searchID]: search,
            }
          : acc;
      },
      {}
    );
  }
}
