import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { isEqual } from 'lodash-es';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { CustomMatPaginatorIntl } from 'src/app/components/query-list/custom-mat-paginator-int';
import { InvestigationNavigatedFrom } from 'src/app/modules/search-intel/models/intel-results-view.model';
import { SearchIntelService } from 'src/app/modules/search-intel/services/search-intel.service';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import {
  DashboardService,
  DashboardView,
} from 'src/app/services/dashboard/dashboard.service';
import { NearbyLocationsService } from 'src/app/services/nearby-locations.service';
import { NearbyLocationsStore } from 'src/app/services/nearby-locations.store';
import {
  ExtractPeerStoreAction,
  QueryExtractPeerService,
} from 'src/app/services/query/query-extract-peer.service';
import { QueryService } from 'src/app/services/query/query.service';
import { RoleManagementService } from 'src/app/services/roles/role-management.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { UserBehaviorService } from 'src/app/services/user-behavior.service';
import { UserService } from 'src/app/services/user/user.service';
import { WsService } from 'src/app/services/websocket/ws.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { ExportPeerAction } from 'src/app/shared/components/extract-peer-dialog/extract-peer-dialog.component';
import { NearbyLocationSearch } from 'src/app/shared/models/nearby-locations.model';
import { LogFilters, Query } from 'src/app/shared/models/query-item.model';
import { Themes } from 'src/app/shared/models/skins.model';
import { debounce } from 'src/app/shared/util/debounce.decorator';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';
import { AdvancedGeolocationQueryConfirmationDialogComponent } from '../advanced-geolocation-query-confirmation-dialog/advanced-geolocation-query-confirmation-dialog.component';

const matomoEventsMap = {
  PDF: matomoActions.exportPDF,
  CSV: matomoActions.exportCSV,
};
@Component({
  selector: 'app-query-list',
  templateUrl: './query-list.component.html',
  styleUrls: ['./query-list.component.scss'],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
  ],
})
export class QueryListComponent
  extends BaseComponent
  implements OnInit, OnChanges, AfterViewInit
{
  exportOptions = [
    { value: 'pdf', label: 'PDF' },
    { value: 'csv', label: 'CSV' },
  ];
  tableLabels: {
    label: string;
    sort: boolean;
    filter?: boolean;
    width: string;
    class: string;
  }[];
  usernames: string[];
  isMobileResolution: boolean;
  skin;
  allQueries;
  selectedFilter;
  filters = [
    {
      name: 'Not Located',
      value: LogFilters.NOT_LOCATED,
      icon: 'no_location.png',
    },
    { name: 'Located', value: LogFilters.LOCATED, icon: 'pin.svg' },
  ];
  selectedUser;
  dateRange: Date[];
  paginator = {
    pageSize: 17,
    currentPage: 0,
    totalSize: 0,
  };
  paginatedQueries: Query[] = [];
  paginationNextPage;
  paginationPreviousPage;
  setExportType;
  currentUser;
  multiSelect = [];
  showLogMessage = false;
  isAdmin = false;
  isSupport = false;
  isPower = false;
  basicImageSrc = 'assets/static/images/';
  showQueriesLoader: Boolean = true;
  matomo = {
    actions: matomoActions,
    categories: matomoCategories,
  };
  private queryItemHeight = 39;
  private bottomActionPanelHeight = 32;

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  @Input() dateTimeRange: Date[];
  @Input() filesDownloadState: boolean = false;
  @Output() emittedSelectedQuery = new EventEmitter<Query>();
  @Output() emittedMultiselectQuery = new EventEmitter<{
    query: Query;
    new: boolean;
  }>();
  @Output() refreshLogMap = new EventEmitter<boolean>();

  public todayDate = new Date();
  formatDate = this.datepipe.transform(this.todayDate, 'dd-MM-yyyy');
  searchText = '';
  selectedQuery;
  showTelnoHistory;
  groupId;
  historyViewTitle;
  dateSort = false; // false: descending, true: ascending
  isGeolocTheme: Boolean = false;
  isWhiteTheme: Boolean = false;
  theme: Themes;
  enableAdvancedGeolocationPopup: boolean;
  selectAll: boolean = false;

  showLoader = false;
  enableInvestigationModule: boolean;

  constructor(
    public datepipe: DatePipe,
    private applicationStateService: ApplicationStateService,
    private queryService: QueryService,
    private localStorageService: LocalStorageService,
    private dashboardService: DashboardService,
    private roleManagementService: RoleManagementService,
    private usersService: UserService,
    private websocketService: WsService,
    private router: Router,
    private changeDetection: ChangeDetectorRef,
    private userBehaviorService: UserBehaviorService,
    private dialog: MatDialog,
    private angulartics2: Angulartics2,
    private appConfigService: AppConfigService,
    private nearbyLocationsStore: NearbyLocationsStore,
    private searchIntelService: SearchIntelService,
    private nearbyLocationsService: NearbyLocationsService,
    private queryExtractPeerService: QueryExtractPeerService,
    public snackBar: MatSnackBar
  ) {
    super();

    this.theme = this.appConfigService.getConfigVariable('theme');
    this.isMobileResolution =
      this.applicationStateService.getIsMobileResolution();
    this.setupTableLabels();
    this.enableAdvancedGeolocationPopup =
      this.appConfigService.getConfigVariable('enableAdvancedGeolocationPopup');
    this.enableInvestigationModule = this.appConfigService.getConfigVariable(
      'enableInvestigationModule'
    );
    this.skin = this.applicationStateService.getSkin();
  }

  onSelectAll(event: MatCheckboxChange) {
    this.selectAll = event.checked;

    if (event.checked) {
      this.paginatedQueries.forEach((query) => {
        this.addMultipleQueryGeolocationDetails(query);
        if (query.location) {
          this.onMultiselectedQuery({ query, checked: event.checked });
        }
      });
    } else {
      this.paginatedQueries.forEach((query) => {
        this.queryService.removeFromMultiqueryGeolocationDetails(query.id);
      });
    }
  }

  addMultipleQueryGeolocationDetails(query) {
    this.queryService.addToMultiqueryGeolocationDetails(query);
  }

  ngAfterViewInit() {
    this.queryService.isAutoFillQueries.next(false);
    this.queryService.clearQueryInput.next(false);

    // server-side search
    if (this.isMobileResolution) {
      return;
    }
    const eventObservables = this.getSearchFilterObservables();
    eventObservables.forEach((event) => {
      event
        .pipe(
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            this.paginator.currentPage = 0;
            // Commented code allows filter to exlude plus symbol
            // const search = this.queryService.stripPlusSign(this.searchInput.nativeElement.value).trim();
            this.searchText = encodeURIComponent(
              this.searchInput.nativeElement.value.trim()
            );
            this.filtering();
          })
        )
        .subscribe();
    });
  }

  ngOnChanges() {
    this.showQueriesLoader = this.filesDownloadState;
  }

  ngOnInit() {
    if (this.theme === 'GEOLOC') {
      this.isGeolocTheme = true;
      this.basicImageSrc = `${this.basicImageSrc}geoloc_`;
    }

    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }

    this.isAdmin = this.roleManagementService.userIsAdmin();
    this.isSupport = this.roleManagementService.userIsSupportUser();
    this.isPower = this.roleManagementService.userIsPowerUser();
    this.addUserColumn();

    const queriesPaginationData =
      this.queryService.queriesPaginationData.subscribe((data: any) => {
        if (!data.links) {
          return;
        }
        this.paginator.totalSize = data.meta.totalResults;
        this.paginationNextPage = data.meta.page + 1;
        this.paginationPreviousPage = data.meta.page - 1;
      });

    const queriesChangedSubscription =
      this.queryService.queriesChanged.subscribe((queries) => {
        if (this.groupId) {
          queries.forEach((query) => {
            if (query.groupId !== this.groupId) {
              return;
            }

            this.addQuery(query);
          });
        } else {
          this.allQueries = queries;
        }

        this._iteratePaginator();
      });

    const selectedQuerySubscription =
      this.queryService.onQuerySelection.subscribe((query: any) => {
        this.selectedQuery = query;
      });

    const refreshLogQueriesSubscription =
      this.queryService.refreshLogQueries.subscribe((flag) => {
        if (flag) {
          this.refreshQueries();
        }
      });

    if (this.isAdmin || this.isSupport || this.isPower) {
      this.subscriptions.push(
        this.usersService.getAllUsernames().subscribe((usernames: string[]) => {
          this.usernames = usernames;
        })
      );
    }

    const showTelnoHistorySubscription =
      this.queryService.showTelnoHistory.subscribe((data: any) => {
        this.showTelnoHistory = data;
        this.selectedFilter = '';
        this.selectedUser = null;
      });

    const batchQueriesSubscription = this.queryService.batchQueries.subscribe(
      (queries) => {
        this.groupId = queries && queries[0] ? queries[0].group : null;
      }
    );

    const historyItemSubscription = this.queryService.historyItem.subscribe(
      (data) => {
        this.historyViewTitle = data.target || data.telno || data.imsi;
      }
    );

    const nearbyLocationCreateIntelSearchSubscription =
      this.nearbyLocationsStore
        .getNearbyLocationIntelSearchSeed()
        .pipe(distinctUntilChanged())
        .subscribe((searchState: { flag: boolean; seed: string }) => {
          this.searchIntelService.searchText.next(searchState.seed);
        });

    const nearByLocationFetchedSubscription = this.queryService.queriesChanged
      .pipe(
        distinctUntilChanged(isEqual),
        filter((queries) => !!queries && queries.length > 0),
        switchMap((queries) =>
          this.nearbyLocationsService.getNearbyLocationSearch(
            queries.map((query) => query.id)
          )
        ),
        mergeMap((alreadyFetchedProfiles) => alreadyFetchedProfiles),
        tap((search: NearbyLocationSearch) => {
          this.nearbyLocationsStore.setStateTrilaterationRequests(
            [search.query_args.geo_query_id],
            search?.trilateration_status === JobStatus.PENDING
              ? JobStatus.PENDING
              : JobStatus.NEVERSTARTED
          );
        })
      )
      .subscribe((search: NearbyLocationSearch) => {
        this.nearbyLocationsStore.saveNearbyLocations({
          queryId: search.query_args.geo_query_id,
          profiles: transformSnakeToCamel(search.profiles),
          queryDate: search.query_date,
          queryArgTelno: search.query_args.telno,
        });
      });

    const webSocketSubscription = this.websocketService
      .onEvent('state-update-geolocation')
      .pipe(
        filter(
          (incomingQueryFeed: Query) =>
            !!incomingQueryFeed || !this.queryService.showTelnoHistory.value
        ),
        distinctUntilChanged(isEqual),
        debounceTime(500)
      )
      .subscribe(() => {
        this.queryService.queryListPaginator.currentPage = 0;
        this.filtering();
      });

    const webSocketSubscriptionAdvancedGeolocationQuery = this.websocketService
      .onEvent('state-update-advanced-geolocation')
      .subscribe((data) => {
        if (this.enableAdvancedGeolocationPopup) {
          this.dialog.open(
            AdvancedGeolocationQueryConfirmationDialogComponent,
            {
              width: '30vw',
              height: 'auto',
              disableClose: true,
              data,
              panelClass: this.isGeolocTheme
                ? 'geoloc-theme'
                : this.isWhiteTheme
                ? 'white-theme'
                : '',
            }
          );
        }
      });

    const queryToHighLightSubscription =
      this.queryService.queryToHighLight.subscribe((query: Query) =>
        this.highLightExtractedQuery(query)
      );

    const multiqueriesSubscription =
      this.queryService.multiquerySelection.subscribe((queries) => {
        this.multiSelect = queries;
      });

    const queriesLoaderSubscription =
      this.queryService.showQueriesLoader.subscribe((flag: boolean) => {
        this.showQueriesLoader = flag;
      });

    const queryExtractPeerAction = this.queryExtractPeerService
      .getAction()
      .subscribe((result: ExtractPeerStoreAction) => {
        this.queryExtractPeerService.extractPeer({
          extractPeerInformationAndLocate:
            result.action === ExportPeerAction.GET_INFO_AND_LOCATE,
          query: result.query,
        });
      });

    this.currentUser = this.localStorageService.getCurrentUser();
    this.subscriptions.push(
      queriesChangedSubscription,
      queriesPaginationData,
      selectedQuerySubscription,
      refreshLogQueriesSubscription,
      showTelnoHistorySubscription,
      batchQueriesSubscription,
      historyItemSubscription,
      webSocketSubscription,
      multiqueriesSubscription,
      queriesLoaderSubscription,
      webSocketSubscriptionAdvancedGeolocationQuery,
      nearbyLocationCreateIntelSearchSubscription,
      nearByLocationFetchedSubscription,
      queryExtractPeerAction,
      queryToHighLightSubscription
    );
  }

  private highLightExtractedQuery(query: Query): void {
    const index = this.allQueries.findIndex((elem) => elem.id === query.id);
    this.onSelectedQuery(query);
    if (-1 !== index) {
      this.allQueries[index] = query;
    }
    this._iteratePaginator();
    this.queryService.selectNewQuery.next(transformSnakeToCamel(query));
  }

  onSelectedQuery(query) {
    this.emittedSelectedQuery.emit(query);
  }

  onMultiselectedQuery(data) {
    this.emittedMultiselectQuery.emit({
      query: data.query,
      new: !!data.checked,
    });
  }

  onPaginatePageChange(event) {
    this.showQueriesLoader = true;
    this.paginatedQueries = [];
    if (event.previousPageIndex < event.pageIndex) {
      this.paginator.currentPage = this.paginator.currentPage + 1;
      this.queryService
        .getPaginatedQueries(this.paginator.currentPage + 1)
        .subscribe();
    } else {
      this.paginator.currentPage = this.paginator.currentPage - 1;
      this.queryService
        .getPaginatedQueries(this.paginator.currentPage + 1)
        .subscribe();
    }
    this.queryService.queryListPaginator.currentPage =
      this.paginator.currentPage;
  }

  private _iteratePaginator() {
    this.paginatedQueries = [...this.allQueries];
    this.showQueriesLoader = false;
    this.changeDetection.markForCheck();
  }

  refreshQueries(force?) {
    if (force) {
      this.queryService.queryListPaginator.currentPage = 0;
    }
    this.showQueriesLoader = true;
    this.resetVariablesState();
    this.dateTimeRange = [];
    this.queryService.resetVariablesState();
    this.dashboardService.showIntelTabContent.next(false);
    this.dashboardService.componentsView.next(DashboardView.LOG);
    this.refreshMap();
    this.selectAll = false;
  }

  resetVariablesState() {
    this.paginator.currentPage = 0;
    this.selectedFilter = '';
    this.groupId = null;
    if (this.searchInput?.nativeElement) {
      this.searchInput.nativeElement.value = '';
    }
    this.selectedUser = null;
    this.dateSort = false;
    this.searchText = '';
  }

  refreshMap() {
    this.refreshLogMap.emit(true);
  }

  @debounce(1000)
  filtering() {
    if (this.queryService.showTelnoHistory.value) {
      const historyItem = this.queryService.historyItem.value;
      this.queryService
        .getFilteredTelnoQueries(
          historyItem.telno,
          historyItem.imsi,
          this.selectedFilter,
          this.dateRange,
          this.searchText,
          this.selectedUser
        )
        .subscribe(() => (this.paginator.currentPage = 0));
    } else {
      this.queryService
        .getFilteredQueries(
          this.dateRange,
          this.selectedFilter,
          this.searchText,
          this.selectedUser
        )
        .subscribe(
          () =>
            (this.paginator.currentPage =
              this.queryService.queryListPaginator.currentPage)
        );
    }
  }

  queryStatusFilterChanged(
    event: MatCheckboxChange,
    filterChanged: LogFilters.NOT_LOCATED
  ) {
    this.selectedFilter = event.checked ? filterChanged : '';
    this.filtering();
  }

  generateReport(reportType: string) {
    this.angulartics2.eventTrack.next({
      action: matomoEventsMap[reportType],
      properties: {
        category: matomoCategories.gioQueryLog,
      },
    });
    // PDF or CSV
    if (reportType) {
      this.queryService
        .createQueryLogReport(
          reportType,
          this.selectedFilter,
          this.dateRange,
          this.searchText,
          this.multiSelect
        )
        .subscribe((data) => {
          this.userBehaviorService
            .userBehavior(`geo_export_log_table_${reportType}`)
            .subscribe();
          const formattedDate = format(new Date(), 'ddMMyyyy_hhmmss');
          if (reportType === 'PDF') {
            const blob = new Blob([data], {
              type: `application/${reportType}`,
            });
            saveAs(blob, `Queries_history_${formattedDate}.pdf`);
          } else if (reportType === 'CSV') {
            const blob = new Blob([data], { type: `text/${reportType}` });
            saveAs(blob, `Queries_history_${formattedDate}.csv`);
          }
        });
    }
  }

  generateCSVReport(type) {
    this.queryService
      .createQueryLogReport(
        type,
        this.selectedFilter,
        this.dateRange,
        this.searchText
      )
      .subscribe((data) => {
        const blob = new Blob([data], { type: `text/$({e.source.value}` });
        saveAs(blob, `${this.formatDate}.report.csv`);
      });
  }

  resetPaginator($event) {
    this.paginator.currentPage = $event;
  }

  querySelected() {
    this.queryService.isAutoFillQueries.next(true);
    this.queryService.autoFillQueries.next(this.multiSelect);
    this.refreshLogMap.emit(true);
    this.dashboardService.componentsView.next(DashboardView.QUERY);
    this.queryService.multiquerySelection.next([]);
  }

  getEmergencyQueries() {
    const type = 'emergency';
    this.queryService.getEmergencyQueries(type).subscribe();
  }

  changeEmergencyView() {
    this.queryService.getAllQueries().subscribe();
    this.queryService.onQuerySelection.next(null);
    this.refreshLogMap.emit(true);
  }

  sortQueries() {
    this.dateSort = !this.dateSort;
    this.queryService.sortQueries(this.dateSort).subscribe();
  }

  getSearchFilterObservables() {
    const eventType = ['keyup', 'paste'];
    const fromEvents = [];
    eventType.forEach((type) => {
      if (this.searchInput) {
        const event = fromEvent(this.searchInput.nativeElement, type);
        fromEvents.push(event);
      }
    });
    return fromEvents;
  }

  onRefreshMap() {
    this.refreshLogMap.emit(true);
  }

  /**
   * This function will be called after appGuesHeight directive will do the job,
   * after that we can calculate corrected amount of rows and do the initial data request
   * @param height number
   */
  doInitialQuery(height?: number) {
    if (height) {
      this.paginator.pageSize = Math.floor(
        (height - this.bottomActionPanelHeight) / this.queryItemHeight
      );
      this.queryService.limit = this.paginator.pageSize;
    }

    const dateRangeSubscription = this.queryService.dateRange
      .pipe(tap((dateRange) => (this.dateRange = dateRange)))
      .subscribe(() => {
        if (this.queryService.showTelnoHistory.value) {
          const historyItem = this.queryService.historyItem.value;
          this.queryService
            .getFilteredTelnoQueries(
              historyItem.telno,
              historyItem.imsi,
              this.selectedFilter,
              this.dateRange,
              this.searchText,
              this.selectedUser
            )
            .subscribe(() => (this.paginator.currentPage = 0));
        } else {
          this.queryService
            .getFilteredQueries(
              this.dateRange,
              this.selectedFilter,
              this.searchText,
              this.selectedUser
            )
            .subscribe(
              () =>
                (this.paginator.currentPage =
                  this.queryService.queryListPaginator.currentPage)
            );
        }
      });

    this.subscriptions.push(dateRangeSubscription);
  }

  addUserColumn() {
    if (this.isAdmin || this.isSupport || this.isPower) {
      const col = {
        label: 'User',
        sort: false,
        width: this.isMobileResolution ? '0px' : '70px',
        col_class_xs: '0',
        class: 'hide',
      };
      this.tableLabels.splice(3, 0, col);
    }
  }

  setupTableLabels() {
    this.tableLabels = [
      {
        label: 'Target',
        sort: false,
        width: this.isMobileResolution ? '40%' : '125px',
        class: 'start-md center-xs',
      },
      {
        label: 'Date',
        sort: true,
        width: this.isMobileResolution ? '40%' : '110px',
        class: 'start-md center-xs',
      },
      {
        label: 'Type',
        sort: false,
        width: this.isMobileResolution ? '0px' : '60px',
        class: 'start-md hide',
      },
      {
        label: 'Actions',
        sort: false,
        filter: true,
        width: this.isMobileResolution ? '15%' : '70px',
        class: 'end-md hide action-header',
      },
    ];
  }

  private addQuery(newQuery: Query): void {
    const queryIndex = this.allQueries.findIndex(
      (query) => query.id === newQuery.id
    );

    if (queryIndex !== -1) {
      this.allQueries[queryIndex] = newQuery;
    } else {
      this.allQueries.push(newQuery);
    }
  }

  public redirectToInvestigation(): void {
    const msisdns = this.multiSelect
      .map((query) => query?.provider?.telno)
      .filter(Boolean);
    const imsis = this.multiSelect
      .map((query) => query?.provider?.imsi)
      .filter(Boolean);
    this.queryService.multiquerySelection.next([]);
    this.router.navigate(['/', 'case-investigation'], {
      queryParams: {
        msisdns,
        imsis,
        returnUrl: InvestigationNavigatedFrom.DISCOVERY,
      },
    });
  }
}
