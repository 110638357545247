import { Component, Inject, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { BreachData } from 'src/app/modules/profiler/shared/models/breach-data.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RecoveryAccount } from 'src/app/shared/models/recovery-account.model';
import { RecoveryAccountsService } from 'src/app/services/recovery-accounts/recovery-accounts.service';

interface EmailResultsDialogContext {
  email: string;
  breachData?: BreachData[];
  recoveryAccounts?: RecoveryAccount[];
}

@Component({
  selector: 'app-email-results-dialog',
  templateUrl: './email-results.component.html',
  styleUrls: ['./email-results.component.scss'],
})
export class EmailResultsDialogComponent extends BaseComponent {
  displayedColumns: string[] = ['type', 'value', 'origin'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public translationService: TranslationService,
    private recoveryAccountsService: RecoveryAccountsService,
    public dialogRef: MatDialogRef<EmailResultsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: EmailResultsDialogContext
  ) {
    super();
    this.dataSource = new MatTableDataSource([
      ...this.loadRecoveryAccounts(),
      ...this.loadBreachData(),
    ]);
  }

  private loadRecoveryAccounts() {
    const { recoveryAccounts = [], email } = this.context;
    return recoveryAccounts.reduce((acc, recoveryAccount) => {
      const { emails = [], telnos = [] } = recoveryAccount;

      const emailSeed = this.recoveryAccountsService
        .joinParentSeeds(recoveryAccount)
        .find((seed) => seed.type == 'email' && seed.value === email);

      if (!emailSeed) {
        return acc;
      }

      const origin = emailSeed.value.split('@').pop();

      for (const { type, iconClass, values } of [
        {
          type: 'Recovery email',
          iconClass: 'warning-icon',
          values: emails,
        },
        {
          type: 'Recovery phone',
          icon: 'warning-icon',
          values: telnos,
        },
      ]) {
        if (values.length) {
          acc.push(
            ...values.map((value) => ({ type, iconClass, value, origin }))
          );
        }
      }

      return acc;
    }, []);
  }

  private loadBreachData() {
    const { breachData = [] } = this.context;
    return breachData.map(({ password, origin }) => ({
      iconClass: 'danger-icon',
      type: 'Breached password',
      value: password,
      origin,
    }));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public copyNotify() {
    this.showMessage(this.translationService.translate('Copied!'));
  }
}
