<div class="inline-flex width-100">
  <div class="target-profile-card">
    <div class="avatars-container">
      <app-multiple-avatars
        (imgError)="onImgError($event)"
        [avatars]="avatars?.photos.length ? avatars.photos : dummyAvatar"
      >
        <ng-template #customMainAvatarTplRef>
          <div class="custom-main-avatar">
            {{ mainAvatarCustomTplText }}
          </div>
        </ng-template>
      </app-multiple-avatars>
      <span
        class="iti__flag iti__{{ target?.countryCode.toLowerCase() }}"
        *ngIf="target?.countryCode"
      ></span>
    </div>
  </div>
  <div class="target-profile-details">
    <div class="name" *ngIf="target?.alias; else imProfilesTpl">
      {{ target.alias }}
    </div>

    <div class="active-cases" *ngIf="target">
      <div class="cases-list" *ngIf="target?.fullCasesList">
        <a
          [routerLink]="['/case', case.id, 'overview']"
          *ngFor="let case of target?.fullCasesList"
        >
          {{ case.caseName }}
        </a>
      </div>
      <div>
        {{ 'Active in' | translate }}
        <span
          [class.part-of-cases]="target?.assignedCases?.length"
          (mouseenter)="loadCases()"
        >
          {{ target?.assignedCases?.length }} {{ 'cases' | translate }}
        </span>
      </div>
    </div>
    <div
      *ngIf="
        lastSeenOnline?.lastSeen &&
        lastSeenOnline.platform !== allPlatforms.TELEGRAM
      "
    >
      {{ 'Last seen' | translate }} {{ 'on' | translate }}
      {{ lastSeenOnline?.platform }}:
      {{
        localDate(lastSeenOnline?.lastSeen, 'DD/MM/YYYY HH:mm:ss')
          .local()
          .format('DD/MM/YYYY HH:mm')
      }}
    </div>
    <div *ngIf="availablePlatforms.length">
      <ng-container *ngFor="let platform of availablePlatforms">
        <div *ngIf="platform !== allPlatforms.TELEGRAM" class="im-platform">
          <img
            #imImage
            class="im-platform-icon"
            [src]="getPlatformImage(platform)"
            [alt]="platform"
            (mouseover)="onMouseOver($event, platform)"
            (mouseout)="onMouseLeave($event)"
          />
        </div>
        <ng-container *ngIf="showImTooltip$ | async">
          <app-geolocation-im-tooltip></app-geolocation-im-tooltip>
        </ng-container>
      </ng-container>
    </div>
    <div
      class="geo-redirect-to-target"
      *ngIf="target"
      (click)="redirectToTarget()"
    >
      {{ 'Open target profile' | translate }}
    </div>
    <div
      class="expired-wrp error"
      *ngIf="targetCreditsChargesEnabled && target?.expired"
    >
      <img src="assets/static/images/geo/expired-icon.svg" />
      <p>{{ 'Target Expired' | translate }}</p>
    </div>
    <div class="geo-add-to-target" *ngIf="!target" (click)="addToTarget()">
      {{ 'Add to target' | translate }}
    </div>
    <div
      class="geo-add-to-target"
      *ngIf="clRequest?.id && !target"
      (click)="viewClAnalysis()"
    >
      {{ 'View Call Log analysis' | translate }}
    </div>
    <div
      class="get-hist-data ml-5"
      *ngIf="query.location && recommendations?.numberOfRecords > 0"
      matTooltip="{{
        'Unveil movement patterns and understand your target’s habits'
          | translate
      }}"
      (click)="openRecommendationModal()"
    >
      {{ 'Get historical locations' | translate }}
    </div>
  </div>
</div>

<ng-template #imProfilesTpl>
  <div class="text-ellipsis im-names" *ngIf="avatars?.imProfiles?.length">
    <div
      matTooltipPosition="above"
      [matTooltip]="(avatars.imProfiles | mapArrayToProp: 'names').join(', ')"
    >
      {{ (avatars.imProfiles | mapArrayToProp: 'names').join(', ') }}
    </div>
  </div>
  <div>
    {{ query?.queryArgs.telno || query?.provider.telno }}
  </div>
</ng-template>
