import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mobile-gauge-item',
  templateUrl: './mobile-gauge-item.component.html',
  styleUrls: ['./mobile-gauge-item.component.scss'],
})
export class MobileGaugeItemComponent {
  @Input()
  public gaugeType: string;

  @Input()
  public isUnlimitedTheme: boolean;

  @Input()
  public gaugeValue: number;

  @Input()
  public gaugeAppendText: string;

  @Input()
  public foregroundColor: string;

  @Input()
  public distributed: boolean = false;

  public readonly backgroundColor: string = '#F7F7F7';
}
