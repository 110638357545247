export enum NotificationType {
  SYSTEM = 'system',
  RADICAL_MONITORING = 'radicalmonitoring:notifyOnDetect',
  MARKETING_BANNER = 'marketing-banner',
  TARGET_CASE_EXPIRATION = 'expires:entity',
  SOMEDUS_STATUS = 'somedus:status',
  AVATAR_EXPIRED = 'avatar:expired',
  TARGET_MONITORING_ALERT = 'target-monitoring:alert',
  LOCATION_HISTORY_NOT_FOUND_TELCELL = 'locationhistory:notfound:telcell',
  GEOQUERY_DEACTIVATED_STATE = 'geoquery:deactivated:state',
  CALLLOG_UPLOAD = 'calllogrequests:notifyUserOnSupportUpload',
  CALLLOG_UPLOAD_FINISHED = 'calllogrequests:notifyUserOnUploadFinished',
  ADVANCED_GEOQUERY = 'advancedgeoquery:notifyUserOnAdvancedQueryResponse',
  QUERY_CALL_INFO = 'querycallinfo:notifyOnDetect',
  GEOQUERY_SCHEDULE_ENDED = 'geoquery:schedule:expired',
  LOCATION_HISTORY_NOT_FOUND = 'locationhistory:notfound',
}

export const markAsReadNotifications: NotificationType[] = [
  NotificationType.LOCATION_HISTORY_NOT_FOUND_TELCELL,
  NotificationType.TARGET_MONITORING_ALERT,
  NotificationType.AVATAR_EXPIRED,
  NotificationType.GEOQUERY_DEACTIVATED_STATE,
  NotificationType.CALLLOG_UPLOAD,
  NotificationType.TARGET_CASE_EXPIRATION,
  NotificationType.CALLLOG_UPLOAD_FINISHED,
  NotificationType.ADVANCED_GEOQUERY,
  NotificationType.QUERY_CALL_INFO,
  NotificationType.SOMEDUS_STATUS,
  NotificationType.GEOQUERY_SCHEDULE_ENDED,
  NotificationType.LOCATION_HISTORY_NOT_FOUND,
];

export enum ActionType {
  push = 'push',
  pop = 'pop',
  clear = 'clear',
  markAsRead = 'markAsRead',
  clearRadicalMonitoring = 'clearRadicalMonitoringSource',
  system = 'system',
  remove = 'remove',
}
