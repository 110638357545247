import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ImProfile } from 'datalayer/models/platform-models/im-profiles/im-profile';
import { parse } from 'date-fns';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { TargetDialogComponent } from 'src/app/modules/search-intel/components/target-dialog/target-dialog.component';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { CaseService } from 'src/app/services/case/case.service';
import { QueryService } from 'src/app/services/query/query.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Action } from 'src/app/shared/classes/action.class';
import { ExpiredDialogComponent } from 'src/app/shared/components/expired-dialog/expired-dialog.component';
import { AvatarWithPlatform } from 'src/app/shared/components/multiple-avatars/multiple-avatars.component';
import { Case } from 'src/app/shared/models/case.model';
import { Query } from 'src/app/shared/models/query-item.model';
import {
  TargetItem,
  TargetItemDTO,
} from 'src/app/shared/models/target-item.model';
import { RecommendationImportService } from 'src/app/shared/modules/recommendations/recommendation-import.service';
import { RequestCallLogModalComponent } from 'src/app/shared/modules/recommendations/request-call-log-modal/request-call-log-modal.component';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { RequestClValidatorService } from 'src/app/modules/analysis/shared/services/request-cl-validator.service';
import { CallLogsListResponseModel } from 'src/app/modules/analysis/shared/models/targets-list-request.model';
import { RecommendationResponseBody } from 'src/app/shared/modules/recommendations/models/socket-responses.interface';
import { ApplicationMainPageUrls } from 'src/app/shared/models/application-main-page-urls.enum';
import { uniq } from 'lodash-es';
import { Platform } from 'src/app/shared/schemas/common/platforms';
import { ImageService } from 'src/app/services/image/image.service';
import { ImTooltip } from '../geolocation-im-tooltip/geolocation-im-tooltip.component';

@Component({
  selector: 'app-geolocation-target-details',
  templateUrl: './geolocation-target-details.component.html',
  styleUrls: ['./geolocation-target-details.component.scss'],
})
export class GeolocationTargetDetailsComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  public showImTooltip$: BehaviorSubject<Boolean> =
    new BehaviorSubject<Boolean>(false);
  public imImageElem: ElementRef<HTMLDivElement>;
  @ViewChild('imImage') set content(content: ElementRef<HTMLDivElement>) {
    if (content) {
      this.imImageElem = content;
    }
  }
  @Input() target: TargetItem;
  @Input() avatars: { imProfiles: ImProfile[]; photos: AvatarWithPlatform[] };
  @Input() query: Query;
  @Input() recommendations: RecommendationResponseBody | null = null;

  availablePlatforms: Platform[] = [];
  lastSeenOnline: { lastSeen: string; platform: string };
  localDate: any;
  targetCreditsChargesEnabled: boolean = false;
  expireTargetDays: number = 0;
  completedClRequests: CallLogsListResponseModel;
  clRequest: CallLogsListResponseModel;
  imProfiles: ImProfile[] = [];
  allPlatforms = Platform;

  constructor(
    private imageService: ImageService,
    private caseService: CaseService,
    private targetService: TargetService,
    private router: Router,
    private dialog: MatDialog,
    private translationService: TranslationService,
    private appConfigService: AppConfigService,
    private recommendationImportService: RecommendationImportService,
    private queryService: QueryService,
    private requestClValidatorService: RequestClValidatorService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.targetCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForTarget'
    );
    this.expireTargetDays =
      this.appConfigService.getConfigVariable('expireTargetDays');
  }

  public dummyAvatar: AvatarWithPlatform[] = [
    {
      src: '/assets/static/images/linked-person.svg',
      platform: null,
      overlayDisabled: true,
    },
  ];
  public mainAvatarCustomTplText: string;

  ngOnInit(): void {
    this.localDate = moment.utc;
    if (this.target?.photos?.length === 0) {
      this.avatars.photos.unshift({
        customTpl: true,
        src: null,
        platform: null,
        overlayDisabled: true,
      });
      this.mainAvatarCustomTplText = this.getInitials();
    }
    this.getClRequestStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const profiles =
      changes.avatars?.currentValue.imProfiles ||
      changes.query?.currentValue.instantMessageProfiles;
    if (profiles) {
      this.lastSeenOnline = this.getLatestLastSeen(profiles);
      this.setAvailableImPlatforms(profiles);
      this.imProfiles = profiles;
    }
  }

  public onMouseOver(_, platform: string): void {
    const tooltipInfo: ImTooltip = { images: [] };
    if (!this.imProfiles.length) {
      return;
    }

    this.imProfiles
      .filter((profile) => profile.platform === platform)
      .forEach((profile) => {
        tooltipInfo['images'] = [...tooltipInfo['images'], ...profile.photos];
        tooltipInfo['name'] = uniq(profile.names).filter(Boolean).join(', ');
        tooltipInfo['lastSeen'] = profile.lastSeen
          ? this.localDate(profile.lastSeen, 'DD/MM/YYYY HH:mm:ss')
              .local()
              .format('DD/MM/YYYY HH:mm')
          : undefined;
      });

    if (
      !tooltipInfo['name'] &&
      !tooltipInfo['lastSeen'] &&
      !tooltipInfo.images.length
    ) {
      this.showImTooltip$.next(false);
    } else {
      tooltipInfo.images = tooltipInfo['images'].map((image) =>
        this.imageService.getPhotoUrl(image['image'])
      );
      tooltipInfo['platform'] = platform;
      this.queryService.setImTooltip(tooltipInfo);
      this.showImTooltip$.next(true);
    }
  }

  public onMouseLeave(_): void {
    this.showImTooltip$.next(false);
  }

  private setAvailableImPlatforms(profiles: ImProfile[]): void {
    this.availablePlatforms = uniq(
      profiles
        .filter((profile) => profile.platform)
        .map((profile) => profile.platform)
    );
  }

  getClRequestStatus(): void {
    const msisdn = this.query?.provider?.telno;
    if (!msisdn) {
      return;
    }

    this.requestClValidatorService
      .getCallLogsStatusForMsisdn(msisdn)
      .subscribe((req) => {
        this.clRequest = req;
        this.cdr.markForCheck();
      });
  }

  viewClAnalysis(): void {
    this.router.navigate([
      `/${ApplicationMainPageUrls.CORE}/call-log-analysis`,
      this.clRequest.id,
    ]);
  }

  loadCases() {
    if (this.target.fullCasesList) {
      return;
    }

    forkJoin(
      this.target.assignedCases.map((c) => this.caseService.getCase(c))
    ).subscribe((cases: Array<Case>) => {
      this.target.fullCasesList = cases;
    });
  }

  redirectToTarget() {
    if (this.targetCreditsChargesEnabled && this.target.expired) {
      this.dialog
        .open(ExpiredDialogComponent, {
          width: '450px',
          data: {
            title: this.translationService.translate('Target is expired'),
            message: this.translationService.interpolate(
              'Click on the button below to renew for #{days} days',
              {
                days: this.expireTargetDays.toString(),
              }
            ),
            action: BillingActions.TARGET_MANAGEMENT,
            countOfActionsCharged: 1,
          },
          panelClass: 'expired-credit',
        })
        .afterClosed()
        .subscribe((result: Action) => {
          if (result.key.includes('success')) {
            this.subscriptions.push(
              this.targetService.renewTarget(this.target.id).subscribe(
                () =>
                  this.showMessage(
                    this.translationService.translate(
                      'Target renewed successfully!'
                    )
                  ),
                (error) =>
                  this.showMessage(
                    this.translationService.translate(
                      error ? error : 'Target has not been renewed'
                    )
                  ),
                () =>
                  this.targetService
                    .getTarget(this.target.id)
                    .subscribe((updatedTarget) => (this.target = updatedTarget))
              )
            );
          }
        });
    } else {
      this.router.navigate(['targets', this.target.id, 'overview']);
    }
  }

  addToTarget() {
    const newTarget: TargetItemDTO = {
      telnos: this.query?.queryArgs?.telno ? [this.query.queryArgs.telno] : [],
      imsis: this.query?.provider?.imsi ? [this.query.provider.imsi] : [],
      provider: this.query?.provider?.name || undefined,
      imeis: this.query?.device?.imei ? [this.query.device.imei] : [],
      ...(this.query?.queryArgs?.telno && {
        telnoProviders: {
          [this.query?.queryArgs?.telno]: {
            provider: this.query?.provider?.name,
          },
        },
      }),
    };

    this.dialog.open(TargetDialogComponent, {
      width: '530px',
      data: {
        seedName: newTarget.telnos,
        seedCount: this.getSeedsCount(newTarget),
        newTarget,
        imProfiles: this.imProfiles || [],
      },
      autoFocus: false,
    });
  }

  private getSeedsCount(target: TargetItemDTO): number {
    let seedCount: number = 0;
    for (const value of Object.values(target)) {
      if (value) {
        if (
          typeof value === 'string' ||
          (Array.isArray(value) && value.length)
        ) {
          seedCount += 1;
        }
      }
    }

    return seedCount;
  }

  /**
   * For target query we check if main avatar image has an error and push an extra item to photos with initials to display it first
   * @param avatar AvatarWithPlatform
   */
  onImgError(avatar: AvatarWithPlatform) {
    const isMainAvatar = this.avatars.photos.indexOf(avatar) === 0;

    if (isMainAvatar && this.target) {
      this.avatars.photos.unshift({
        customTpl: true,
        src: null,
        platform: null,
        overlayDisabled: true,
      });
      this.mainAvatarCustomTplText = this.getInitials();
    }
  }

  openRecommendationModal() {
    this.dialog
      .open(RequestCallLogModalComponent, {
        data: {
          telno: this.query.queryArgs.telno,
          recommendations: this.recommendations,
        },
      })
      .afterClosed()
      .pipe(
        filter((data) => data?.selectedDays),
        switchMap(({ selectedDays }) =>
          this.recommendationImportService.doImportAndShowToastr(
            this.recommendations.msisdn,
            selectedDays
          )
        )
      )
      .subscribe(([apiResult, comp]) => {
        if (apiResult.body.status === 'completed') {
          comp.toastRef.componentInstance.isImported = true;
          this.queryService.refreshLogQueries.next(true);
        }
      });
  }

  private getInitials(): string {
    const name = this.target.names.length
      ? this.target.names.join('')
      : this.target.alias;

    return name
      .split(' ')
      .slice(0, 3)
      .map((w) => w.charAt(0).toUpperCase())
      .join('');
  }

  private getLatestLastSeen(profiles: ImProfile[]): {
    lastSeen: string;
    platform: string;
  } {
    let latestLastSeen: {
      lastSeen: string;
      platform: string;
    };
    profiles?.forEach((profile) => {
      if (
        !!profile.lastSeen &&
        (!latestLastSeen ||
          parse(profile.lastSeen, 'dd/MM/yyyy HH:mm:ss', new Date()) >
            parse(latestLastSeen.lastSeen, 'dd/MM/yyyy HH:mm:ss', new Date()))
      ) {
        latestLastSeen = Object.assign(
          {},
          { lastSeen: profile.lastSeen, platform: profile.platform }
        );
      }
    });
    return latestLastSeen;
  }

  getPlatformImage(platform: Platform): string {
    return this.queryService.getPlatformImage(platform);
  }
}
