export enum CreditGaugeRange {
  NO_CREDITS = '0',
  CREDITS_DANGER = '10',
  CREDITS_WARNING = '21',
  CREDITS_OK = '41',
}

export enum CreditGaugeColors {
  RED = '#FF4343',
  REDWARNING = '#E65100',
  GREY = '#D9D9D9',
  ORANGE = '#FF9800',
  GREEN = '#00BFA5',
  WHITE = '#ffffff',
  BLACK = '#000000',
  PRIMARY = '#3365FF',
}

export interface AvailableConcurrentCredits {
  label: string;
  currentBalance: number;
  maxBalance: number;
  width: string;
  color: CreditGaugeColors;
  sublabel?: string;
}

export enum ConcurrentCreditsSublabel {
  DAILY_QUOTA = 'daily quota',
  MONTHLY_QUOTA = 'monthly quota',
  ANNUAL_QUOTA = 'annual quota',
}

export const ConcurrentCreditsSublabelValue: { [key: string]: string } = {
  geolocation: ConcurrentCreditsSublabel.DAILY_QUOTA,
  webint: ConcurrentCreditsSublabel.MONTHLY_QUOTA,
  somedus: ConcurrentCreditsSublabel.DAILY_QUOTA,
};

export const gaugeOptions = {
  size: 30,
  thick: 4,
  gaugeType: 'full',
  gaugeValue: 0,
  gaugeAppendText: '%',
  foregroundColor: CreditGaugeColors.BLACK,
  backgroundColor: CreditGaugeColors.PRIMARY,
  thresholdConfig: {
    [CreditGaugeRange.NO_CREDITS]: { color: CreditGaugeColors.RED },
    [CreditGaugeRange.CREDITS_OK]: { color: CreditGaugeColors.PRIMARY },
  },
  strokeWeight: 2,
};
