import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  interval,
  Observable,
  Subscription,
  timer,
} from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { SearchIntelService } from 'src/app/modules/search-intel/services/search-intel.service';
import { Message } from 'src/app/services/websocket/websocket.class';
import { SearchIntelFields } from '../../models/search-intel-fields.enum';
import { ServerPyWsService } from 'src/app/services/websocket/server-py-websocket.service';
import { MessageSubject } from 'src/app/services/websocket/message-subject.model';

@Component({
  selector: 'app-intel-loader',
  templateUrl: './intel-loader.component.html',
  styleUrls: ['./intel-loader.component.scss'],
})
export class IntelLoaderComponent extends BaseComponent implements OnInit {
  @Input() groupingTabs: boolean = false;
  @Input() groupingTabsText: string;
  searchValue: string;
  searchTextSubscription: Subscription;
  displayText: string;
  private progressBarValue: BehaviorSubject<number> = new BehaviorSubject(0);
  public progressBarValue$ = this.progressBarValue.asObservable();
  groupingTime: Observable<number> = interval(50);
  progressBarElements: { name: string; value: boolean; updatedAt?: Date }[] =
    [];
  resultsCount: number = 0;
  keywordsLoader: boolean = false;

  private allowedMessageSubjects: MessageSubject[] = [
    MessageSubject.NotifyAnalysingResults,
    MessageSubject.NotifySearchingClosedDatabases,
    MessageSubject.NotifySearchingDarkWeb,
    MessageSubject.NotifySearchingInstantMessageProfiles,
    MessageSubject.NotifySearchingOpenWeb,
    MessageSubject.NotifySearchingSocialDatabases,
    MessageSubject.NotifySearchingSocialProfiles,
  ];

  constructor(
    private searchIntelService: SearchIntelService,
    private translationService: TranslationService,
    private serverPyWsService: ServerPyWsService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.reset();
    if (this.groupingTabs) {
      this.displayText = this.groupingTabsText;
      this.subscriptions.push(
        this.groupingTime
          .pipe(take(1000))
          .subscribe((number) => this.progressBarValue.next(number))
      );
    } else {
      this.displayText = this.translationService.translate(
        'Collecting and analysing intelligence for'
      );
    }
    const ws = this.serverPyWsService
      .getMessage()
      .pipe(
        filter(({ subject }) => this.allowedMessageSubjects.includes(subject))
      )
      .subscribe((msg) => {
        this.setProgressBarElements(msg);
      });

    this.searchTextSubscription = this.searchIntelService.searchText.subscribe(
      (res) => {
        this.searchValue = res;
        this.cdRef.markForCheck();
      }
    );
    this.subscriptions.push(ws, this.searchTextSubscription);

    const keywordLoaderSub = this.searchIntelService.keywordSearchInprogress
      .pipe(filter((res) => !!res))
      .subscribe((res) => {
        this.searchValue = res.value;
        this.keywordsLoader = res.loading;
        this.setupKeywordsLoader();
        this.cdRef.markForCheck();
      });
    this.subscriptions.push(ws, this.searchTextSubscription, keywordLoaderSub);
  }

  setProgressBarElements(msg: Message): void {
    const searchIntelField =
      SearchIntelFields[msg.subject.replace('notify.', '')];
    let elementIndex = this.progressBarElements.findIndex(
      (item) => item.name === searchIntelField
    );
    let status = !(msg.body.status === 'in-progress' && !msg.body?.is_analysis);
    const analyzingResults = msg.body.is_analysis;
    const analyzeIndex = this.progressBarElements.findIndex(
      (item) => item.name === SearchIntelFields.analyzing_results
    );
    if (analyzeIndex <= -1) {
      this.progressBarElements.push({
        name: SearchIntelFields.analyzing_results,
        value: false,
        updatedAt: new Date(),
      });
    }
    if (analyzingResults) {
      this.progressBarElements[analyzeIndex].value = false;
    } else {
      if (elementIndex <= -1) {
        this.progressBarElements.push({
          name: searchIntelField,
          value: status,
          updatedAt: new Date(),
        });
      } else {
        this.progressBarElements[elementIndex].value = status;
        this.progressBarElements[elementIndex].updatedAt = new Date();
      }
    }

    this.resultsCount = !!msg?.body?.count ? msg.body.count : 0;

    this.progressBarElements = this.progressBarElements.sort(
      (item1, item2) => item1.updatedAt.getTime() - item2.updatedAt.getTime()
    );
    this.progressBarElements = this.progressBarElements.sort((item1, item2) =>
      item1.value < item2.value ? 1 : -1
    );
    const findAnalyzeIndex = this.progressBarElements.findIndex(
      (item) => item.name === SearchIntelFields.analyzing_results
    );
    this.progressBarElements.push(
      this.progressBarElements.splice(findAnalyzeIndex, 1)[0]
    );
    this.setProgressBarValue();
  }

  setProgressBarValue(): void {
    const totalItems = this.progressBarElements.length;
    const completed = this.progressBarElements.filter(
      (item) => item.value === true
    ).length;
    this.progressBarValue.next((completed / totalItems) * 100);
  }

  reset(): void {
    this.progressBarValue.next(0);
    this.progressBarElements = [];
    this.cdRef.markForCheck();
  }

  setupKeywordsLoader() {
    this.progressBarElements.push({
      name: SearchIntelFields.searching_social_media,
      value: false,
      updatedAt: new Date(),
    });
    this.processInSeconds(SearchIntelFields.searching_social_media, 2000);
    this.progressBarElements.push({
      name: SearchIntelFields.analyzing_results,
      value: false,
      updatedAt: new Date(),
    });
    this.processInSeconds(SearchIntelFields.analyzing_results, 4000);
    this.progressBarElements.push({
      name: 'Identifying top profiles',
      value: false,
      updatedAt: new Date(),
    });
  }

  processInSeconds(name: string, seconds: number) {
    let elementIndex = this.progressBarElements.findIndex(
      (item) => item.name === name
    );
    timer(seconds).subscribe(() => {
      this.progressBarElements[elementIndex].value = true;
      this.progressBarElements[elementIndex].updatedAt = new Date();
      this.setProgressBarValue();
    });
  }
}
