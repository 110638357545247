import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconTextButtonComponent } from './icon-text-button/icon-text-button.component';
import { StateIndicatorComponent } from './state-indicator/state-indicator.component';
import { AccordionComponent } from './accordion/accordion.component';
import { AccordionHeaderComponent } from './accordion/accordion-header/accordion-header.component';
import { AccordionBodyComponent } from './accordion/accordion-content/accordion-body.component';
import { DrawerComponent } from './drawer/drawer.component';
import { DrawerService } from './drawer/drawer.service';
import { FaProModule } from '@fe-platform/fa-pro';
import { PlatformIconComponent } from './platform-icon/platform-icon.component';
import { ModalComponent } from './modal/modal.component';
import { ModalHeaderComponent } from './modal/modal-header/modal-header.component';
import { RouterModule } from '@angular/router';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { IsMobileDirective } from './directives/is-mobile.directive';
import { LayoutService } from './services/layout.service';
import { ReactiveComponentModule } from '@ngrx/component';

@NgModule({
  declarations: [
    IconTextButtonComponent,
    StateIndicatorComponent,
    AccordionComponent,
    AccordionHeaderComponent,
    AccordionBodyComponent,
    DrawerComponent,
    PlatformIconComponent,
    ModalComponent,
    ModalHeaderComponent,
    PhoneNumberComponent,
    IsMobileDirective,
  ],
  imports: [CommonModule, FaProModule, RouterModule, ReactiveComponentModule],
  exports: [
    IconTextButtonComponent,
    StateIndicatorComponent,
    AccordionComponent,
    AccordionHeaderComponent,
    AccordionBodyComponent,
    DrawerComponent,
    PlatformIconComponent,
    ModalComponent,
    PhoneNumberComponent,
    IsMobileDirective,
  ],
  providers: [DrawerService, LayoutService],
})
export class SharedUiIntellectusModule {}
