import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ExportPeerAction } from 'src/app/shared/components/extract-peer-dialog/extract-peer-dialog.component';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { Query } from 'src/app/shared/models/query-item.model';
import { Skins } from 'src/app/shared/models/skins.model';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import { UserBillingService } from '../billing/user-billing.service';
import { InstantMessagesStore } from '../instant-messages.store';
import { QueryService } from './query.service';

export interface ExtractPeerStoreAction {
  query: Query;
  action: ExportPeerAction;
}

@Injectable({
  providedIn: 'root',
})
export class QueryExtractPeerService extends BaseService {
  constructor(
    protected router: Router,
    public snackBar: MatSnackBar,
    private queryService: QueryService,
    private instantMessagesStore: InstantMessagesStore,
    private userBillingService: UserBillingService
  ) {
    super(router, snackBar);
  }

  private extractPeerAction$: Subject<ExtractPeerStoreAction> =
    new Subject<ExtractPeerStoreAction>();

  public emitAction(action: ExtractPeerStoreAction): void {
    this.extractPeerAction$.next(action);
  }

  public getAction(): Observable<ExtractPeerStoreAction> {
    return this.extractPeerAction$.asObservable();
  }

  public extractPeer(extractPeerInfo: {
    extractPeerInformationAndLocate: boolean;
    query: Query;
  }): void {
    const { extractPeerInformationAndLocate, query } = extractPeerInfo;
    const billingActions: BillingActions[] = !!extractPeerInformationAndLocate
      ? [BillingActions.CALL_INFO, BillingActions.QUERY_LOCATION]
      : [BillingActions.CALL_INFO];

    if (
      environment.flavor === Skins.GEO4 ||
      environment.flavor === Skins.CVTP
    ) {
      if (this.userBillingService.userHasEnoughCredits(billingActions)) {
        this.queryService
          .chargeExtractPeer({ extractPeerInformationAndLocate, query })
          .subscribe((result: { parentQuery: Query; childQuery?: Query }) => {
            this.queryService.queryToHighLight.next(result.parentQuery);
            if (!!result.childQuery) {
              this.instantMessagesStore.fetchAllImPlatforms(
                result.childQuery.queryArgs.telno,
                result.childQuery.id,
                true
              );
            }
          });
      }
    }
  }
}
