import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Chart } from '@trg-ui/link-analysis';
// import blobStream from '@trg-ui/link-analysis/pdf/blob-stream.cjs';
// import PDFDocument from '@trg-ui/link-analysis/pdf/pdfkit.standalone.cjs';
import { Subject } from 'rxjs';
import { ExportDialogComponent } from 'src/app/modules/visual-investigation/components/export-dialog/export-dialog.component';

// window['PDFDocument'] = PDFDocument;
@Injectable({
  providedIn: 'root',
})
// DISABLE FUNTIONALITY UNTIL WE FIX THE ISSUE WITH Keylines 6.13.1 dependencies
export class ExportService {
  fontFamily = {
    regular: 'Helvetica',
    bold: 'Helvetica-Bold',
    italic: 'Helvetica-Oblique',
  };
  margin = 0.4 * 72; // 72dpi so this is 0.4 inches
  onExport: Subject<boolean> = new Subject();

  constructor(private dialog: MatDialog) {}

  private downloadPdf(pdfUrl: string) {
    const snapshotLink = document.createElement('a');
    snapshotLink.download = `link-analysis-export.pdf`;
    snapshotLink.href = pdfUrl;
    snapshotLink.click();
    // Revoke the url afterwards to free it from browser memory
    URL.revokeObjectURL(pdfUrl);
  }

  private async addExportedChartAndDate(
    chart: Chart,
    width: number,
    height: number,
    doc: unknown
  ) {
    // await chart.export({
    //   type: 'pdf',
    //   doc,
    //   extents: 'view',
    //   fitTo: {
    //     width,
    //     height: height - 10
    //   },
    // });
    // doc.font(this.fontFamily.italic, 10);
    // doc.moveDown().text(new Date(), { align: 'center', width });
  }

  private async generateLandscapeReport(
    chart,
    doc: unknown,
    title: string,
    notes: string
  ) {
    // doc.font(this.fontFamily.bold, 35).text(title, { align: 'center' });
    // // Allowing a margin separation between them,
    // // calculate the remaining space for the chart & bullet points
    // const divide = notes.length ? 3 : 2
    // const chartWidth = ((doc.page.width - 3 * this.margin) * 2) / divide;
    // const contentHeight = doc.page.height - this.margin - doc.y;
    // const chartHeight = (contentHeight * 4) / 5;
    // // Split the padding equally above and below the main content
    // const contentTop = doc.y;
    // doc.y += (contentHeight - chartHeight) / 2;
    // await this.addExportedChartAndDate(chart, chartWidth, chartHeight, doc);
    // if (notes.length) {
    //   // Add the notes to the right of the chart
    //   doc.x += chartWidth + this.margin;
    //   doc.y = contentTop;
    //   doc.font(this.fontFamily.regular, 16);
    //   doc.text(notes);
    // }
  }

  private async exportReport(chart: Chart, title: string, notes: string) {
    // const doc = new PDFDocument({ layout: 'landscape', margin: this.margin });
    // const stream = doc.pipe(blobStream());
    // await this.generateLandscapeReport(chart, doc, title, notes);
    // doc.end();
    // stream.on('finish', () => {
    //   this.downloadPdf(stream.toBlobURL('application/pdf'));
    // });
  }

  public openExportDialog(chart: Chart) {
    // const dialog = this.dialog.open(ExportDialogComponent, {
    //   width: '460px',
    //   autoFocus: true,
    // });
    // dialog.afterClosed().subscribe((formData: { title: string; notes: string }) => {
    //   this.exportReport(chart, formData.title, formData.notes);
    // });
  }
}
