import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { GetTargetListResponse } from '../models/get-target-list-response.payload';
import {
  CallLogsListRequestModel,
  TargetsListRequest,
} from '../models/targets-list-request.model';

@Injectable({
  providedIn: 'root',
})
export class AnalysisService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar,
    private translationService: TranslationService
  ) {
    super(router, snackBar);
  }

  getTargetsList(params: HttpParams, requestbody: TargetsListRequest) {
    return this.httpClient
      .post<GetTargetListResponse>(`${this.url}/targets-filter`, requestbody, {
        params: params,
      })
      .pipe(
        map((response) => transformSnakeToCamel(response)),
        catchError((error) => this.handleError(error))
      );
  }

  getCallLogsList(requestbody: CallLogsListRequestModel) {
    return this.httpClient
      .post<any>(`${this.proxyApiUrl}/call-log-request`, requestbody)
      .pipe(
        map((data) => {
          data = transformSnakeToCamel(data);
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getPendingCallLogsList(requestbody: CallLogsListRequestModel) {
    return this.httpClient
      .post<any>(
        `${this.proxyApiUrl}/call-log-request/rpc/get-number-of-call-log-requests`,
        requestbody
      )
      .pipe(
        map((data) => {
          data = transformSnakeToCamel(data);
          return data.numOfResults;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getCasesList(params: HttpParams, requestbody: TargetsListRequest) {
    return this.httpClient
      .post<any>(`${this.url}/cases-filter`, requestbody, { params: params })
      .pipe(
        map((data) => {
          data.result = transformSnakeToCamel(data.result);
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getTargetsFilters(columnName: string): Observable<string[]> {
    return this.httpClient
      .get<any>(`${this.url}/targets-filter?cols=${columnName}`)
      .pipe(
        map((data: { [key: string]: string[] }) => {
          data[columnName] = data[columnName].map((i) =>
            columnName == 'expired'
              ? i.toLowerCase() == 'true'
                ? this.translationService.translate('Expired')
                : this.translationService.translate('Active')
              : i
          );
          return data[columnName];
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getCasesFilters(columnName: string): Observable<string[]> {
    return this.httpClient
      .get<any>(`${this.url}/cases-filter?cols=${columnName}`)
      .pipe(
        map((data) => {
          data[columnName] = data[columnName].map((i) =>
            columnName == 'expired'
              ? i.toLowerCase() == 'true'
                ? this.translationService.translate('Expired')
                : this.translationService.translate('Active')
              : i
          );
          return data[columnName];
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getTargetsSearch(requestbody: TargetsListRequest) {
    return this.httpClient
      .post<any>(`${this.url}/targets-filter`, requestbody)
      .pipe(
        map((data) => {
          data.result = transformSnakeToCamel(data.result);
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  requestCallLog(requestCl: { [key: string]: string }) {
    return this.httpClient
      .put<any>(`${this.proxyApiUrl}/call-log-request`, requestCl)
      .pipe(catchError((error) => this.handleError(error)));
  }

  makeFavoriteCalllog(clId: string) {
    return this.httpClient
      .post<any>(`${this.proxyApiUrl}/call-log-requests-favorite/${clId}`, {})
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  removeFavoriteCalllog(favoriteId: string) {
    return this.httpClient
      .delete<any>(
        `${this.proxyApiUrl}/call-log-requests-favorite/${favoriteId}`,
        {}
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getCallLogsFilters(columnName: string): Observable<string[]> {
    return this.httpClient
      .get<any>(`${this.proxyApiUrl}/call-log-request/filter/${columnName}`)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }
}
