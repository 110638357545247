import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageService } from 'src/app/services/image/image.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Angulartics2 } from 'angulartics2';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';
import { COMMA, ENTER, BACKSPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  BillingActions,
  BillingActionType,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';
import { BillingService } from 'src/app/services/billing/billing.service';
import { Observable } from 'rxjs';
import { urlRegex } from '@app/validators';

export interface SearchByImageModalPayload {
  name: string;
  filename: string;
  displayName: string;
  imageUrl?: string;
}
@Component({
  selector: 'app-searchby-image',
  templateUrl: './searchby-image.component.html',
  styleUrls: ['./searchby-image.component.scss'],
})
export class SearchbyImageComponent implements OnInit {
  constructor(
    private imageService: ImageService,
    private translationService: TranslationService,
    private billingService: BillingService,
    public dialogRef: MatDialogRef<SearchbyImageComponent>,
    public form: FormBuilder,
    private angulartics2: Angulartics2,
    public snackBar: MatSnackBar
  ) {}

  public billingPlan$: Observable<
    BillingPlan<BillingActions, BillingActionType>
  >;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  imageName: string = '';

  private base64Regex = new RegExp(
    /^\s*data:([a-z]+\/[a-z0-9\-\+]+(;[a-z\-]+\=[a-z0-9\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i
  );

  ngOnInit(): void {
    this.billingPlan$ = this.billingService.getBillingPlan().asObservable();
  }

  forbiddenUrlValidator(base64Regex, urlRegex): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden =
        base64Regex.test(control.value) || urlRegex.test(control.value);
      return forbidden ? null : { forbiddenUrl: { value: control.value } };
    };
  }

  public imageForm: FormGroup = this.form.group({
    imageUrl: [
      '',
      [
        Validators.required,
        this.forbiddenUrlValidator(this.base64Regex, urlRegex),
      ],
    ],
  });

  selectedImageBase64: string;
  showLoader: boolean = false;

  @ViewChild('fileDropRef') fileDropEl: ElementRef;
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.angulartics2.eventTrack.next({
      action: matomoActions.searchByImage,
      properties: {
        category: matomoCategories.landingPage,
      },
    });
    if (
      $event?.length &&
      ['image/png', 'image/x-png', 'image/jpg', 'image/jpeg'].includes(
        $event[0].type
      )
    ) {
      this.prepareFilesList($event);
      this.toBase64(this.files[0]).then((data: string) => {
        this.selectedImageBase64 = data;
      });
    } else {
      //   console.log('Uploaded file is not an image');
    }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    if (
      files?.length &&
      ['image/png', 'image/x-png', 'image/jpg', 'image/jpeg'].includes(
        files[0].type
      )
    ) {
      this.prepareFilesList(files);
      this.toBase64(this.files[0]).then((data: string) => {
        this.selectedImageBase64 = data;
      });
    } else {
      //  console.log('Uploaded file is not an image');
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files = [];
    this.selectedImageBase64 = '';
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files = [];
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = '';
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  addToSearch() {
    this.angulartics2.eventTrack.next({
      action: matomoActions.searchByImage,
      properties: {
        category: matomoCategories.landingPage,
      },
    });

    const { imageUrl } = this.imageForm.getRawValue();

    const isBase64Data = imageUrl.includes('data:image');
    let result: SearchByImageModalPayload = {
      name: this.imageName,
      filename: imageUrl,
      displayName: imageUrl,
    };

    this.getImageUrl(imageUrl, isBase64Data, result);
  }

  addToImageSearch() {
    let result: SearchByImageModalPayload = {
      name: this.imageName,
      filename: this.files[0].name,
      displayName: '',
    };

    this.getImageUrl(this.selectedImageBase64, true, result);
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  getImageUrl(
    image: string,
    isBase64Data: boolean,
    result: SearchByImageModalPayload
  ) {
    this.showLoader = true;
    this.imageService
      .getImageUrl(image, isBase64Data)
      .subscribe((imageUrl: string) => {
        this.showLoader = false;

        if (imageUrl) {
          this.dialogRef.close({ ...result, imageUrl });
        } else {
          this.showMessage(
            this.translationService.translate(
              'Something went wrong. Please try again.'
            )
          );
        }
      }),
      () => {
        this.showMessage(
          this.translationService.translate(
            'Something went wrong. Please try again.'
          )
        );
        this.showLoader = false;
        this.dialogRef.close();
      };
  }

  showMessage(msg: string, okText = 'OK') {
    this.snackBar.open(msg, okText, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar'],
    });
  }

  // ----
  clearName(): void {
    this.imageName = '';
  }

  addName(event: MatChipInputEvent): void {
    if ((event.value || '').trim()) {
      this.imageName = event.value.trim();
      event.input.value = null;
    }
  }

  onKeyDown(keyEvent) {
    if (this.imageName && keyEvent.keyCode !== BACKSPACE) {
      keyEvent.preventDefault();
      keyEvent.stopPropagation();
    }
  }
}
