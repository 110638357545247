import { FontAwesomeIcon } from '@fe-platform/shared-ui/intellectus';
import { ModalAction } from './modal-action.type';
import { Breadcrumb } from './breadcrumb.type';

export type ModalConfig = {
  icon?: FontAwesomeIcon;
  title?: string;
  breadcrumbs?: Array<Breadcrumb>;
  actions?: ReadonlyArray<ModalAction>;
  footerHeight?: number;
  centeredTitle?: boolean;
};
