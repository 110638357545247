import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { catchError, map, mergeMap, Observable, of, take, tap } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { WebsocketManagerService } from 'src/app/services/websocket/websocket-manager.service';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { CaseInvestigationService } from '../../analysis/shared/services/case-investigations.service';
import { FtaNotificationService } from './fta-notification.service';
import { GraphServiceResponse } from '../../visual-investigation/models/graph-entities.model';
import {
  GetAllFilesResponse,
  JobStatusResponse,
  RawFileResponse,
} from '../../visual-investigation/models/text-analysis-uploader.model';
import {
  FileAnalysisStatus,
  jobStatusToFileAnalysisStatus,
} from '../models/file-analysis.model';
import { Country } from '../../../shared/models/country.model';
import { Region } from '../../../shared/models/region.model';

@Injectable({
  providedIn: 'root',
})
export class FileTextAnalysisService extends BaseService {
  private analyzedFileIds: { [fileId: string]: FileAnalysisStatus } = {};
  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    private httpClient: HttpClient,
    private caseInvestigationService: CaseInvestigationService,
    private translationService: TranslationService,
    private ftaNotificationService: FtaNotificationService,
    private webSocketManagerService: WebsocketManagerService
  ) {
    super(router, snackBar);
    this.webSocketManagerService.getServerTsConnection().subscribe({
      next: (socket) => {
        socket.on('message', (message: unknown) => {
          if (message['channel'] === 'TextExtraction') {
            const investigationId =
              this.caseInvestigationService.currentInvestigation.id;
            this.ftaNotificationService.changeStateOfNotificationToaster(
              message['correlationId'],
              message['body'].status,
              investigationId
            );
          }
        });
      },
    });
  }

  public uploadFileRequest(
    file: File
  ): Observable<HttpEvent<{ type: HttpEventType; id?: string }>> {
    const investigationId =
      this.caseInvestigationService.currentInvestigation.id;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('investigationId', investigationId);
    return this.httpClient.post<
      HttpEvent<{ type: HttpEventType; id?: string }>
    >(`${this.proxyApiUrl}/file-text-analysis/upload`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  public downloadRequest(fileId: string): Observable<RawFileResponse> {
    return this.httpClient.get<RawFileResponse>(
      `${this.proxyApiUrl}/file-text-analysis/rawFile?investigationId=${this.caseInvestigationService.currentInvestigation.id}&fileId=${fileId}`
    );
  }

  public deleteRequest(fileId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(
      `${this.proxyApiUrl}/file-text-analysis?investigationId=${this.caseInvestigationService.currentInvestigation.id}&fileId=${fileId}`
    );
  }

  public uploadByUrl(url: string) {
    const formData = new FormData();
    const investigationId =
      this.caseInvestigationService.currentInvestigation.id;
    formData.append('url', url);
    formData.append('investigationId', investigationId);
    return this.httpClient.post(
      `${this.proxyApiUrl}/file-text-analysis/upload`,
      formData
    );
  }

  public downloadFile(fileId: string) {
    this.downloadRequest(fileId).subscribe(
      (res: RawFileResponse) => {
        const file = this.base64ToBlob(
          res.base64Data,
          res.filePath,
          res.mimeType
        );
        saveAs(file, res.filePath);
      },
      () => {
        this.showMessage(
          this.translationService.translate('Something went wrong.')
        );
      }
    );
  }

  public getInvestigationFiles(
    investigationId
  ): Observable<GetAllFilesResponse[]> {
    return this.httpClient
      .get<any>(
        `${this.proxyApiUrl}/file-text-analysis?investigationId=${investigationId}`
      )
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError((error) => of(error))
      );
  }

  public analyseFile(fileId: string, filename: string) {
    this.analyzedFileIds[fileId] = FileAnalysisStatus.ANALYSIS_IN_PROGRESS;
    this.webSocketManagerService
      .getServerTsConnection()
      .pipe(
        take(1),
        mergeMap((value) => {
          const headers = new HttpHeaders().append(
            'Ws-Connection-Id',
            value.id
          );
          return this.httpClient.post(
            `${this.proxyApiUrl}/file-text-analysis/rpc/trigger-file-analysis`,
            {
              fileId,
              investigationId:
                this.caseInvestigationService.currentInvestigation.id,
            },
            { headers }
          );
        })
      )
      .subscribe({
        next: (res: { correlationId: string }) => {
          const investigationId =
            this.caseInvestigationService.currentInvestigation.id;
          this.ftaNotificationService.addToast(
            filename,
            fileId,
            res.correlationId,
            investigationId
          );
        },
        error: (error) => {
          this.analyzedFileIds[fileId] = FileAnalysisStatus.NOT_ANALYZED;
        },
      });
  }

  public fetchFileAnalysis(fileId): Observable<GraphServiceResponse> {
    return this.httpClient
      .get<any>(
        `${this.proxyApiUrl}/file-text-analysis/graph?startingEntityId=File/${fileId}&depth=1`
      )
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        tap((data) => {
          const isAnalyzed = Boolean(data.nodes.length || data.edges.length);
          if (isAnalyzed) {
            this.analyzedFileIds[fileId] = FileAnalysisStatus.ANALYZED;
          }
        }),
        catchError((error) => of(error))
      );
  }

  public getFileAnalysisStatus(fileId: string): FileAnalysisStatus {
    const status = this.analyzedFileIds[fileId];
    return status ? status : FileAnalysisStatus.NOT_ANALYZED;
  }

  public getJobStatusRequest(InvestigationId: string) {
    return this.httpClient
      .get<JobStatusResponse[]>(
        `${this.proxyApiUrl}/file-text-analysis/jobs/${InvestigationId}`
      )
      .pipe(
        tap((jobs) => {
          jobs.forEach((job) => {
            const fileEntry =
              this.caseInvestigationService.getFileIdFromFilename(
                job.body.filepath
              );
            if (fileEntry) {
              this.analyzedFileIds[fileEntry.fileId] =
                jobStatusToFileAnalysisStatus[job.status];
            }
          });
        })
      );
  }

  public getFilenameFromFilePath(filepath: string): string {
    let filenameArray = filepath.split('/');
    let filename = filenameArray[filenameArray.length - 1];
    return filename;
  }

  public base64ToBlob(base64: string, fileName: string, mimeType: string) {
    const splitted = base64.split(',');
    const base64string = atob(splitted[1]);
    let length = base64string.length;
    const u8bit = new Uint8Array(length);
    while (length--) {
      u8bit[length] = base64string.charCodeAt(length);
    }
    return new Blob([u8bit], { type: mimeType });
  }
}
