import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Feed } from 'datalayer/models/feed/feed';
import { FeedDTO } from 'datalayer/models/feed/feed-dto';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  skip,
  tap,
} from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import {
  FeedListQueryFilters,
  FeedListResult,
} from 'src/app/shared/models/feed.model';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';

@Injectable({
  providedIn: 'root',
})
export class FeedService extends BaseService {
  filterParams = {};
  reachedEndOfFeed = new BehaviorSubject<boolean>(false);
  isUpdatedFeed = new BehaviorSubject<boolean>(false);
  private onFilterFeedList$ = new Subject<FeedListQueryFilters>();

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
  }

  public setFilterFeedList(filters: FeedListQueryFilters): void {
    this.onFilterFeedList$.next(filters);
  }

  public getFilterFeedList(): Observable<FeedListQueryFilters> {
    return this.onFilterFeedList$
      .asObservable()
      .pipe(skip(1), debounceTime(400), distinctUntilChanged(isEqual));
  }

  getTargetFeedWithPagination(
    { targetId, filters = {}, page = 1, limit = 10 },
    getCompleteFeed?: boolean
  ): Observable<FeedListResult> {
    let params = {};
    params = this.updateFilterParams(filters);
    params['page'] = page;
    params['limit'] = limit;
    if (getCompleteFeed) {
      params['skip_filters'] = true;
    }

    return this.httpClient
      .get<any>(`${this.url}/target/${targetId}/feed`, { params })
      .pipe(
        map((data) => {
          // TODO: update previous items if they have updates in db
          data.result = transformSnakeToCamel(data.result);
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getTargetFeedItem(targetId: string, feedItemId: string) {
    return this.httpClient
      .get<any>(`${this.url}/target/${targetId}/feed/${feedItemId}`)
      .pipe(
        map((data) => {
          data.result = transformSnakeToCamel(data.result);
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getTargetCombinedFeed(
    targetId: string,
    filters: FeedListQueryFilters
  ): Observable<Feed[]> {
    const params = this.createFilterParams(filters);
    // maybe the limit should be set in the filters area
    params['limit'] = 1000;
    const targetPosts$ = this.httpClient
      .get<{ result: FeedDTO[] }>(`${this.url}/target/${targetId}/feed`, {
        params,
      })
      .pipe(
        map((feedDtos) =>
          feedDtos.result.map((dto) => new FeedDTO(dto).toModel())
        ),
        map((feeds) => {
          const uniqueMonthsYears = [];
          return feeds.map((feed) => {
            const publishedAtMonthYear = `${feed.publishedAt?.getMonth()}/${feed.publishedAt?.getFullYear()}`;
            feed.showPublishMonth =
              !uniqueMonthsYears.includes(publishedAtMonthYear);
            uniqueMonthsYears.push(publishedAtMonthYear);
            return feed;
          });
        })
      );
    // TODO this endpoint is still work in progress
    const relatedPosts$ = of([]);
    return combineLatest([targetPosts$, relatedPosts$]).pipe(
      map(([targetPosts, relatedPosts]) => [...targetPosts, ...relatedPosts])
    );
  }
  getTargetFeed(
    { targetId, filters = {}, page = 1, limit = 10, related = false },
    getCompleteFeed?
  ) {
    let params = {};
    params = this.updateFilterParams(filters);
    params['page'] = page;
    params['limit'] = limit;
    params['related'] = related;
    if (getCompleteFeed) {
      params['skip_filters'] = true;
    }

    return this.httpClient
      .get<any>(`${this.url}/target/${targetId}/feed`, { params })
      .pipe(
        tap((data) => {
          if (!getCompleteFeed && !data.result.length) {
            this.reachedEndOfFeed.next(true);
          }
        }),
        map((data) => {
          // TODO: update previous items if they have updates in db
          const dataToCamel = transformSnakeToCamel(data.result);
          return dataToCamel;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getFeedHistory(targetId) {
    return this.httpClient
      .get<any>(`${this.url}/target/${targetId}/feed-history`)
      .pipe(catchError((error) => this.handleError(error)));
  }

  getFeedActivity({ targetId, filters = {} }) {
    let params = {};
    params = this.updateFilterParams(filters);
    if (!params) {
      params['skip_filters'] = true;
    }
    params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.httpClient
      .get<any>(`${this.url}/target/${targetId}/feed-activity`, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  createFilterParams(filters: FeedListQueryFilters): {
    [id: string]: string | number;
  } {
    let filterParams = {};
    for (let [key, value] of Object.entries(filters)) {
      filterParams[key] = value || '';
    }
    return filterParams;
  }

  updateFilterParams(filters) {
    this.filterParams = {};
    for (let [key, value] of Object.entries(filters)) {
      if (!value) {
        value = '';
      }
      this.filterParams[key] = value;
    }
    return this.filterParams;
  }
}
