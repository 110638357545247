import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSlider } from '@angular/material/slider';
import * as KeyLines from '@trg-ui/link-analysis';
import { Link, Node } from '@trg-ui/link-analysis';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { JobStatus } from 'src/app/modules/data-layer/models/background-jobs/background-job-status';
import {
  degreesSliderConfig,
  DEGREES_EXPLANATION,
  entitiesTypeToLabel,
  mutualFriendsSliderConfig,
  nodeTypeToColor,
  quickFilters,
} from 'src/app/modules/link-analysis/shared/link-analysis.model';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { MessageSubject } from 'src/app/services/websocket/message-subject.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';

@Component({
  selector: 'app-la-top-filters',
  templateUrl: './la-top-filters.component.html',
  styleUrls: ['./la-top-filters.component.scss'],
})
export class LaTopFiltersComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() nodesCounter: number;
  @Input() linksCounter: number;
  @Input() playgroundView: boolean;
  @Input() casePlaygroundView: boolean;
  @Input() caseView: boolean;
  @Input() selectedLayout: KeyLines.LayoutName;
  @Input() target: TargetItem;
  @Input() selectedQuickFilterOption: {
    label: string;
    value: MessageSubject | string;
  };
  @Input() graphData: (Node | Link)[];
  @Input() playgroundEntities: Node[];
  @Input() socialProfileNodes: Node[];
  @Input() enableShowPhotosButton: boolean;
  @Input() showFilterSidebar: boolean;

  @Output() showEntitiesPanel = new EventEmitter<void>();
  @Output() showAllGraphData = new EventEmitter<void>();
  @Output() chartLayoutChange = new EventEmitter<MatSelectChange>();
  @Output() quickFilterChange = new EventEmitter<MatSelectChange>();
  @Output() selectedEntity = new EventEmitter<string>();
  @Output() onDegreesSlider = new EventEmitter<number>();
  @Output() mutualFriendsSliderChange = new EventEmitter<void>();
  @Output() toggleLabels = new EventEmitter<MatCheckboxChange>();
  @Output() togglePhotos = new EventEmitter<void>();
  @Output() toggleMapView = new EventEmitter<MatSlideToggleChange>();
  @Output() toggleFilterSidebar = new EventEmitter<MatSlideToggleChange>();

  @ViewChild('degreesSlider') degreesSlider: MatSlider;

  chartLayouts: KeyLines.LayoutName[] = [
    'standard',
    'organic',
    'hierarchy',
    'sequential',
    'structural',
    'lens',
    'tweak',
  ];
  // some quick filter options will send an event to analytics services, the rest happen automatically using graph filtering
  quickFilterOptions: { label: string; value: MessageSubject | string }[] = [];
  deepOsintFlagEnabled = false;
  entitiesFilteredList: Observable<(Node | Link)[]>;
  entitiesAutocompleteControl = new FormControl();
  nodeTypeToColor = nodeTypeToColor;
  entitiesTypeToLabel = entitiesTypeToLabel;
  analysisExplanations: { name?: string; explanation?: string } = {};
  degreesSliderConfig = degreesSliderConfig;
  mutualFriendsSliderConfig = mutualFriendsSliderConfig;

  constructor(private appConfigService: AppConfigService) {
    this.deepOsintFlagEnabled =
      this.appConfigService.getConfigVariable('enableDeepOsint');
  }
  ngOnInit(): void {
    this.initializeAnalysisExplanations();
  }

  ngAfterViewInit() {
    this.handleEntitiesInputSearch();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.target?.currentValue) {
      this.initializeQuickFiltertOptions();
    }
  }

  public onShowEntitiesPanel() {
    this.showEntitiesPanel.emit();
  }

  public onChartLayout(event: MatSelectChange) {
    this.chartLayoutChange.emit(event);
  }

  public onQuickFilterChange(event: MatSelectChange) {
    if (this.degreesSlider) {
      this.degreesSlider.value = 1;
    }
    this.quickFilterChange.emit(event);
  }

  public onShowAllGraphData() {
    if (this.degreesSlider) {
      this.degreesSlider.value = 1;
    }
    this.showAllGraphData.emit();
  }

  public onSelectedEntity(id: string) {
    this.selectedEntity.emit(id);
  }

  public showExplanation(name: string): string {
    return this.analysisExplanations[name];
  }

  public formatDegreesSliderLabel(value: number) {
    return `${value}+`;
  }

  public degrees(value: number) {
    this.onDegreesSlider.emit(value);
  }

  public onToggleLabels(event: MatCheckboxChange) {
    this.toggleLabels.emit(event);
  }

  public onTogglePhotos() {
    this.togglePhotos.emit();
  }

  public onToggleMapView(event: MatSlideToggleChange) {
    this.toggleMapView.emit(event);
  }

  public onToggleFilterSidebar(event: MatSlideToggleChange) {
    this.toggleFilterSidebar.emit(event);
  }

  private initializeAnalysisExplanations() {
    this.analysisExplanations['degrees'] = DEGREES_EXPLANATION;
  }

  private handleEntitiesInputSearch() {
    this.entitiesFilteredList =
      this.entitiesAutocompleteControl.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
        distinctUntilChanged(),
        map((value) => this.filterAutocomplete(value))
      );
  }

  private filterAutocomplete(value: string): (Link | Node)[] {
    if (!value?.length) {
      return [];
    }
    const filterValue = value.toLowerCase();
    const dataToSearchIn = this.playgroundView
      ? this.playgroundEntities
      : this.graphData;
    return dataToSearchIn.filter((item: Node | Link) => {
      return (
        item.type === 'node' &&
        item.d?.label?.toLowerCase().indexOf(filterValue) > -1
      );
    });
  }

  private initializeQuickFiltertOptions() {
    this.quickFilterOptions = [
      {
        label: quickFilters.TOP_FACEBOOK_CONNECTIONS,
        value: MessageSubject.DeepOsintTopConnections,
      },
      {
        label: quickFilters.FACEBOOK_MUTUAL_FRIENDS,
        value: MessageSubject.DeepOsintMutualFriendsRequest,
      },
      {
        label: quickFilters.FAMILY,
        value: MessageSubject.DeepOsintFamilyMembers,
      },
      {
        label: quickFilters.TOP_PHOTO_LIKERS,
        value: MessageSubject.DeepOsintPhotoLikers,
      },
      {
        label: quickFilters.TOP_PHOTO_COMMENTERS,
        value: MessageSubject.DeepOsintPhotoCommenters,
      },
      {
        label: quickFilters.TOP_POST_LIKERS,
        value: MessageSubject.DeepOsintPostLikers,
      },
      {
        label: quickFilters.TOP_POST_COMMENTERS,
        value: MessageSubject.DeepOsintPostCommenters,
      },
      {
        label: quickFilters.TAGGED_PHOTO,
        value: MessageSubject.DeepOsintTaggedProfiles,
      },
      {
        label: quickFilters.WORKPLACES,
        value: quickFilters.WORKPLACES,
      },
      {
        label: quickFilters.EDUCATION,
        value: quickFilters.EDUCATION,
      },
      {
        label: quickFilters.TOP_POST_SHARED,
        value: MessageSubject.DeepOsintPostShares,
      },
      {
        label: quickFilters.TOP_PHOTO_SHARED,
        value: MessageSubject.DeepOsintPhotoShares,
      },
      // TODO: add these filters when we get the full profile from the collectors
      // quickFilters.SAME_PLACE,
      // quickFilters.SAME_INSITUTION,
    ];
    if (
      this.deepOsintFlagEnabled &&
      this.target.deepOsintStatus === JobStatus.DONE
    ) {
      // preselect the top connections filter
      this.selectedQuickFilterOption = this.quickFilterOptions[0];
    }
  }
}
